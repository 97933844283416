<template>
  <v-treeview rounded hoverable :items="items" open-on-click activatable item-key="name" class="tree-view">
    <template slot="label" slot-scope="{ item }">
      <span @click="emitToParent(item)">{{ item.name }}</span>
    </template>

    <template v-slot:prepend="{ item }">
      <!-- Basic blocks-->
      <i v-if="item.id == 1" class="iconStyleArray blocklyToolboxLogic"> </i>
      <i v-else-if="item.id == 2" class="iconStyleArray blocklyToolboxControl"> </i>
      <i v-else-if="item.id == 3" class="iconStyleArray blocklyToolboxMath"> </i>
      <i v-else-if="item.id == 4" class="iconStyleArray blocklyToolboxText"> </i>
      <i v-else-if="item.id == 5" class="iconStyleArray blocklyToolboxVar"> </i>
      <i v-else-if="item.id == 6" class="iconStyleArray blocklyToolboxLists"> </i>
      <i v-else-if="item.id == 7" class="iconStyleArray blocklyToolboxFunctions"> </i>
      <!-- Advanced blocks-->
      <i v-else-if="item.id == 8" class="iconStyleArray blocklyToolboxFullInOut"> </i>
      <i v-else-if="item.id == 9" class="iconStyleArray blocklyToolboxFullTime"> </i>
      <i v-else-if="item.id == 10" class="iconStyleArray blocklyToolboxFullSerialPort"> </i>
      <i v-else-if="item.id == 11" class="iconStyleArray blocklyToolboxSensors"> </i>
      <i v-else-if="item.id == 12" class="iconStyleArray blocklyToolboxFullActuators"> </i>
      <i v-else-if="item.id == 13" class="iconStyleArray blocklyToolboxLcdScreen"> </i>
      <i v-else-if="item.id == 14" class="iconStyleArray blocklyToolboxLedMatrix"> </i>
      <i v-else-if="item.id == 15" class="iconStyleArray blocklyToolboxNeoPixel"> </i>
      <i v-else-if="item.id == 16" class="iconStyleArray blocklyToolboxFullMotor"> </i>
      <i v-else-if="item.id == 17" class="iconStyleArray blocklyToolboxRtcClock"> </i>
      <i v-else-if="item.id == 18" class="iconStyleArray blocklyToolboxSdCard"> </i>
      <i v-else-if="item.id == 19" class="iconStyleArray blocklyToolboxBluetooth"> </i>
      <i v-else-if="item.id == 20" class="iconStyleArray blocklyToolboxWifi"> </i>
      <i v-else-if="item.id == 21" class="iconStyleArray blocklyToolboxIR"> </i>
    </template>
  </v-treeview>
</template>

<script>
import { tree } from './lang/es.json';

export default {
  components: {},
  data: () => ({
    items: tree,
  }),
  methods: {
    emitToParent(item) {
      this.$emit('nodeClick', item.id);
    },
  },
};
</script>

<style>
.iconStyleArray {
  border-radius: 5px;
  margin-right: 16px;
  height: 18px;
  width: 18px;
  display: block;
}

.blocklyToolboxLogic {
  background-color: var(--v-toolbox1-base);
}

.blocklyToolboxControl {
  background-color: var(--v-toolbox2-base);
}

.blocklyToolboxMath {
  background-color: var(--v-toolbox3-base);
}

.blocklyToolboxText {
  background-color: var(--v-toolbox4-base);
}

.blocklyToolboxVar {
  background-color: var(--v-toolbox10-base);
}

.blocklyToolboxLists {
  background-color: var(--v-toolbox6-base);
}

.blocklyToolboxFunctions {
  background-color: var(--v-toolbox8-base);
}

.blocklyToolboxFullInOut {
  border-color: var(--v-toolbox1-base);
}

.blocklyToolboxFullTime {
  border-color: var(--v-toolbox2-base);
}

.blocklyToolboxFullSerialPort {
  border-color: var(--v-toolbox3-base);
}

.blocklyToolboxSensors {
  border-color: var(--v-toolbox4-base);
}

.blocklyToolboxFullActuators {
  border-color: var(--v-toolbox10-base);
}

.blocklyToolboxLcdScreen {
  border-color: var(--v-toolbox6-base);
}

.blocklyToolboxLedMatrix {
  border-color: var(--v-toolbox6-base);
}

.blocklyToolboxNeoPixel {
  border-color: var(--v-toolbox6-base);
}

.blocklyToolboxFullMotor {
  border-color: var(--v-toolbox8-base);
}

.blocklyToolboxRtcClock {
  border-color: var(--v-toolbox1-base);
}

.blocklyToolboxSdCard {
  border-color: var(--v-toolbox1-base);
}

.blocklyToolboxBluetooth {
  border-color: var(--v-toolbox2-base);
}

.blocklyToolboxWifi {
  border-color: var(--v-toolbox3-base);
}

.blocklyToolboxIR {
  border-color: var(--v-toolbox4-base);
}
</style>
