<template>
  <div class="text-center">
    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ on }"  >
        <button
          style="margin-right: 10px"
          v-on="on"
        >
          <i18n-svg height="42px" />
        </button>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in languages"
          :key="index"
          :class="currentLang === item && 'active-lang'"
        >
          <v-list-item-title @click="clickedSave(item)" class="pointer">
            <div class="d-flex align-center">
              <component
                class="mr-2"
                height="32"
                width="41"
                :is="item"
              />
              {{$t(`commons.${item}`)}}
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

import I18nSvg from '@public/img/menu/i18n.svg';
import EsFlag from '@public/img/icons/flags/es.svg';
import EnFlag from '@public/img/icons/flags/en.svg';
import CaFlag from '@public/img/icons/flags/ca.svg';
import EuFlag from '@public/img/icons/flags/eu.svg';
import { mapMutations } from 'vuex';

export default {
  components: {
    I18nSvg,
    es: EsFlag,
    en: EnFlag,
    ca: CaFlag,
    eu: EuFlag,
  },
  data() {
    return {
      languages: ['en', 'es', 'ca', 'eu'],
    };
  },
  computed: {
    currentLang() {
      return this.$i18n.locale;
    },
  },
  methods: {
    ...mapMutations(['changeLocale']),
    clickedSave(id) {
      sessionStorage.helloblocksLocale = id;
      this.$i18n.locale = id;
      this.changeLocale(id);
    },
  },
};

</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
