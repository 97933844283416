<template>
  <v-app>
    <browser-check />
    <v-overlay :value="showOverlay" @click.native="setShowOverlay(false)" color="background" />
    <router-view></router-view>
    <v-notifier />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BrowserCheck from './components/browser-check.vue';

export default {
  name: 'App',
  components: {
    BrowserCheck,
  },
  data() {
    return {
      serialReady: false,
      tab: 'program',
    };
  },
  methods: {
    // Bypassed for testing in Firefox
    ...mapMutations(['setShowOverlay']),
    checkSerialReady() {
      if (this.$serial) this.serialReady = true;
      else setTimeout(() => this.checkSerialReady(), 100);
    },
  },
  computed: {
    ...mapGetters(['showOverlay', 'interfaceMode']),
  },
  watch: {},
  async mounted() {
    this.checkSerialReady();
    this.$FeathersVuex.api.File.find({ query: { $limit: 9999999 } });
    this.$FeathersVuex.api.Project.find({ query: { $limit: 9999999 } });
    await this.$FeathersVuex.api.Setting.find({ query: { $limit: 9999999 } });
    // const { Setting } = this.$FeathersVuex.api;
    // const { data } = Setting.findInStore({ query: { key: 'editor' } });
    // this.$vuetify.theme.dark = /(dark)|(black)/.test(data[0]?.value?.theme ?? '');
    this.$vuetify.theme.dark = /(dark)|(black)/.test(this.interfaceMode ?? '');
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        this.setShowOverlay(false);
      }
    });
  },
};
</script>

<style lang="scss">
@import '../node_modules/xterm/css/xterm.css';

.shelf-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

.right-actions {
  position: absolute;
  right: 24px;
  top: 4px;
}
.v-application {
  font-family: 'Lemur';
}
</style>
