<template>
  <v-flex class="my-2">
    <v-flex class="d-flex justify-space-between">
      <p class="ma-2 mr-4 normalText--text flex-shrink-0">
        {{$t("toolbar.upload.server-connection")}}
      </p>
      <internet-status width="40px" :fill="$vuetify.theme.currentTheme[`${serverValid ? 'green' : 'orange'}`]"/>
    </v-flex>
    <v-flex class="d-flex justify-space-between">
      <p class="ma-2 mr-4 normalText--text flex-shrink-0">
        {{$t(`toolbar.upload.board-${currentBoardCustomName}`)}}
      </p>
      <electrolabs v-if="currentBoardCustomName === 'electrolabs'" width="40px"
                   :fill="$vuetify.theme.currentTheme[`${boardValid ? 'green' : 'orange'}`]"
                   :stroke="$vuetify.theme.currentTheme[`${boardValid ? 'green' : 'orange'}`]"
      />
      <cirkids v-else-if="currentBoardCustomName === 'cirkids'" width="40px"
                   :fill="$vuetify.theme.currentTheme[`${boardValid ? 'green' : 'orange'}`]"/>
      <arduino-uno v-else-if="currentBoardCustomName === 'uno-board'" width="40px"
                   :fill="$vuetify.theme.currentTheme[`${boardValid ? 'green' : 'orange'}`]"/>
      <arduino-nano v-else width="40px"
                    :fill="$vuetify.theme.currentTheme[`${boardValid ? 'green' : 'orange'}`]"/>
    </v-flex>
    <v-flex class="d-flex justify-space-between">
      <p class="ma-2 mr-4 normalText--text flex-shrink-0">
        {{$t("toolbar.upload.device-connection")}}
      </p>
      <usb-status width="40px" :fill="$vuetify.theme.currentTheme[`${currentDevice ? 'green' : 'orange'}`]"/>
    </v-flex>
  </v-flex>
</template>

<script>
import InternetStatus from '@public/img/icons/status/internet-status.svg';
import ArduinoUno from '@public/img/icons/status/board-0-status.svg';
import ArduinoNano from '@public/img/icons/status/board-1-status.svg';
import Cirkids from '@public/img/icons/status/placa_cirkids_v0.svg';
import Electrolabs from '@public/img/icons/status/placa_electrolab_v0.svg';
// import WemosD1 from '@public/img/icons/status/board-2-status.svg';
import UsbStatus from '@public/img/icons/status/usb-status.svg';
import { mapGetters } from 'vuex';

export default {
  components: {
    InternetStatus,
    ArduinoUno,
    ArduinoNano,
    Cirkids,
    Electrolabs,
    // WemosD1,
    UsbStatus,
  },
  props: ['serverValid', 'boardValid', 'currentDevice'],
  computed: {
    ...mapGetters(['currentBoard', 'currentBoardCustomName']),
  },
};

</script>
