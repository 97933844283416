<template>
  <div>
    <v-card class="docu-card">
      <v-card-title>{{ content.title }}</v-card-title>
      <v-divider />
      <v-card-text>{{ content.paragraph }}</v-card-text>
      <document-block :block="content.block"  :inject="content.inject" />
    </v-card>
  </div>
</template>

<script>
import DocumentBlock from '../../blockly/DocumentBlock.vue';

export default {
  props: ['content'],
  components: {
    DocumentBlock,
  },
};
</script>

<style>
.docu-card {
  width: 70%;
  margin-top: 36px;
  margin-bottom: 36px;
  box-shadow: none;
  padding: 15px;
}
</style>
