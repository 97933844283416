<template>
  <div>
    <plugged-icon class="plugged-icon" :class="{'showTransition': serialShelf, 'show': !paused}" />
    <unplugged-icon class="plugged-icon" :class="{'showTransition': serialShelf, 'show': paused}" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PluggedIcon from '@public/img/icons/serial_icono_plugged.svg';
import UnpluggedIcon from '@public/img/icons/serial_icono_unplugged.svg';

export default {
  props: ['paused'],
  components: {
    PluggedIcon,
    UnpluggedIcon,
  },
  computed: {
    ...mapGetters(['serialShelf']),
  },

};
</script>

<style>
.plugged-icon {
  position: absolute;
  width: 0px;
  top: 50vh;
  left: calc(50vw - 52px);
  z-index: 1000;
  height: 0px;
  opacity: 0;
  transition: all 0s;
  fill: var(--v-green-base);
}
.plugged-icon.showTransition {
  top: calc(25vh - 100px);
  opacity: 1;
  width: 104px;
  height: 64px;
  transition: height 0.5s;
}

.plugged-icon.showTransition:not(.show) {
  visibility: hidden;
}
</style>
