<template>
  <div class="scs-container">
    <div class="scs-label">
      <light-icon class="scs-icon"/>
      <div class="scs-txt">{{ $t('settings.interface.light-mode') }}</div>
    </div>
    <v-switch v-model="interfaceValue" inset class="scs-switch" />
    <div class="scs-label">
      <div class="scs-txt">{{ $t('settings.interface.dark-mode') }}</div>
      <dark-icon class="scs-icon"/>
    </div>
  </div>
</template>
<script>
import LightIcon from '@public/img/icons/settings/light_v0.svg';
import DarkIcon from '@public/img/icons/settings/dark_v0.svg';

const INTERFACE_VALUES = {
  scsOn: 'vs-dark',
  scsOff: 'vs',
};

export default {
  components: {
    LightIcon,
    DarkIcon,
  },
  props: {
    scsInterface: String,
  },
  data() {
    return {
      interfaceValue: this.scsInterface === INTERFACE_VALUES.scsOn,
    };
  },
  watch: {
    interfaceValue(newValue) {
      this.$emit('update:interface', newValue ? INTERFACE_VALUES.scsOn : INTERFACE_VALUES.scsOff);
    },
  },
};
</script>
<style scoped>
.scs-container {
  display: flex;
  justify-content: space-between;
  width: 350px;
}
.scs-label {
  display: flex;
  align-items: center;
}
.scs-label, .scs-icon, .scs-icon {
  height: 30px;
}
.scs-label .scs-txt {
  margin: 0px 4px;
}
.scs-switch {
  height: 50px;
  margin: 0px 20px;
}
</style>
