import Vue from 'vue';
import Router from 'vue-router';
import NotFound from './views/Error.vue';
import HomeBlockly from './views/HomeBlockly.vue';
import Documentation from './views/Documentation.vue';

Vue.use(Router);

export default new Router({
  base: '/editor',
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: Home,
    // },
    {
      path: '/',
      name: 'blocks',
      component: HomeBlockly,
    },
    {
      path: '/index.html',
      redirect: '/',
    },
    {
      path: '/docs',
      name: 'documentation',
      component: Documentation,
    },
    { path: '*', component: NotFound },
  ],
});
