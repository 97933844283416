/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview UI control for fullscreen.
 */
import './fullscreenStyles';
import Blockly from 'blockly/core';

/* eslint-disable */
const FULLSCREEN_SVG_DATAURI = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAABB0RVh0VGl0bGUAaWNvbm9zX3N2ZyQAYKgAAALdSURBVEiJxZZNa9RQFIbfc+cjcdOi/0GsRUTbqiNItwOlmcyqf0CpIAru3M7Or60Vi/8gq+nNUEj3pa3aVkRroT9C7UInYea+LpromPnKaAdfuITk3JznnnvvORwBAN/3ZwA8JXkdwATGo2MReQPgkeM4e9JoNGZJbpEsjAmYViQiN/Mkn5AskHxVKBQeLSwsHI+Dtr6+PtFqtZ4DWCb5WLTW3wBM5PP5yXFBE3meN2nb9lcR+SZaawJApVIRAEjeU/pO8qHruq8HOdZa3wawCiCXtqX9q/QEshcXNoD84HiAeI5kmNftTETa+L3ipjFmtlqtHmRxVqlUVgGs1uv1aaXUbrzgnuqKOBHJEICtlNqt1+vTWcAAkIKGI4Mty5oVkWYM319bW7s8DBoEwVQul0ugUVwXMoNbANrb29ufi8Xi1RheFJG3g+BBEExFUbRPMoFec133Q+yvNRRM8gHJB7VazZTL5cMs8AFQALgfjz/UlU69FJ/bHgCL5A/btmfK5fJhBmiX+qZTL1Wr1QOl1ByAUETORFG0mdiiKNqMoU1jzNVB0E5lAgPA4uLiR6XUnIgcAzjqMB0B+DJK2gHZisIfcACTnd8cxymN4iNR5ohPWyNFHATBVBiGmyJylESqtd4SkQsiMh/vSCZljji5vQDOkfxVyUieJ3nWGPOu0WhcOlVwOmWazeZ8YrNt+1ac55YxZtfzvCt/BfZ9/47W+u4A6I2lpaX3iT1dZGzb3umEa62XtdbLQ8EkXwBYqdVqahg0I3wlHoPBOLlwuVKpdDELNAM8jx6XuO8Zh2G421mRBkE74e12exZAAt/qN7cLLCLJ08KIjQBwUl6NMQm8byPQtQUkO/slWyn1SWttANyLO4y+ii/lS2TIlv9Wuf5be6sA7ABAq9V65nne5JB//wlqWdZzACC5I77vz5DcAlAcFzSliGRJOY6zR7IEYAPAOLf6GMAGyZLruvs/AdovxRpQpgaxAAAAAElFTkSuQmCC';

/**
 * Class for zoom to fit control.
 * @param {!Blockly.WorkspaceSvg} workspace The workspace to sit in.
 * @implements {Blockly.IPositionable}
 * @constructor
 */
export class FullscreenControl {
  /**
   * Constructor for fullscreen control.
   * @param {!Blockly.WorkspaceSvg} workspace The workspace that the fullscreen
   *     control will be added to.
   */
  constructor(workspace) {
    /**
     * The workspace.
     * @type {!Blockly.WorkspaceSvg}
     * @protected
     */
    this.workspace_ = workspace;

    /**
     * The unique id for this component.
     * @type {string}
     */
    this.id = 'zoomToFit';

    /**
     * The SVG group containing the fullscreen control.
     * @type {SVGElement}
     * @private
     */
    this.svgGroup_ = null;

    /**
     * Left coordinate of the fullscreen control.
     * @type {number}
     * @private
     */
    this.left_ = 0;

    /**
     * Top coordinate of the fullscreen control.
     * @type {number}
     * @private
     */
    this.top_ = 0;

    /**
     * Width of the fullscreen control.
     * @type {number}
     * @const
     * @private
     */
    this.WIDTH_ = 32;

    /**
     * Height of the fullscreen control.
     * @type {number}
     * @const
     * @private
     */
    this.HEIGHT_ = 32;

    /**
     * Distance between fullscreen control and bottom or top edge of workspace.
     * @type {number}
     * @const
     * @private
     */
    this.MARGIN_VERTICAL_ = 20;

    /**
     * Distance between fullscreen control and right or left edge of workspace.
     * @type {number}
     * @const
     * @private
     */
    this.MARGIN_HORIZONTAL_ = 26;

    /**
     * Whether this has been initialized.
     * @type {boolean}
     * @private
     */
    this.initialized_ = false;
  }

  /**
   * Initializes the zoom reset control.
   */
  init() {
    this.workspace_.getComponentManager().addComponent({
      component: this,
      weight: 2,
      capabilities: [Blockly.ComponentManager.Capability.POSITIONABLE],
    });
    this.createDom_();
    this.initialized_ = true;
    this.workspace_.resize();
  }

  /**
   * Disposes of workspace search.
   * Unlink from all DOM elements and remove all event listeners
   * to prevent memory leaks.
   */
  dispose() {
    if (this.svgGroup_) {
      Blockly.utils.dom.removeNode(this.svgGroup_);
    }
    if (this.onZoomToFitWrapper_) {
      Blockly.unbindEvent_(this.onZoomToFitWrapper_);
    }
  }

  /**
   * Creates DOM for ui element.
   * @private
   */
  createDom_() {
    this.svgGroup_ = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.IMAGE, {
        height: `${this.HEIGHT_}px`,
        width: `${this.WIDTH_}px`,
        class: 'zoomToFit',
      },
    );
    this.svgGroup_.setAttributeNS(Blockly.utils.dom.XLINK_NS, 'xlink:href',
      FULLSCREEN_SVG_DATAURI);

    Blockly.utils.dom.insertAfter(
      this.svgGroup_, this.workspace_.getBubbleCanvas(),
    );

    // Attach listener.
    this.onZoomToFitWrapper_ = Blockly.browserEvents.conditionalBind(
      this.svgGroup_, 'mousedown', null, this.onClick_.bind(this),
    );
  }

  requestFullScreen_(element) {
    // Supports most browsers and their versions.
    const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen
      || element.mozRequestFullScreen || element.msRequestFullScreen;

    if (requestMethod) { // Native full screen.
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== 'undefined') { // Older IE.
      const wscript = new ActiveXObject('WScript.Shell');
      if (wscript !== null) {
        wscript.SendKeys('{F11}');
      }
    }
  }

  cancelFullScreen_() {
    const requestMethod = document.exitFullscreen || document.cancelFullScreen || document.webkitCancelFullScreen
      || document.mozCancelFullScreen;
    if (requestMethod) { // cancel full screen.
      requestMethod.call(document);
    } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
      var wscript = new ActiveXObject("WScript.Shell");
      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }

  /**
   * Handle click event.
   * @private
   */
  onClick_() {
    const isInFullScreen = (document.fullScreenElement && document.fullScreenElement !== null) ||  (document.mozFullScreen || document.webkitIsFullScreen);

    if (isInFullScreen) {
      this.cancelFullScreen_();
    } else {
      this.requestFullScreen_(document.body);
    }
    const uiEvent = new (Blockly.Events.get(Blockly.Events.CLICK))(
      null, this.workspace_.id, 'zoom_reset_control',
    );
    Blockly.Events.fire(uiEvent);
  }

  /**
   * Returns the bounding rectangle of the UI element in pixel units relative to
   * the Blockly injection div.
   * @return {!Blockly.utils.Rect} The component’s bounding box.
   */
  getBoundingRectangle() {
    return new Blockly.utils.Rect(
      this.top_, this.top_ + this.HEIGHT_,
      this.left_, this.left_ + this.WIDTH_,
    );
  }

  /**
   * Positions the fullscreen control.
   * It is positioned in the opposite corner to the corner the
   * categories/toolbox starts at.
   * @param {!Blockly.MetricsManager.UiMetrics} metrics The workspace metrics.
   * @param {!Array<!Blockly.utils.Rect>} savedPositions List of rectangles that
   *     are already on the workspace.
   */
  position(metrics, savedPositions) {
    if (!this.initialized_) {
      return;
    }
    const hasVerticalScrollbars = this.workspace_.scrollbar
      && this.workspace_.scrollbar.canScrollHorizontally();
    const hasHorizontalScrollbars = this.workspace_.scrollbar
      && this.workspace_.scrollbar.canScrollVertically();

    if (metrics.toolboxMetrics.position === Blockly.TOOLBOX_AT_LEFT
      || (this.workspace_.horizontalLayout && !this.workspace_.RTL)) {
      // Right corner placement.
      this.left_ = metrics.absoluteMetrics.left + metrics.viewMetrics.width
        - this.WIDTH_ - this.MARGIN_HORIZONTAL_;
      if (hasVerticalScrollbars && !this.workspace_.RTL) {
        this.left_ -= Blockly.Scrollbar.scrollbarThickness;
      }
    } else {
      // Left corner placement.
      this.left_ = this.MARGIN_HORIZONTAL_;
      if (hasVerticalScrollbars && this.workspace_.RTL) {
        this.left_ += Blockly.Scrollbar.scrollbarThickness;
      }
    }

    const startAtBottom = metrics.toolboxMetrics.position !== Blockly.TOOLBOX_AT_BOTTOM;
    if (startAtBottom) {
      // Bottom corner placement
      this.top_ = metrics.absoluteMetrics.top + metrics.viewMetrics.height
        - this.HEIGHT_ - this.MARGIN_VERTICAL_;
      if (hasHorizontalScrollbars) {
        // The horizontal scrollbars are always positioned on the bottom.
        this.top_ -= Blockly.Scrollbar.scrollbarThickness;
      }
    } else {
      // Upper corner placement
      this.top_ = metrics.absoluteMetrics.top + this.MARGIN_VERTICAL_;
    }

    // Check for collision and bump if needed.
    let boundingRect = this.getBoundingRectangle();
    for (let i = 0, otherEl; (otherEl = savedPositions[i]); i++) {
      if (boundingRect.intersects(otherEl)) {
        if (startAtBottom) { // Bump up.
          this.top_ = otherEl.top - this.HEIGHT_ - this.MARGIN_VERTICAL_;
        } else { // Bump down.
          this.top_ = otherEl.bottom + this.MARGIN_VERTICAL_;
        }
        // Recheck other savedPositions
        boundingRect = this.getBoundingRectangle();
        i = -1;
      }
    }

    this.svgGroup_.setAttribute('transform',
      `translate(${this.left_},${this.top_})`);
  }
}
