export default [
  { style: 'logic_blocks', themeVariable: 'toolbox1' },
  { style: 'control_blocks', themeVariable: 'toolbox2' },
  { style: 'control_blocks_init', themeVariable: 'toolbox11' },
  { style: 'math_blocks', themeVariable: 'toolbox3' },
  { style: 'text_blocks', themeVariable: 'toolbox4' },
  { style: 'num_variables_blocks', themeVariable: 'toolbox9' },
  { style: 'text_variables_blocks', themeVariable: 'toolbox5' },
  { style: 'bool_variables_blocks', themeVariable: 'toolbox7' },
  { style: 'lists_blocks', themeVariable: 'toolbox6' },
  { style: 'num_lists_blocks', themeVariable: 'toolbox9' },
  { style: 'text_lists_blocks', themeVariable: 'toolbox5' },
  { style: 'procedure_blocks', themeVariable: 'toolbox8' },
  { style: 'io_blocks', themeVariable: 'toolbox1' },
  { style: 'time_blocks', themeVariable: 'toolbox2' },
  { style: 'serial_blocks', themeVariable: 'toolbox3' },
  { style: 'thinger_blocks', themeVariable: 'toolbox3' },
  { style: 'thingspeak_blocks', themeVariable: 'toolbox3' },
  { style: 'ifttt_blocks', themeVariable: 'toolbox3' },
  { style: 'sensor_blocks', themeVariable: 'toolbox1' },
  { style: 'actuators_blocks', themeVariable: 'toolbox10' },
  { style: 'lcd_blocks', themeVariable: 'toolbox6' },
  { style: 'ledmatrix_blocks', themeVariable: 'toolbox6' },
  { style: 'neopixel_blocks', themeVariable: 'toolbox6' },
  { style: 'motor_blocks', themeVariable: 'toolbox8' },
  { style: 'rtc_blocks', themeVariable: 'toolbox1' },
  { style: 'sd_blocks', themeVariable: 'toolbox1' },
  { style: 'bluetooth_blocks', themeVariable: 'toolbox2' },
  { style: 'mqtt_blocks', themeVariable: 'toolbox3' },
  { style: 'blynk_blocks', themeVariable: 'toolbox3' },
  { style: 'infrared_blocks', themeVariable: 'toolbox4' },
];
