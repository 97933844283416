<template>
  <div @click="bSBClick()"
    :class="`speech-bubble ${bsbDirection}-bubble speech-bubble-${bsbColor}${bsbNarrow ? ' speech-bubble-narrow' : ''}`">
    <span>{{ $t(bsbText) }}</span>
  </div>
</template>
<script>
export default {
  props: {
    bsbColor: {
      type: String,
      default: 'white',
    },
    bsbText: {
      type: String,
      default: '',
    },
    bsbDirection: {
      type: String,
      default: 'left',
    },
    bsbNarrow: {
      type: String,
      default: '',
    },
  },
  methods: {
    bSBClick() {
      this.$emit('clicked');
    },
  },
};
</script>
<style scoped>
  .speech-bubble {
    position: relative;
    top: 10px;
    height: 90px;
    width: 110px;
    border-radius: 10%;
    padding: 10px;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: .8rem;
    font-family: inherit;
  }

  .speech-bubble-narrow {
    height: 30px;
    border-radius: 6%;
  }

  .speech-bubble-blue {
    background-color: var(--v-toolbox2-base);
    color: var(--v-lightText-base);
  }

  .speech-bubble-blue:before {
    border: 8px solid var(--v-toolbox2-base);
  }

  .speech-bubble-grey {
    background-color: var(--v-off-base);
    color: var(--v-normalText-base);
  }

  .speech-bubble-grey:before {
    border: 8px solid var(--v-off-base);
  }

  .speech-bubble-yellow {
    background-color: var(--v-yellow-base);
    color: var(--v-lightText-base);
  }

  .speech-bubble-yellow:before {
    border: 8px solid var(--v-yellow-base);
  }

  .speech-bubble-green {
    background-color: var(--v-green-base);
    color: var(--v-lightText-base);
  }

  .speech-bubble-green:before {
    border: 8px solid var(--v-green-base);
  }

  .speech-bubble-orange {
    background-color: var(--v-orange-base);
    color: var(--v-lightText-base);
  }

  .speech-bubble-orange:before {
    border: 8px solid var(--v-orange-base);
  }

  .speech-bubble-white {
    background-color: var(--v-background-base);
    color: #000;
  }

  .speech-bubble-white:before {
    border: 8px solid var(--v-lightText-base);
  }

  .bottom-bubble:before {
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    bottom: -16px;
    left: calc(50% - 8px)
  }

  .speech-bubble-narrow.bottom-bubble:before {
    bottom: -14px;
  }

  .top-bubble:before {
    content: '';
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    top: -16px;
    left: calc(50% - 8px)
  }

  .speech-bubble-narrow.top-bubble:before {
    top: -14px;
  }

  .left-bubble:before {
    content: '';
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    left: -16px;
    top: 25%;
  }

  .speech-bubble-narrow.left-bubble:before {
    left: -14px;
  }

  .right-bubble:before {
    content: '';
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    right: -16px;
    top: 25%;
  }

  .speech-bubble-narrow.right-bubble:before {
    right: -14px;
  }
</style>
