<template>
  <div>
    <h1>{{ $t('documentation.logic.title') }}</h1>
    <doc-card :content="$t('documentation.logic.docu')" />
    <doc-card :content="$t('documentation.logic.if')" />
    <doc-card :content="$t('documentation.logic.compare')" />
    <doc-card :content="$t('documentation.logic.operation')" />
    <doc-card :content="$t('documentation.logic.negate')" />
    <doc-card :content="$t('documentation.logic.boolean')" />
    <doc-card :content="$t('documentation.logic.bolean_io')" />
    <doc-card :content="$t('documentation.logic.compare_bool')" />
  </div>
</template>

<script>
import DocCard from '../cards/doc-card.vue';

export default {
  components: {
    DocCard,
  },
};
</script>

<style></style>
