<template>
  <v-dialog v-model="dialog" max-width="520" hide-overlay
            content-class="dialog-fail" style="z-index: 1003;">
    <template>
      <div class="upload-dialog-card">
        <v-card-title class="upload-dialog-card-title">
          {{ $t('compile.title.error') }}
        </v-card-title>
        <v-card-actions class="upload-dialog-card-actions">
          <div class="upload-dialog-card-action upload-dialog-card-action-left">
            <div class="upload-dialog-card-action-icon">
              <accept-icon width="45" />
            </div>
            <bubble-speech-button
              bsb-color="blue"
              :bsb-text="$t('compile.title.retry')"
              bsb-direction="left"
              @clicked="retry" />
          </div>
          <div class="upload-dialog-card-action upload-dialog-card-action-right">
            <bubble-speech-button
              bsb-color="grey"
              :bsb-text="$t('compile.title.what-happened')"
              bsb-direction="right"
              @clicked="$emit('whatHappened');" />
            <div class="upload-dialog-card-action-icon">
              <cancel-icon width="45" />
            </div>
          </div>
        </v-card-actions>
      </div>
    </template>
  </v-dialog>
</template>

<script>

import AcceptIcon from '@public/img/icons/iconos_svg_25.svg';
import CancelIcon from '@public/img/icons/iconos_svg_26.svg';
import BubbleSpeechButton from '../../dialogs/actions/bubble-speech-button.vue';

export default {
  components: {
    AcceptIcon,
    CancelIcon,
    BubbleSpeechButton,
  },
  data() {
    return {
      dialog: true,
      retryClicked: false,
    };
  },
  methods: {
    retry() {
      this.dialog = false;
      this.retryClicked = true;
      this.$emit('retry');
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        if (!this.retryClicked) {
          this.$emit('closed');
        }
      } else {
        this.retryClicked = false;
      }
    },
  },
};
</script>

<style>
.dialog-fail {
  position: absolute;
  top: 36%;
  background: transparent;
  border: none;
  box-shadow: none;
}
.upload-dialog-card {
  margin: 30px;
  padding: 20px;
  width: calc(100% - 60px);
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-greyShadow-base);
}
.upload-dialog-card:before {
  content: "";
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid var(--v-grey-base);
  right: 250px;
  top: -10px;
}
.upload-dialog-card:after {
  content: "";
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid var(--v-background-base);
  right: 250px;
  top: -9px;
}
.upload-dialog-card-title {
  color: var(--v-darkText-base);
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  word-break: normal;
}
.upload-dialog-card-actions {
  width: 100%;
  justify-content: space-around;
  margin: 20px 0;
}
.upload-dialog-card-action {
  width: 50%;
  display: flex;
}
.upload-dialog-card-action-left {
  justify-content: flex-start;
}
.upload-dialog-card-action-right {
  justify-content: flex-end;
}
.upload-dialog-card-action-icon{
  width: 50px;
}
</style>
