<template>
  <div>
    <small id="legal-warning" class="pa-2 ml-6">{{ $t("footer.legal-warning") }}</small>
    <v-dialog
      max-width="80vw"
      overlay-color="background"
      v-model="dialog"
      content-class="editor-modal"
      style="z-index: 1002;"
      activator="#legal-warning"
    >
      <template>
        <v-flex class="d-flex pt-1 pl-6 pr-2 align-center">
          <v-btn icon style="margin-left: auto" @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
        <v-container class="px-4">
          <v-main class="px-4 pb-4 legal-content">
            <p class="text-h6">{{$t("footer.legal.title")}}</p>
            <p class="text-body-2">{{$t("footer.legal.p1")}}</p>
            <div v-for="i in [1,2,3,4,5,6,7,8]" :key="i">
              <bullet-point :n="i" :title="$t(`footer.legal.${i}.title`)" :text="$t(`footer.legal.${i}.text`)" />
            </div>
            <ok-button class="d-flex justify-center align-center pa-8" @click="dialog=false" color="orange"/>
          </v-main>
        </v-container>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import BulletPoint from './footer-bullet-point.vue';
import OkButton from '../program/components/ok-button.vue';

export default {
  data: () => ({
    dialog: false,
  }),
  components: {
    BulletPoint,
    OkButton,
  },
};
</script>

<style scoped>
#legal-warning {
  cursor: pointer;
  border-radius: 5px;
}
#legal-warning:hover {
  background-color: var(--v-lightestGrey-base);
}
.legal-content {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
