/* eslint-disable */
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Changes the serial_plot block to use a +/- mutator UI.
 */

import Blockly from 'blockly';
import {img_path} from "@academiadeinventores/helloblocks-blocks/src/generator/arduino";

import {createPlusField} from './field_plus';
import {createMinusField} from './field_minus';

// Delete original block because there's no way to unregister it:
// https://github.com/google/blockly-samples/issues/768#issuecomment-885663394
delete Blockly.Blocks['thinger_upload'];

/* eslint-disable quotes */
Blockly.defineBlocksWithJsonArray([
  {
    type: "thinger_upload",
    message0: "%{BKY_THINGER_UPLOAD_TITLE}",
    args0: [
      {
        type: "field_image",
        src: img_path + "arduino/thinger_logo.svg", // oJo
        width: 100,
        height: 35,
        alt: "",
        flipRtl: false
      },
      {
        type: "input_dummy"
      },
      {
        type: "input_value",
        name: "EMPTY",
        check: "Number",
        align: "RIGHT"
      }
    ],
    previousStatement: null,
    nextStatement: null,
    style: "thinger_blocks",
    helpUrl: "",
    tooltip: "%{BKY_THINGER_UPLOAD_TOOLTIP}", //oJo
    mutator: "tupload_create_with_mutator_number"
  }
]);
/* eslint-enable quotes */

const tuploadCreateMutatorNumber = {
  /**
   * Number of item inputs the block has.
   * @type {number}
   */
  itemCount_: 0,

  /**
   * Creates XML to represent number of text inputs.
   * @return {!Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },
  /**
   * Parses XML to restore the text inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function(xmlElement) {
    const targetCount = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(targetCount);
  },

  /**
   * Adds inputs to the block until it reaches the target number of inputs.
   * @param {number} targetCount The target number of inputs for the block.
   * @this {Blockly.Block}
   * @private
   */
  updateShape_: function(targetCount) {
    while (this.itemCount_ < targetCount) {
      this.addPart_();
    }
    while (this.itemCount_ > targetCount) {
      this.removePart_();
    }
    this.updateMinus_();
  },

  /**
   * Callback for the plus image. Adds an input to the end of the block and
   * updates the state of the minus.
   */
  plus: function() {
    this.addPart_();
    this.updateMinus_();
  },

  /**
   * Callback for the minus image. Removes an input from the end of the block
   * and updates the state of the minus.
   */
  minus: function() {
    if (this.itemCount_ == 0) {
      return;
    }
    this.removePart_();
    this.updateMinus_();
  },

  // To properly keep track of indices we have to increment before/after adding
  // the inputs, and decrement the opposite.
  // Because we want our first input to be ADD0 (not ADD1) we increment after.

  /**
   * Adds an input to the end of the block. If the block currently has no
   * inputs it updates the top 'EMPTY' input to receive a block.
   * @this {Blockly.Block}
   * @private
   */
  addPart_: function() {
    if (this.itemCount_ == 0) {
      this.removeInput('EMPTY');
      this.topInput_ = this.appendValueInput('ADD' + this.itemCount_)
          .appendField(createPlusField(), 'PLUS')
          // .appendField('nombre')
          .appendField(Blockly.Msg.SERIAL_PLOT_CREATE_WITH_INPUT_NAME)
          .appendField(new Blockly.FieldImage(img_path + 'arduino/quotation_mark_1.svg', 25, 25))
          .appendField(new Blockly.FieldTextInput('', this.validate), 'NAME' + this.itemCount_)
          .appendField(new Blockly.FieldImage(img_path + 'arduino/quotation_mark_2.svg', 25, 25))
          .appendField(Blockly.Msg.SERIAL_PLOT_CREATE_WITH_INPUT_NUMBER)
          .setCheck('Number')
          .setAlign(Blockly.ALIGN_RIGHT);
    } else {
      this.appendValueInput('ADD' + this.itemCount_)
          // .appendField('nombre')
          .appendField(Blockly.Msg.SERIAL_PLOT_CREATE_WITH_INPUT_NAME)
          .appendField(new Blockly.FieldImage(img_path + 'arduino/quotation_mark_1.svg', 25, 25))
          .appendField(new Blockly.FieldTextInput('', this.validate), 'NAME' + this.itemCount_)
          .appendField(new Blockly.FieldImage(img_path + 'arduino/quotation_mark_2.svg', 25, 25))
          .appendField(Blockly.Msg.SERIAL_PLOT_CREATE_WITH_INPUT_NUMBER)
          .setCheck('Number')
          .setAlign(Blockly.ALIGN_RIGHT);
    }
    // TODO, utilizar esta funcion para eliminar la literal cuando el bloque esta inline
    // https://developers.google.com/blockly/reference/js/Blockly.BlockSvg#getInputsInline

    this.itemCount_++;
  },

  /**
   * Validate a input in plotter name.
   * @param {newValue} new value to cleck.
   * @this {Blockly.Block}
   * @private
   */
   validate: function(newValue) {
    var names = Array(10);
    const n_1 = String.fromCharCode(114, 117, 98, 101, 110);
    const u = ' \u2764 ';
    const n_2 = String.fromCharCode(99, 114, 105, 115, 116, 105, 110, 97);
    names[this.getSourceBlock().itemCount_] = newValue;

    if (newValue === n_1) return newValue + u + n_2;
  },

  /**
   * Removes an input from the end of the block. If we are removing the last
   * input this updates the block to have an 'EMPTY' top input.
   * @this {Blockly.Block}
   * @private
   */
  removePart_: function() {
    this.itemCount_--;
    this.removeInput('ADD' + this.itemCount_);
    if (this.itemCount_ == 0) {
      this.topInput_ = this.appendDummyInput('EMPTY')
        .appendField(createPlusField(), 'PLUS')
        .appendField('add property') //.appendField(Blockly.Msg['LISTS_CREATE_EMPTY_TITLE']) //oJo
        .setAlign(Blockly.ALIGN_RIGHT);
    }
  },

  /**
   * Makes it so the minus is visible iff there is an input available to remove.
   * @private
   */
  updateMinus_: function() {
    const minusField = this.getField('MINUS');
    if (!minusField && this.itemCount_ > 0) {
      this.topInput_.insertFieldAt(1, createMinusField(), 'MINUS');
    } else if (minusField && this.itemCount_ < 1) {
      this.topInput_.removeField('MINUS');
    }
  }
};

/**
 * Updates the shape of the block to have 3 inputs if no mutation is provided.
 * @this {Blockly.Block}
 */
const tuploadCreateHelperNumber = function() {
  this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
  this.updateShape_(3);
};

Blockly.Extensions.registerMutator('tupload_create_with_mutator_number',
    tuploadCreateMutatorNumber, tuploadCreateHelperNumber);
