/* eslint-disable */
import Blockly from 'blockly';
import store from '../../../store';

Blockly.Flyout.prototype.MARGIN = 35;
Blockly.Flyout.prototype.GAP_Y = 25;


/**
 * Calculates the x coordinate for the flyout position.
 * @return {number} X coordinate.
 */
Blockly.VerticalFlyout.prototype.getX = function() {
  if (!this.isVisible()) {
    return 0;
  }
  var metricsManager = this.targetWorkspace.getMetricsManager();
  var viewMetrics = metricsManager.getViewMetrics();
  var toolboxMetrics = metricsManager.getToolboxMetrics();
  var x = 0;

  // If this flyout is not the trashcan flyout (e.g. toolbox or mutator).
  if (this.targetWorkspace.toolboxPosition == this.toolboxPosition_) {
    // If there is a category toolbox.
    if (this.targetWorkspace.getToolbox()) {
      if (this.toolboxPosition_ == Blockly.utils.toolbox.Position.LEFT) {
        x = toolboxMetrics.width;
      } else {
        x = viewMetrics.width - this.width_;
      }
      // Simple (flyout-only) toolbox.
    } else {
      if (this.toolboxPosition_ == Blockly.utils.toolbox.Position.LEFT) {
        x = 0;
      } else {
        // The simple flyout does not cover the workspace.
        x = viewMetrics.width;
      }
    }
    // Trashcan flyout is opposite the main flyout.
  } else {
    if (this.toolboxPosition_ == Blockly.utils.toolbox.Position.LEFT) {
      x = 0;
    } else {
      // Because the anchor point of the flyout is on the left, but we want
      // to align the right edge of the flyout with the right edge of the
      // blocklyDiv, we calculate the full width of the div minus the width
      // of the flyout.
      x = this.targetWorkspace.trashcan.getBoundingRectangle().left - this.width_ - this.CORNER_RADIUS;
    }
  }

  return x;
};


/**
 * Calculates the y coordinate for the flyout position.
 * @return {number} Y coordinate.
 */
Blockly.VerticalFlyout.prototype.getY = function () {
  const toolbox = this.targetWorkspace.getToolbox();
  const selectedItemOffsetTop = this.getSelectedItemY();
  const calculatedY = Math.max((selectedItemOffsetTop - this.height_ / 2) || 0, 0);
  return calculatedY + this.height_ > toolbox.getHeight() - this.CORNER_RADIUS
    ? toolbox.getHeight() - this.height_ - this.CORNER_RADIUS : calculatedY;
};

Blockly.VerticalFlyout.prototype.getSelectedItemY = function () {
  if (this.targetWorkspace.toolboxPosition === this.toolboxPosition_) {
    // If there is a category toolbox.
    if (this.targetWorkspace.getToolbox()) {
      const toolbox = this.targetWorkspace.getToolbox();
      const selectedItem = toolbox.getSelectedItem().getDiv().getBoundingClientRect();
      return selectedItem.top - toolbox.HtmlDiv.getBoundingClientRect().top
        + +selectedItem.height / 2;
    }
  } else if (this.targetWorkspace.trashcan.contentsIsOpen()) {
    store.getters.backpack.close();
    const trashcanPosition = this.targetWorkspace.trashcan.getBoundingRectangle();
    return (trashcanPosition.top + trashcanPosition.bottom) / 2;
  } else if (store.getters.backpack.isOpen()) {
    if (store.getters.backpack.getCount() === 0) {
      store.getters.backpack.close();
    } else {
      const backpackPosition = store.getters.backpack.getBoundingRectangle();
      return (backpackPosition.top + backpackPosition.bottom) / 2;
    }
  }
};

/**
 * Move the flyout to the edge of the workspace.
 */
Blockly.VerticalFlyout.prototype.position = function () {
  if (!this.isVisible() || !this.targetWorkspace.isVisible()) {
    return;
  }
  const metricsManager = this.targetWorkspace.getMetricsManager();
  const targetWorkspaceViewMetrics = metricsManager.getViewMetrics();

  // Record the height for workspace metrics.
  const blocklyWorkspaceArray = this.svgGroup_.getElementsByClassName('blocklyWorkspace');
  const height = blocklyWorkspaceArray.length
    ? blocklyWorkspaceArray[0].getBBox().height : 0;
  this.height_ = Math.min((height + Blockly.Flyout.prototype.MARGIN * 2) || (targetWorkspaceViewMetrics.height - this.CORNER_RADIUS),
    targetWorkspaceViewMetrics.height - this.CORNER_RADIUS);

  const edgeWidth = this.width_ - 2 * this.CORNER_RADIUS - 2;
  const edgeHeight = this.height_ - 2 * this.CORNER_RADIUS - 2;
  this.atRight_ = true;

  if (this.targetWorkspace.toolboxPosition === this.toolboxPosition_
    && this.targetWorkspace.getToolbox()) {
    this.svgGroup_.classList.add('toolboxBlocklyFlyout');
    this.atRight_ = false;
  } else {
    this.svgGroup_.classList.add('rightBlocklyFlyout');
  }

  const x = this.getX();
  const y = this.getY();

  this.setCustomBackgroundPath_(edgeWidth, edgeHeight, this.getSelectedItemY() - y, this.atRight_);

  this.positionAt_(this.width_ + this.CORNER_RADIUS , this.height_, x, y);
};

Blockly.Flyout.prototype.getFlyoutScale = function() {
  return 1;
};

/**
 * Create and set the path for the visible boundaries of the flyout. Custom for Academia de inventores.
 * @param {number} width The width of the flyout, not including the
 *     rounded corners.
 * @param {number} height The height of the flyout, not including
 *     rounded corners.
 * @private
 */
Blockly.VerticalFlyout.prototype.setCustomBackgroundPath_ = function (width, height, y, atRight) {
  const atRightWidth = width + 2 * this.CORNER_RADIUS + 1;

  const path = [`M ${atRight ? atRightWidth: this.CORNER_RADIUS},${this.CORNER_RADIUS + 1}`];
  path.push('a', this.CORNER_RADIUS, this.CORNER_RADIUS, 0, 0,
    atRight ? 0 : 1, atRight ? - this.CORNER_RADIUS : this.CORNER_RADIUS,
    -this.CORNER_RADIUS);
  // Top.
  path.push('h', atRight ? -width : width);
  // Rounded corner.
  path.push('a', this.CORNER_RADIUS, this.CORNER_RADIUS, 0, 0,
    atRight ? 0 : 1,
    atRight ? -this.CORNER_RADIUS : this.CORNER_RADIUS,
    this.CORNER_RADIUS);
  // Side closest to workspace.
  path.push('v', Math.max(0, height));
  // Rounded corner.
  path.push('a', this.CORNER_RADIUS, this.CORNER_RADIUS, 0, 0,
    atRight ? 0 : 1,
    atRight ? this.CORNER_RADIUS : -this.CORNER_RADIUS,
    this.CORNER_RADIUS);
  // Bottom.
  path.push('h', atRight ? width : -width);
  path.push('a', this.CORNER_RADIUS, this.CORNER_RADIUS, 0, 0,
    atRight ? 0 : 1,
    atRight ? this.CORNER_RADIUS : -this.CORNER_RADIUS,
    -this.CORNER_RADIUS);
  // triangle
  const triangleMiddle = Math.min(Math.max(y, this.CORNER_RADIUS * (1 + 1 / 1.5)),
    this.targetWorkspace.getMetricsManager().getViewMetrics().height - this.getY() - this.CORNER_RADIUS * 3);
  const triangleTop = triangleMiddle - this.CORNER_RADIUS / 1.5;
  const triangleBottom = triangleMiddle - height + this.CORNER_RADIUS * (1 / 1.5 - 1);
  if (triangleBottom < height - this.CORNER_RADIUS) {
    path.push('v', triangleBottom);
    path.push('L', `${atRight ? atRightWidth + this.CORNER_RADIUS: 0},${triangleMiddle}`);
    path.push('L', `${atRight ? atRightWidth : this.CORNER_RADIUS},${triangleTop}`);
  }
  path.push('z');
  this.svgBackground_.setAttribute('d', path.join(' '));
};


Blockly.Flyout.prototype.positionAt_ = function(width, height, x, y) {
  this.svgGroup_.setAttribute("width", width);
  this.svgGroup_.setAttribute("height", height);
  this.workspace_.setCachedParentSvgSize(width, height);

  if (this.svgGroup_.tagName == 'svg') {
    var transform = 'translate(' + x + 'px,' + y + 'px)';
    Blockly.utils.dom.setCssTransform(this.svgGroup_, transform);
  } else {
    // IE and Edge don't support CSS transforms on SVG elements so
    // it's important to set the transform on the SVG element itself
    var transform = 'translate(' + x + ',' + y + ')';
    this.svgGroup_.setAttribute("transform", transform);
  }

  // Update the scrollbar (if one exists).
  var scrollbar = this.workspace_.scrollbar;
  if (scrollbar) {
    // Set the scrollbars origin to be the top left of the flyout.
    scrollbar.setOrigin(this.atRight_ ? x - this.width_ + this.CORNER_RADIUS: x, y);
    scrollbar.resize();
    // If origin changed and metrics haven't changed enough to trigger
    // reposition in resize, we need to call setPosition. See issue #4692.
    if (scrollbar.hScroll) {
      scrollbar.hScroll.setPosition(
        scrollbar.hScroll.position.x, scrollbar.hScroll.position.y);
    }
    if (scrollbar.vScroll) {
      scrollbar.vScroll.setPosition(
        scrollbar.vScroll.position.x, scrollbar.vScroll.position.y);

    }
  }
};
