<template>
  <div class="blockly-container"
  >
    <BlocklyComponent
      id='blockly'
      :options='options'
      :class="{ 'closed': !showHeader}"
      ref='foo'
    />
    <code-editor :code="code" />
    <xml id='startBlocks' style='display: none'>
      <block type='control_arduino_setup' inline='false' x='20' y='20' deletable='false'> </block>
      <block type='control_arduino_loop' inline='false' x='20' y='180' deletable='false'> </block>
    </xml>
  </div>
</template>
<script>

// vuex
import BlocklyStorage from 'blockly/appengine/storage';
import { mapMutations, mapGetters } from 'vuex';

// language
import { AES } from '@academiadeinventores/helloblocks-blocks/src/msg/es';
import { AEN } from '@academiadeinventores/helloblocks-blocks/src/msg/en';
import { ACA } from '@academiadeinventores/helloblocks-blocks/src/msg/ca';
import { AEU } from '@academiadeinventores/helloblocks-blocks/src/msg/eu';

// blockly plugins
import DarkTheme from '@blockly/theme-dark';

// blockly
import Blockly from 'blockly';
import BlocklyComponent from '../components/blockly/BlocklyComponent.vue';

// this plugin must be loaded after blockly component
import '../plugins/block-plus-minus/index';

import { CATEGORY_LANG_MAP } from '../components/blockly/toolbox/toolboxTranslations';
import myToolboxFull from '../components/blockly/toolbox/versions/toolboxFull';
import myToolboxLite from '../components/blockly/toolbox/versions/toolboxLite';

import store from '../store';
import CodeEditor from './Editor.vue';

// https://developers.google.com/blockly/guides/configure/web/configuration_struct
const options = {
  toolbox: myToolboxFull,
  collapse: true,
  comments: true,
  disable: true,
  maxBlocks: Infinity,
  maxInstances: Infinity, // {controls_if: 3, control_arduino_setup: 1, control_arduino_loop: 1 },
  trashcan: true,
  horizontalLayout: false,
  toolboxPosition: 'start',
  css: true,
  rtl: false,
  move: {
    scrollbars: true,
    drag: true,
    wheel: false,
  },
  sounds: true,
  oneBasedIndex: true,
  renderer: 'zelos',
  zoom: {
    controls: true,
    wheel: true,
    startScale: 1,
    maxScale: 3,
    minScale: 0.3,
    scaleSpeed: 1.05,
  },
  media: 'media/',
  grid: {
    spacing: 15,
    length: 2,
    colour: '#a2c9d4',
    snap: true,
  },
};

const BLOCKLY_LANG_MAP = {
  en: AEN(),
  es: AES(),
  ca: ACA(),
  eu: AEU(),
};

export default {
  name: 'BlocklyView',
  components: {
    BlocklyComponent,
    CodeEditor,
  },
  data() {
    return {
      code: '',
      options: JSON.parse(JSON.stringify(options)),
      filenameToSave: '',
    };
  },
  mounted() {
    // Add a change for render the code automatically
    this.$refs.foo.workspace.addChangeListener(this.renderContent);
    window.addEventListener('resize', this.workspaceResizeListener);
    this.$refs.foo.workspace.getToolbox().HtmlDiv
      .getElementsByClassName('blocklyToolboxContents')[0]
      .addEventListener('scroll', this.toolboxScrollListener);
    Blockly.setLocale(this.loadCategoryTranslations(this.$i18n.locale));
    this.$refs.foo.workspace.updateToolbox(this.currentEditor === 'lite' ? myToolboxLite : myToolboxFull);
    const darkMode = /(dark)|(black)/.test(this.interfaceMode);
    this.options = { ...options, sounds: (this.sound === 'sound'), ...(darkMode ? { theme: DarkTheme } : {}) };
    this.loadBlocks();
    window.addEventListener('unload', this.auto_save_and_restore_blocks, false);

    if ('BlocklyStorage' in window) {
      BlocklyStorage.backupOnUnload(this.$refs.foo.workspace);
    }
  },
  computed: {
    ...mapGetters(['locale', 'currentEditor', 'showHeader', 'showOverlay', 'interfaceMode', 'sound', 'projectVersion', 'backpack']),
  },
  updated() {
    this.auto_save_and_restore_blocks();
  },
  beforeDestroy() {
    this.$refs.foo.workspace.removeChangeListener(this.renderContent);
    window.removeEventListener('resize', this.workspaceResizeListener);
    this.$refs.foo.workspace.getToolbox().HtmlDiv
      .getElementsByClassName('blocklyToolboxContents')[0]
      .removeEventListener('scroll', this.toolboxScrollListener);
  },
  watch: {
    locale(val) {
      Blockly.setLocale(this.loadCategoryTranslations(val));
      this.$refs.foo.workspace.updateToolbox(this.currentEditor === 'lite' ? myToolboxLite : myToolboxFull);
      Blockly.Events.disable();
      const xml = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
      Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, this.$refs.foo.workspace);
      Blockly.Events.enable();
    },
    currentEditor(val) {
      this.$refs.foo.workspace.updateToolbox(val === 'lite' ? myToolboxLite : myToolboxFull);
    },
    interfaceMode(val) {
      const darkMode = /(dark)|(black)/.test(val);
      this.options = { ...options, ...(darkMode ? { theme: DarkTheme } : {}) };
    },
    showOverlay() {
      if (!this.showOverlay) {
        this.$refs.foo.workspace.getToolbox().clearSelection();
        this.$refs.foo.workspace.getFlyout().hide();
        const toolboxContent = document.getElementsByClassName('blocklyToolboxContents')[0];
        toolboxContent.classList.remove('someCategorySelected');
      }
    },
    sound(val) {
      const darkMode = /(dark)|(black)/.test(this.interfaceMode);
      this.options = { ...options, sounds: (val === 'sound'), ...(darkMode ? { theme: DarkTheme } : {}) };
    },
    projectVersion() {
      Blockly.Events.disable();
      Blockly.mainWorkspace.clear();
      Blockly.Events.enable();
      Blockly.Xml.domToWorkspace(document.getElementById('startBlocks'), this.$refs.foo.workspace);
      if (this.$refs.foo && this.$refs.foo.workspace.trashcan) {
        this.$refs.foo.workspace.trashcan.emptyContents();
      }
      this.backpack.empty();
    },
  },
  methods: {
    ...mapMutations(['toggleCodeShelf', 'setCodeTab']),
    ...mapMutations(['setCode']),
    ...mapMutations(['setShowOverlay']),

    renderContent(event) {
      this.code = Blockly.Arduino.workspaceToCode(this.$refs.foo.workspace);
      if (([Blockly.Events.BLOCK_DRAG, Blockly.Events.CREATE].indexOf(event.type) > -1) && this.showOverlay) {
        this.setShowOverlay(false);
      }
    },
    workspaceResizeListener() {
      if (this.$refs.foo && this.$refs.foo.workspace.trashcan.contentsIsOpen()) {
        this.$refs.foo.workspace.trashcan.closeFlyout();
      }
      if (store.getters.backpack.isOpen()) {
        store.getters.backpack.close();
      }
    },
    toolboxScrollListener() {
      if (this.$refs.foo.workspace.getFlyout().isVisible()) {
        this.$refs.foo.workspace.getFlyout().position();
      }
    },
    loadBlocks() {
      let loadOnce;
      try {
        loadOnce = window.sessionStorage.loadOnceBlocks;
      } catch (e) {
        loadOnce = null;
      }
      if (loadOnce) {
        // Language switching stores the blocks during the reload.
        delete window.sessionStorage.loadOnceBlocks;
        const xml = Blockly.Xml.textToDom(loadOnce);
        Blockly.Xml.domToWorkspace(xml, this.$refs.foo.workspace);
      } else {
        Blockly.Xml.domToWorkspace(document.getElementById('startBlocks'), this.$refs.foo.workspace);
      }
      Blockly.Events.clearPendingUndo();
    },
    persist() {
      localStorage.myBlocks = this.myBlocks;
    },
    auto_save_and_restore_blocks() {
      if (window.sessionStorage) {
        const xml = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
        const text = Blockly.Xml.domToText(xml);
        window.sessionStorage.loadOnceBlocks = text;
      }
    },
    loadCategoryTranslations(lang) {
      let selectedLang = lang;
      if (BLOCKLY_LANG_MAP[lang] === undefined) {
        selectedLang = this.$i18n.fallbackLocale;
      }
      const translations = BLOCKLY_LANG_MAP[selectedLang];
      const categoryTranslations = CATEGORY_LANG_MAP[selectedLang];
      return { ...translations, ...categoryTranslations };
    },
  },
};
</script>

<style>
.blockly-container {
  position: relative;
  flex: 1;
  margin: 0;
}
#blockly {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
}

.editor {
  height: calc(100% - 100px);
  margin: 60px 0 0 20px;
}
.editor .slider{
  background: var(--v-lightestGrey-base);
  border-radius: 10px;
}
.editor .slider:hover {
   background: var(--v-grey-base);
 }

.blocklyDiv .blocklyMainBackground {
  stroke: none;
}
</style>
