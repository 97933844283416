<template>
<div class="action">
  <button
    class="white--text btn-action"
    @click="action"
  >
    <component :is="type" v-bind="$props" class="icon"/>
  </button>
  </div>
</template>

<script>
import Blockly from 'blockly';
import Undo from '@public/img/menu/undo.svg';
import Redo from '@public/img/menu/redo.svg';

export default {
  props: ['type'],
  components: {
    Undo, Redo,
  },
  methods: {
    action() {
      Blockly.mainWorkspace.undo(this.type === 'redo');
    },
  },
};
</script>

<style scoped>
.action{
  display:grid;
  align-content: center;
}

.btn-action{
  height: 34px;
}

.icon{
    width: 34px;
    height: 34px;
}

@media (max-width: 1000px) {
  .action{
    display:none
  }
}

.action:active rect{
  fill: #d38fbb;
}
</style>
