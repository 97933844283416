import Blockly from 'blockly';

Blockly.Css.register([
  /* eslint-disable indent */
  '.blocklyFlyout {',
    '-webkit-filter: drop-shadow( 8px 8px 8px rgba(0, 0, 0, 0.2));',
    'filter: drop-shadow( 8px 8px 8px rgba(0, 0, 0, 0.2));',
  '}',
  '.blocklyScrollbarHandle {',
    'cursor: pointer;',
    'fill: var(--v-lightestGrey-base);',
  '}',
  '.blocklyScrollbarHandle:hover, .blocklyScrollbarHandle:active {',
    'fill: var(--v-lightGrey-base);',
  '}',
  '.blocklyFlyoutBackground {',
    'fill: white;',
    'fill-opacity: 1;',
    'stroke: var(--v-grey-base);',
    'stroke-width: 1;',
  '}',
  '.toolboxBlocklyFlyout .blocklyWorkspace {',
    'transform: translateX(15px);',
  '}',
  /* eslint-enable indent */
]);
