<template>
  <v-btn
    depressed
    color="toolbox2"
    class="ma-3 text-none rounded-lg white--text"
    @click="toggleCodeShelf"
  >
    <v-icon
      dark
    >
      mdi-xml
    </v-icon>
    <span class="pl-2 show-code-text">
      {{$t('toolbar.show-code')}}
    </span>
  </v-btn>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['toggleCodeShelf', 'setCodeTab']),
  },
};
</script>

<style scoped>
button {
  letter-spacing: normal;
}
@media (max-width: 1000px) {
  .show-code-text {
    display: none;
  }
}
</style>
