<template>
  <div class="cdialog-card-section">
    <div class="cdialog-card-section-title">
      {{ $t(scsTitle) }}
    </div>
    <div v-if="scsSubtitle" class="cdialog-card-section-subtitle">
      {{ $t(scsSubtitle) }}
    </div>
    <div class="cdialog-card-section-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    scsTitle: String,
    scsSubtitle: String,
  },
};
</script>
<style scoped>
.cdialog-card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cdialog-card-section-title {
  color: var(--v-normalText-base);
  font-size: 1.2rem;
  /* font-weight: bolder; */
  margin-bottom: 20px;
}
.cdialog-card-section-subtitle {
  color: var(--v-normalText-base);
  margin-bottom: 20px;
}
</style>
