import Blockly from 'blockly';
import store from '../../../../store';
/**
 * Adds event listeners to the toolbox container div.
 * @param {!Element} container The HTML container for the toolbox.
 * @param {!Element} contentsContainer The HTML container for the contents
 *     of the toolbox.
 * @protected
 */
Blockly.Toolbox.prototype.attachEvents_ = function (container,
  contentsContainer) {
  // Clicking on toolbox closes popups.
  const clickEvent = Blockly.browserEvents.conditionalBind(
    container, 'click', this, this.onCustomClick_,
    /* opt_noCaptureIdentifier */ false,
    /* opt_noPreventDefault */ true,
  );
  this.boundEvents_.push(clickEvent);

  const keyDownEvent = Blockly.browserEvents.conditionalBind(
    contentsContainer, 'keydown', this, this.onKeyDown_,
    /* opt_noCaptureIdentifier */ false,
    /* opt_noPreventDefault */ true,
  );
  this.boundEvents_.push(keyDownEvent);
};

Blockly.Toolbox.prototype.onCustomClick_ = function (e) {
  this.onClick_(e);
  store.commit('setShowOverlay', !!this.getSelectedItem());
  const toolboxContent = document.getElementsByClassName('blocklyToolboxContents')[0];
  if (toolboxContent) {
    if (this.getSelectedItem()) {
      toolboxContent.classList.add('someCategorySelected');
    }
  }
};
