<template>
  <v-dialog v-model="show" max-width="450px" content-class="modal" style="z-index: 1002;" overlay-color="background">
    <template>
      <tools-header :text="$t('screenshot.title')" class="text-sm-body-2" @closed="closeDialog">
        <camera-icon height="25" />
      </tools-header>

      <screenshot-block class="screenshot-image"/>

      <div class="box">
        <input v-model="imageName" :placeholder="$t('screenshot.write-name')" @keyup.enter="confirm" />
      </div>
      <v-flex class="d-flex justify-center">
        <!-- <new-project-icon height="128" /> -->
      </v-flex>
      <v-card-actions class="pb-6 justify-center">
        <v-btn class="lightText--text green mr-2 px-4" style="text-transform: none;" text @click="confirm">{{
          $t('commons.download')
        }}</v-btn>
        <v-btn class="normalText--text lightGrey ml-2 px-4" style="text-transform: none;" text @click="closeDialog">{{
          $t('commons.cancel')
        }}</v-btn>
      </v-card-actions>
    </template>
  </v-dialog>
</template>

<script>
import CameraIcon from '@public/img/icons/iconos_svg_v1_screenshot-122.svg';
import ScreenshotBlock from '@public/img/icons/iconos_svg_v1_blockly_screenshot.svg';
import ToolsHeader from '../tools/header.vue';

export default {
  data() {
    return {
      imageName: '',
    };
  },
  components: {
    ToolsHeader,
    CameraIcon,
    ScreenshotBlock,
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('closed', value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit('closed');
    },
    confirm() {
      this.show = false;
      this.$emit('download', this.imageName);
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style>
.import-button {
  cursor: pointer;
}
.import-button:hover {
  background: var(--v-toolbox1-lighten1);
}
.modal {
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-lightGrey-base);
}

.box {
  position: relative;
  padding: 2px;
  margin: 0px 40px 30px 40px;
  border-radius: 5px;
  border: 2px solid var(--v-off-base);
  width: auto;
}

.box > input {
  position: relative;
  z-index: 3;
  border: none;
  background: transparent;
  width: 100%;
  padding-left: 5px;
  color: var(--v-normalText-base);
}

.box:after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: var(--v-background-base);
  content: ' ';
  border-radius: 5px;
}

.box:before {
  position: absolute;
  left: -5px;
  top: 9px;
  display: block;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  content: ' ';
  background: var(--v-background-base);
  z-index: 0;
  border: 2px solid var(--v-off-base);
}

.screenshot-image {
  height: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
