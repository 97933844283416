/* eslint-disable import/prefer-default-export */
export const CATEGORY_LANG_MAP = {
  en: {
    CAT_LOGIC: 'Logic',
    CAT_CONTROL: 'Control',
    CAT_MATH: 'Math',
    CAT_TEXT: 'Text',
    CAT_VARIABLES: 'Variables',
    CAT_LISTS: 'Lists',
    CAT_FUNCTIONS: 'Functions',
    CAT_IO: 'In/Out',
    CAT_TIME: 'Time',
    CAT_SERIAL_PORT: 'Serial Port',
    CAT_SENSORS: 'Sensors',
    CAT_ACTUATORS: 'Actuators',
    CAT_LCD_SCREEN: 'LCD Display',
    CAT_LED_MATRIX: 'LED Matrix 8x8',
    CAT_NEO_PIXEL: 'NeoPixel',
    CAT_MOTOR: 'Motor',
    CAT_RTC_CLOCK: 'Digital clock',
    CAT_SD_CARD: 'SD Card',
    CAT_BLUETOOTH: 'Bluetooth',
    CAT_WIFI: 'Wifi',
    CAT_IR: 'Infrared',
    LABEL_NUMERIC_VARIABLES: 'Numeric variables',
    LABEL_TEXT_VARIABLES: 'Text variables',
    LABEL_BOOL_VARIABLES: 'Boolean variables',
    LABEL_LOGIC_OPERATIONS: 'Logic operations',
    LABEL_BOOL_COMPARATOR: 'Boolean comparator',
    LABEL_LISTS_NUMBERS: 'Listas numéricas',
    LABEL_LISTS_TEXT: 'Listas textuales',
    LABEL_MULTIPROTOCOL: 'Multiprotocol',
    LABEL_EMIT_NUMBERS: 'Send numbers',
    LABEL_MOTORSHIELD_SERVO: 'Servo',
    LABEL_MOTOR_STEPPER: 'Stepper',
    LABEL_MOTOR_DC: 'DC Motor',
    LABEL_IOT_IFTTT: 'IFTTT IoT',
    LABEL_IOT_THINGER: 'Thinger IoT',
    LABEL_IOT_THINGSPEAK: 'ThingSpeak IoT',
  },
  es: {
    CAT_LOGIC: 'Lógica',
    CAT_CONTROL: 'Control',
    CAT_MATH: 'Matemáticas',
    CAT_TEXT: 'Texto',
    CAT_VARIABLES: 'Variables',
    CAT_LISTS: 'Listas',
    CAT_FUNCTIONS: 'Funciones',
    CAT_IO: 'Entrada/Salida',
    CAT_TIME: 'Tiempo',
    CAT_SERIAL_PORT: 'Puerto Serie',
    CAT_SENSORS: 'Sensores',
    CAT_ACTUATORS: 'Actuadores',
    CAT_LCD_SCREEN: 'Pantalla LCD',
    CAT_LED_MATRIX: 'Matriz LED 8x8',
    CAT_NEO_PIXEL: 'NeoPixel',
    CAT_MOTOR: 'Motor',
    CAT_RTC_CLOCK: 'Reloj digital',
    CAT_SD_CARD: 'Tarjeta SD',
    CAT_BLUETOOTH: 'Bluetooth',
    CAT_WIFI: 'Wifi',
    CAT_IR: 'Infrarrojos',
    LABEL_NUMERIC_VARIABLES: 'Variables numéricas',
    LABEL_TEXT_VARIABLES: 'Variables textuales',
    LABEL_BOOL_VARIABLES: 'Variables booleanas',
    LABEL_LOGIC_OPERATIONS: 'Operaciones lógicas',
    LABEL_BOOL_COMPARATOR: 'Comparador booleano',
    LABEL_LISTS_NUMBERS: 'Listas numéricas',
    LABEL_LISTS_TEXT: 'Listas textuales',
    LABEL_MULTIPROTOCOL: 'Multiprotocolo',
    LABEL_EMIT_NUMBERS: 'Emitir números',
    LABEL_MOTORSHIELD_SERVO: 'Servo actuador',
    LABEL_MOTOR_STEPPER: 'Motor de pasos',
    LABEL_MOTOR_DC: 'Motor de corriente continua',
    LABEL_IOT_IFTTT: 'IFTTT IoT',
    LABEL_IOT_THINGER: 'Thinger IoT',
    LABEL_IOT_THINGSPEAK: 'ThingSpeak IoT',
  },
  ca: {
    CAT_LOGIC: 'Lògica',
    CAT_CONTROL: 'Control',
    CAT_MATH: 'Matemàtiques',
    CAT_TEXT: 'Text',
    CAT_VARIABLES: 'Variables',
    CAT_LISTS: 'Llistes',
    CAT_FUNCTIONS: 'Funcions',
    CAT_IO: 'Entrada/Sortida',
    CAT_TIME: 'Temps',
    CAT_SERIAL_PORT: 'Port Sèrie',
    CAT_SENSORS: 'Sensors',
    CAT_ACTUATORS: 'Actuadors',
    CAT_LCD_SCREEN: 'Pantalla LCD',
    CAT_LED_MATRIX: 'Matriu LED 8x8',
    CAT_NEO_PIXEL: 'NeoPixel',
    CAT_MOTOR: 'Motor',
    CAT_RTC_CLOCK: 'Rellotge digital',
    CAT_SD_CARD: 'Targeta SD',
    CAT_BLUETOOTH: 'Bluetooth',
    CAT_WIFI: 'Wifi',
    CAT_IR: 'Infrarojos',
    LABEL_NUMERIC_VARIABLES: 'Variables numèriques',
    LABEL_TEXT_VARIABLES: 'Variables textuals',
    LABEL_BOOL_VARIABLES: 'Variables booleanes',
    LABEL_LOGIC_OPERATIONS: 'Operacions lògiques',
    LABEL_BOOL_COMPARATOR: 'Comparador booleà',
    LABEL_LISTS_NUMBERS: 'Llistes numèriques',
    LABEL_LISTS_TEXT: 'Llistes textuals',
    LABEL_MULTIPROTOCOL: 'Multiprotocol',
    LABEL_EMIT_NUMBERS: 'Emet nombres',
    LABEL_MOTORSHIELD_SERVO: 'Servo actuador',
    LABEL_MOTOR_STEPPER: 'Motor de passos',
    LABEL_MOTOR_DC: 'Motor de corrent continu',
    LABEL_IOT_IFTTT: 'IFTTT IoT',
    LABEL_IOT_THINGER: 'Thinger IoT',
    LABEL_IOT_THINGSPEAK: 'ThingSpeak IoT',
  },
  eu: {
    CAT_LOGIC: 'Logika',
    CAT_CONTROL: 'Kontrola',
    CAT_MATH: 'Matematika',
    CAT_TEXT: 'Testua',
    CAT_VARIABLES: 'Aldagaiak',
    CAT_LISTS: 'Zerrendak',
    CAT_FUNCTIONS: 'Funtzioak',
    CAT_IO: 'Sarrera/Irteera',
    CAT_TIME: 'Denbora',
    CAT_SERIAL_PORT: 'Serieko ataka',
    CAT_SENSORS: 'Sentsoreak',
    CAT_ACTUATORS: 'Eragingailuak',
    CAT_LCD_SCREEN: 'LCD pantaila',
    CAT_LED_MATRIX: 'LED 8x8 matrizea',
    CAT_NEO_PIXEL: 'NeoPixel',
    CAT_MOTOR: 'Motorra',
    CAT_RTC_CLOCK: 'Erloju digitala',
    CAT_SD_CARD: 'SD txartela',
    CAT_BLUETOOTH: 'Bluetooth',
    CAT_WIFI: 'Wifi',
    CAT_IR: 'Infragorriak',
    LABEL_NUMERIC_VARIABLES: 'Zenbakizko aldagaiak',
    LABEL_TEXT_VARIABLES: 'Testu aldagaiak',
    LABEL_BOOL_VARIABLES: 'Aldagai boolearrak',
    LABEL_LOGIC_OPERATIONS: 'Eragiketa logikoak',
    LABEL_BOOL_COMPARATOR: 'Konparadore boolearra',
    LABEL_LISTS_NUMBERS: 'Zenbaki zerrendak',
    LABEL_LISTS_TEXT: 'Testu zerrendak',
    LABEL_MULTIPROTOCOL: 'Multiprotokoloa',
    LABEL_EMIT_NUMBERS: 'Zenbakiak igortzea',
    LABEL_MOTORSHIELD_SERVO: 'Serbo eragingailua',
    LABEL_MOTOR_STEPPER: 'Urratsez urratseko motorra',
    LABEL_MOTOR_DC: 'DC Motorra',
    LABEL_IOT_IFTTT: 'IFTTT IoT',
    LABEL_IOT_THINGER: 'Thinger IoT',
    LABEL_IOT_THINGSPEAK: 'ThingSpeak IoT',
  },
};
