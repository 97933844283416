<template>
  <div style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: center;">
    <div class="progress-container">
      <div id="bg" v-if="showRocks">
        <div id="line-bg"></div>
        <rock id="bg-rock" width="50px"></rock>
      </div>

      <div id="buffo-container">
        <div class="buffo"></div>
        <div class="buffo" style="margin-top: 10px"></div>
      </div>

      <div class="char-container">
        <div id="char">
          <char width="80px"></char>
        </div>
        <div id="leg" class="leg"></div>
        <div id="leg2" class="leg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Rock from '@public/upload/rock.svg';
import Char from '@public/upload/char.svg';

export default {
  props: {
    showRocks: {
      value: true,
      default: false,
      type: Boolean,
    },
  },
  components: {
    Rock,
    Char,
  },
};
</script>

<style>
#bg {
  position: absolute;
  z-index: 5;
  width: 240px;
  height: 40px;
  overflow: hidden;
  margin-top: 40px;
}
#char {
  animation: run 1.5s linear infinite;
}

.char-container {
  position: absolute;
  z-index: 5;
}

.progress-container {
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  align-items: center;
}

#buffo-container {
  width: 50px;
  transform: rotate(180deg);
  margin-right: 130px;
}

.buffo:nth-child(2) {
  animation-delay: 0.3s;
}

.leg {
  width: 12px;
  height: 30px;
  background-color: #e5867b;
  transform-origin: top center;
  position: absolute;
  margin-left: 40px;
  margin-top: -40px;
  border-radius: 8px;
}

#leg {
  animation: walk 0.6s linear infinite;
}

#leg2 {
  animation: walk2 0.6s linear infinite;
}

.buffo {
  background: #eee;
  border-radius: 8px;
  width: 100%;
  height: 4px;
  animation: 1s linear infinite buffo;
}

#bg-rock {
  animation: rock linear 1.5s infinite;
  margin-top: 10px;
}

@keyframes walk {
  0% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(-25deg);
  }
}

@keyframes walk2 {
  0% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(25deg);
  }
}

@keyframes rock {
  from {
    transform: translateX(400px);
  }
  to {
    transform: translateX(-100px);
  }
}

@keyframes run {
  50% {
    transform: scale(1.1);
  }
}

@keyframes buffo {
  50% {
    width: 92%;
  }
}
</style>
