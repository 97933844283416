<template>
  <v-btn
    icon
    large
    color="darkText"
    class="ml-8"
    @click="toggleIcon"
  >
    <v-icon>{{showHeader ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'}}</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters(['showHeader']),
  },
  methods: {
    ...mapMutations(['toggleShowHeader']),
    toggleIcon() {
      const timeout = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        clearTimeout(timeout);
      }, 500);
      this.toggleShowHeader();
    },
  },
};
</script>
