<template>
  <div class="d-flex align-center">
    <small class="mr-2">
      {{$t(`commons.${currentServer.valid ? 'online' : 'offline'}`)}}
    </small>
    <footer-status-dot :valid="currentServer.valid" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FooterStatusDot from './footer-status-dot.vue';

export default {
  components: {
    FooterStatusDot,
  },
  computed: {
    ...mapGetters({ currentServerId: 'currentServer' }),
    currentServer() {
      const { Server } = this.$FeathersVuex.api;
      return Server.findInStore({ query: { uuid: this.currentServerId } }).data[0] || {};
    },
  },
};
</script>
