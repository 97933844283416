<template>
<div id="bar-container">
  <v-progress-linear
    id="bar"
    rounded
    height="30px"
    background-opacity="0"
    :color="barColor"
    :value="progress"
  ></v-progress-linear>
  <lines id="lines"></lines>
</div>
</template>

<script>
import Lines from '@public/upload/progress.svg';

export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    failed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Lines,
  },
  computed: {
    barColor() {
      return this.failed
        ? this.$vuetify.theme.currentTheme.orange
        : this.$vuetify.theme.currentTheme.green;
    },
  },
};
</script>

<style>
#bar-container {
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  background: #ccc;
  display: flex;
  height: 30px;
  width: 100%;
  align-content: center;
}

#bar {
  z-index: 2;
}

#lines {
  animation: 2s linear infinite loading;
  position: absolute;
  z-index: 1;
  height: 30px;
  margin-top: 5px;
}

@keyframes loading {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-114px);
  }
}
</style>
