/* eslint-disable */
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Helper and utility methods for the comment context menu.
 * @author la_madre_del_topo
 */

import * as Blockly from 'blockly/core';

/**
 * Registers a context menu option to empty the backpack when right-clicked.
 * @param {!Blockly.WorkspaceSvg} workspace The workspace to register the
 *   context menu option on.
 */
export function registerContextMenu(workspace) {
  workspace.configureContextMenu = (menuOptions, e) => {
    // Adds a default-sized workspace comment to the workspace.
    menuOptions.push(Blockly.ContextMenu.workspaceCommentOption(workspace, e));
  };
}
