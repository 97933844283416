<template>
  <div class="container fill-height">
    <div class="item" @click="clickedSave('electrolabs')">
      <electro-lab-icon />
      <p>{{ $t('settings.board.electrolab-board') }}</p>
    </div>
    <div class="item" @click="clickedSave('cirkids')">
      <cirkids-icon />
      <p>{{ $t('settings.board.cirkids-board') }}</p>
    </div>
    <div class="item" @click="clickedSave('uno-board')">
      <uno-icon/>
      <p>{{ $t('settings.board.uno-board') }}</p>
    </div>
    <div class="item" @click="clickedSave('nano-board')">
      <nano-icon/>
      <p>{{ $t('settings.board.nano-board') }}</p>
    </div>
  </div>
</template>
<script>

import UnoIcon from '@public/img/icons/settings/uno_v0.svg';
import NanoIcon from '@public/img/icons/settings/nano_v0.svg';
import CirkidsIcon from '@public/img/icons/settings/cirkids_v0.svg';
import ElectroLabIcon from '@public/img/icons/settings/electrolab_ilustración_v3.svg';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    NanoIcon, UnoIcon, CirkidsIcon, ElectroLabIcon,
  },
  methods: {
    ...mapMutations(['setCurrentEditor']),
    clickedSave(id) {
      this.setCurrentEditor(id === 'cirkids' ? 'lite' : 'full');
      this.updateBoard(id === 'nano-board' ? this.boards.arduinoNano : this.boards.arduinoUno);
      this.updateBoardCustomName(id);
      this.$emit('response', 'close');
    },
    updateBoard(value) {
      this.$store.commit('setCurrentBoard', value);
      if (value === this.boards.arduinoNano) {
        const { Board } = this.$FeathersVuex.api;
        const board = Board.findInStore({ query: { uuid: value } }).data[0] || {};
        const nanoConfig = { option: 'cpu', value: 'atmega328old' };

        // eslint-disable-next-line camelcase
        const config_options = board.config_options.map((con) => (
          con.option !== nanoConfig.option ? { ...con } : {
            ...con,
            values: con.values.map((val) => ({ ...val, selected: val.value === nanoConfig.value })),
          }));
        const config = { [nanoConfig.option]: nanoConfig.value };
        (new this.$FeathersVuex.api.Board({
          ...board,
          config_options,
          config,
        })).save();
      }
    },
    updateBoardCustomName(value) {
      this.$store.commit('setCurrentBoardCustomName', value);
    },
  },
  computed: {
    ...mapGetters('boards', { findBoards: 'find' }),
    boards() {
      const boards = this.findBoards({ query: { $sort: { name: 1 } } }).data;
      return {
        arduinoUno: boards.find((b) => b.fqbn === 'arduino:avr:uno')?.uuid,
        arduinoNano: boards.find((b) => b.fqbn === 'arduino:avr:nano')?.uuid,
      };
    },
  },
};
</script>
<style scoped>
.container {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.item {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid white;
  border-radius: 20px;
  transition: border 0.2s linear;
  &:hover {
    border: 2px solid #bdbdbd;
  }
}
</style>
