<template>
  <v-tooltip bottom content-class="tooltip-content">
    <template #activator="{ on }">
      <span v-on="on">
        <upload-btn :valid="valid" id="upload"/>
      </span>
    </template>
    <upload-tooltip :server-valid="currentServer.valid" :board-valid="currentBoard" :current-device="currentDevice" />
    <upload-dialog activator="#upload"/>
  </v-tooltip>
</template>

<script>

import UploadBtn from './upload-btn.vue';
import UploadTooltip from './upload-tooltip.vue';
import UploadDialog from '../../../program/upload-dialog/upload-dialog.vue';

export default {
  components: {
    UploadTooltip,
    UploadBtn,
    UploadDialog,
  },
  props: ['currentServer', 'currentBoard', 'currentDevice'],
  computed: {
    valid() {
      return !!(
        this.currentServer.valid
        && this.currentBoard
        && this.currentDevice
      );
    },
  },
};
</script>

<style scoped>
button {
  position: relative;
}
button.connect {
  margin-left: 2em;
}
.custom-text {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
}

.tooltip-content {
  background-color: var(--v-background-base);
  opacity: 1 !important;
  border: 1px solid var(--v-grey-base);
  border-radius: 1em;
}

.tooltip-content:before {
  content: "";
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid var(--v-grey-base);
  right: 30%;
  top: -40px;
}

.tooltip-content:after {
  content: "";
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid var(--v-background-base);
  right: 30%;
  top: -39px;
}

</style>
