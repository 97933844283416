<template>
  <div class="cdialog-card">
    <div v-if="!dscDisableClose" class="cdialog-card-close">
      <v-btn icon @click="emitResponse('close');slotProps.close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card-title class="cdialog-card-title">
      {{ $t(dTitle) }}
    </v-card-title>
    <v-card-actions class="cdialog-card-actions">
      <div class="cdialog-card-action">
        <div class="cdialog-card-action-icon">
          <windows-icon class="cdialog-card-action-btn" width="70"
            @click="downloadDriver('Win')"/>
        </div>
      </div>
      <div class="cdialog-card-action">
        <div class="cdialog-card-action-icon">
          <mac-icon class="cdialog-card-action-btn" width="70"
            @click="downloadDriver('Mac')"/>
        </div>
      </div>
      <div class="cdialog-card-action">
        <div class="cdialog-card-action-icon">
          <linux-icon class="cdialog-card-action-btn" width="70"
            @click="downloadDriver('Linux')"/>
        </div>
      </div>
    </v-card-actions>
    <div class="cdialog-card-install">
      <div class="cdialog-card-install-title">
        {{ $t(dInstallTitle) }}
      </div>
      <div class="cdialog-card-install-body">
        <div class="cdialog-card-install-step">
          <download-icon class="cdialog-card-install-icon" />
          <div class="cdialog-card-install-step-body">
            <div class="cdialog-card-install-step-body-num">
              <span>1</span>
            </div>
            <div class="cdialog-card-install-step-body-txt">
              {{ $t(dInstallStep1) }}
            </div>
          </div>
        </div>
        <div class="cdialog-card-install-step">
          <unzip-icon class="cdialog-card-install-icon" />
          <div class="cdialog-card-install-step-body">
             <div class="cdialog-card-install-step-body-num">
              <span>2</span>
            </div>
            <div class="cdialog-card-install-step-body-txt">
              {{ $t(dInstallStep2) }}
            </div>
          </div>
        </div>
        <div class="cdialog-card-install-step">
          <check-icon class="cdialog-card-install-icon" />
          <div class="cdialog-card-install-step-body">
             <div class="cdialog-card-install-step-body-num">
              <span>3</span>
            </div>
            <div class="cdialog-card-install-step-body-txt">
              {{ $t(dInstallStep3) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WindowsIcon from '@public/img/icons/iconos_svg_41.svg';
import MacIcon from '@public/img/icons/iconos_svg_42.svg';
import LinuxIcon from '@public/img/icons/iconos_svg_43.svg';
import DownloadIcon from '@public/img/icons/iconos_svg_download_v0.svg';
import UnzipIcon from '@public/img/icons/iconos_svg_abrir_v0.svg';
import CheckIcon from '@public/img/icons/iconos_svg_comprobar_v0.svg';

const SO_DRIVERS = {
  Win: { name: 'windows_drivers.zip', url: 'drivers/drivers-windows.zip' },
  Mac: { name: 'mac_os_drivers.zip', url: 'drivers/drivers-mac_os.zip' },
  Linux: { name: 'linux_drivers.zip', url: 'drivers/drivers-linux.zip' },
};

export default {
  components: {
    WindowsIcon,
    MacIcon,
    LinuxIcon,
    DownloadIcon,
    UnzipIcon,
    CheckIcon,
  },
  data: () => ({
    dTitle: 'connection-guide.dialog-5.title',
    dInstallTitle: 'connection-guide.dialog-5.install.title',
    dInstallStep1: 'connection-guide.dialog-5.install.step1',
    dInstallStep2: 'connection-guide.dialog-5.install.step2',
    dInstallStep3: 'connection-guide.dialog-5.install.step3',
  }),
  props: {
    slotProps: Object,
    dscDisableClose: Boolean,
  },
  methods: {
    downloadDriver(so) {
      // TODO: Ajustar a ficheros de drivers reales
      if (so) {
        const link = document.createElement('a');
        link.download = SO_DRIVERS[so].name;
        link.href = SO_DRIVERS[so].url;
        link.click();
      }
    },
    emitResponse(response) {
      this.$emit('response', response);
    },
  },
};
</script>
<style scoped>
  .cdialog-card {
    padding: 20px;
    font-family: "Lemur";
  }
  .cdialog-card-close {
    height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .cdialog-card-title {
    color: var(--v-darkText-base);
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    word-break: normal;
    margin-bottom: 10px;
  }
  .cdialog-card-actions {
    width: 100%;
    justify-content: center;
    margin: 10px 0px 20px;
  }
  .cdialog-card-action {
    margin: 0 10px;
  }
  .cdialog-card-action-icon svg {
    width: 90px;
  }
  .cdialog-card-action-btn {
    cursor: pointer;
  }
  .cdialog-card-install {
    display: flex;
    flex-direction: column;
  }
  .cdialog-card-install-title {
    text-align: center;
    font-size: 1.1rem;
  }
  .cdialog-card-install-body {
    display: flex;
    margin-top: 30px;
  }
  .cdialog-card-install-step {
    display: flex;
    flex-direction: column;
    flex: 1
  }
  .cdialog-card-install-icon {
    height: 60px;
  }
  .cdialog-card-install-step-body {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
  }
  .cdialog-card-install-step-body-num {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    color: var(--v-lightText-base);
    background-color: var(--v-orange-base);
    margin: 0 10px;
  }
  .cdialog-card-install-step-body-num span{
    width: 25px;
    display: inline-block;
    text-align: center;
  }
  .cdialog-card-install-step-body-txt {
    font-size: .92rem;
  }
</style>
