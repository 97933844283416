<template>
  <div>
    <v-dialog v-model="dialog" :activator="activator" max-width="1000" overlay-opacity="0.8"
              overlay-color="background" content-class="upload-modal" style="z-index: 1002;" persistent>
      <div id="content">
        <div id="animation">
          <v-scroll-x-transition hide-on-leave>
            <running v-if="!failed && !completed" show-rocks></running>
            <finished v-if="!failed && completed"></finished>
            <failing v-if="failed"></failing>
          </v-scroll-x-transition>
        </div>
        <div id="progress-bar">
          <v-container>
            <v-row align="center" class="ml-0 ">
              <progress-bar :progress="progress" :failed="failed"></progress-bar>
            </v-row>
          </v-container>
        </div>
        <upload-dialog-fail v-if="failed" @retry="retry" @whatHappened="whatHappenedClicked" @closed="close" />
      </div>
    </v-dialog>
    <upload-dialog-errors :show="whatHappened" :log="log" :cause="cause" @closed="whatHappened = false" />
  </div>
</template>

<script>

import Finished from '../headers/finished.vue';
import Failing from '../headers/failing.vue';
import Running from '../headers/running.vue';

import ProgressBar from '../components/progress-bar.vue';
import UploadDialogFail from './upload-dialog-fail.vue';
import UploadDialogErrors from './upload-dialog-errors.vue';

export default {
  props: ['activator'],
  components: {
    ProgressBar,
    Running,
    Failing,
    Finished,
    UploadDialogFail,
    UploadDialogErrors,
  },
  data() {
    return {
      dialog: false,
      progress: 0,
      failed: false,
      completed: false,
      activity: '',
      cause: '',
      showExtended: false,
      log: '',
      whatHappened: false,
      pogressSimulationInterval: null,
    };
  },
  mounted() {
    this.$compiler.on('compiler.progress', this.progressCB);
  },
  unmounted() {
    // this.$compiler.off('compiler.progress', this.progressCB);
  },
  methods: {
    progressCB(data) {
      if (!data.error && data.state === 'compile') {
        this.activity = 'compile.title.compiling';
        this.progress = data.percent * 100;
      }
      if (!data.error && data.state === 'upload') {
        if (data.percent === 1) {
          this.activity = 'compile.title.complete';
          this.progress = 100;
          this.completed = true;
          setTimeout(() => {
            this.dialog = false;
          }, 1000);
        } else {
          this.activity = 'compile.title.uploading';
          this.progress = data.percent * 100;
          this.pogressSimulationInterval = setInterval(() => {
            this.progress += 5;
          }, 1000);
        }
      }
      if (data.error) {
        this.failed = true;
        this.progress = 100;
        if (data.state === 'compile') {
          this.cause = 'compiling';
          this.activity = 'compile.title.error-compiling';
          this.log = data.message;
        }
        if (data.state === 'upload') {
          this.cause = 'uploading';
          this.activity = 'compile.title.error-uploading';
        }
        this.showExtended = true;
      }
    },
    close() {
      this.dialog = false;
      this.progress = 0;
      this.failed = false;
      this.completed = false;
      this.activity = '';
      this.cause = '';
      this.showExtended = false;
    },
    compile() {
      this.failed = false;
      this.progress = 0;
      this.completed = false;
      this.cause = '';
      this.activity = 'compile.title.compiling';
      this.showExtended = false;

      // TODO remove this timeout?
      setTimeout(() => {
        this.$compiler.upload();
      }, 0);
    },
    retry() {
      this.compile();
    },
    whatHappenedClicked() {
      this.dialog = false;
      this.progress = 0;
      this.failed = false;
      this.completed = false;
      this.activity = '';
      this.showExtended = false;
      this.whatHappened = true;
    },
  },
  watch: {
    progress(newVal) {
      return newVal > 100 ? 100 : newVal;
    },
    dialog(newVal) {
      if (newVal) {
        this.compile();
      } else {
        clearInterval(this.pogressSimulationInterval);
        this.pogressSimulationInterval = null;
      }
    },
  },
};
</script>

<style>
#content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#animation {
  height: 200px;
}
#progress-bar {
  height: 100px;
}
#progress-bar .row {
  width: 100%;
  height: 100%;
  align-items: center;
}
.upload-modal {
  position: absolute;
  top: 20vh;
  border: none;
  box-shadow: none;
  overflow: hidden;
}
</style>
