<template>
  <v-dialog
    max-width="600"
    overlay-color="background"
    v-model="dialog"
    content-class="modal"
    style="z-index: 1002;"
  >
    <template v-slot:activator="{ on }">
      <div class="new-button" v-on="on">
        <button class="ma-4 pl-2 d-flex align-items">
          <v-row align-content="center">
            <settings-icon width="16"/>
            <div class="text-body-2 ml-2 white--text d-flex align-center">{{ $t('toolbar.settings') }}</div>
          </v-row>
        </button>
      </div>
    </template>
    <template>
      <settings-card @response="closeDialog" />
    </template>
  </v-dialog>
</template>

<script>
import SettingsIcon from '@public/img/menu/settings.svg';
import SettingsCard from '../../dialogs/cards/settings-card.vue';

export default {
  components: {
    SettingsIcon,
    SettingsCard,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('closed');
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style>

.new-button:hover {
  background: var(--v-toolbox1-lighten1);
}
.modal {
  overflow: hidden;
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-greyShadow-base);
}
</style>
