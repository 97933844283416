<template>
  <v-dialog v-model="dialog" max-width="1000" overlay-color="background"
            content-class="dialog-errors" style="z-index: 1003;">
    <template>
      <v-card-text>
          <v-container>
            <upload-dialog-compiler v-if="cause === 'compiling'" :log="log"/>
            <v-row justify="center" v-else>
              <upload-dialog-path />
            </v-row>
            <v-row justify="center" class="mt-6">
            <ok-button class="d-flex justify-center align-center pa-8" @click="dialog=false" color="green"/>
            </v-row>
          </v-container>
      </v-card-text>
    </template>
  </v-dialog>
</template>

<script>
import OkButton from '../components/ok-button.vue';
import UploadDialogCompiler from './upload-dialog-compiler.vue';
import UploadDialogPath from './upload-dialog-path.vue';

export default {
  props: ['show', 'cause', 'log'],
  components: {
    OkButton,
    UploadDialogCompiler,
    UploadDialogPath,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style>
.dialog-errors {
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-greyShadow-base);
}
</style>
