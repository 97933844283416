export default `<xml style='display: none'>
<category name="%{BKY_CAT_LOGIC}" css-row="blocklyTreeRowLogic" css-icon="blocklyToolboxLogic">
  <label text="%{BKY_LABEL_LOGIC_OPERATIONS}"></label>
  <block type="controls_if"></block>
  <block type="logic_compare"></block>
  <block type="logic_operation"></block>
  <block type="logic_negate"></block>
  <block type="logic_boolean"></block>
  <block type="logic_boolean_io"></block>
  <label text="%{BKY_LABEL_BOOL_COMPARATOR}"></label>
  <block type="logic_compare_bool"></block>
</category>
<category name="%{BKY_CAT_CONTROL}" css-row="blocklyTreeRowControl" css-icon="blocklyToolboxControl">
  <block type="controls_repeat_ext">
    <value name="TIMES">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
  </block>
  <block type="controls_for">
    <field name="VAR">i</field>
    <value name="FROM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">9</field>
      </shadow>
    </value>
    <value name="BY">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="controls_whileUntil"></block>
  <block type="control_wait_whileUntil"></block>
  <block type="control_group"></block>
</category>
<category name="%{BKY_CAT_MATH}" css-row="blocklyTreeRowMath" css-icon="blocklyToolboxMath">
  <block type="math_number"></block>
  <block type="math_arithmetic"></block>
  <block type="math_map">
    <value name="FROMLOW">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="FROMHIGH">
      <shadow type="math_number">
        <field name="NUM">1023</field>
      </shadow>
    </value>
    <value name="TOLOW">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TOHIGH">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_change">
    <value name="DELTA">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="math_constrain">
    <value name="LOW">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="HIGH">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_to_int"></block>
  <block type="math_to_uint"></block>
  <block type="math_angle"></block>
  <block type="math_single"></block>
  <block type="math_random_int">
    <value name="FROM">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_modulo"></block>
  <block type="math_atan2"></block>
</category>
<category name="%{BKY_CAT_TEXT}" css-row="blocklyTreeRowText" css-icon="blocklyToolboxText">
  <block type="text"></block>
  <block type="text_join"></block>
  <block type="text_compare"></block>
  <block type="text_tonumber">
    <value name="TXT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="text_format_decimal">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
      <field name="DECIMALS">4</field>
    </value>
  </block>
  <block type="text_format">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="text_length"></block>
  <block type="text_contains"></block>
  <block type="text_indexof"></block>
  <block type="text_substring"></block>
  <block type="text_replace"></block>
  <block type="text_ascii"></block>
</category>
<category name="%{BKY_CAT_VARIABLES}" css-row="blocklyTreeRowVariable" css-icon="blocklyToolboxVariable">
  <label text="%{BKY_LABEL_NUMERIC_VARIABLES}"></label>
  <block type="variables_set_number">
    <value name="VALUE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="variables_get_number"></block>
  <label text="%{BKY_LABEL_TEXT_VARIABLES}"></label>
  <block type="variables_set_text">
    <value name="VALUE">
      <shadow type="text"></shadow>
    </value>
  </block>
  <block type="variables_get_text"></block>
  <label text="%{BKY_LABEL_BOOL_VARIABLES}"></label>
  <block type="variables_set_bool">
    <value name="VALUE">
      <shadow type="logic_boolean_io"></shadow>
    </value>
  </block>
  <block type="variables_get_bool"></block>
</category>
<category name="%{BKY_CAT_LISTS}" css-row="blocklyTreeRowLists" css-icon="blocklyToolboxLists">
  <label text="%{BKY_LABEL_LISTS_NUMBERS}"></label>
  <block type="lists_create_with_number"></block>
  <block type="lists_getIndex_number">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lists_setIndex_number">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lists_length_number"></block>
  <label text="%{BKY_LABEL_LISTS_TEXT}"></label>
  <block type="lists_create_with_text"></block>
  <block type="lists_getIndex_text">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lists_setIndex_text">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="text">
        <field name="TEXT">A</field>
      </shadow>
    </value>
  </block>
  <block type="lists_length_text"></block>
</category>
<category name="%{BKY_CAT_FUNCTIONS}" custom="PROCEDURE" css-row="blocklyTreeRowFunctions" css-icon="blocklyToolboxFunctions">
</category>
<sep></sep>
<category name="%{BKY_CAT_SENSORS}" css-row="blocklyTreeRowLiteSensors" css-icon="blocklyToolboxLiteSensors">
  <block type="sensor_button_lite">
    <field name="PIN">7</field>
  </block>
  <block type="sensor_button_debounced_lite">
    <field name="PIN">7</field>
  </block>
  <block type="sensor_tilt_lite">
    <field name="PIN">7</field>
  </block>
  <block type="sensor_potentiometer_lite"></block>
  <block type="sensor_TEMT6000_lite"></block>
</category>
<category name="%{BKY_CAT_TIME}" css-row="blocklyTreeRowTime" css-icon="blocklyToolboxLiteTime">
  <block type="time_delay_seconds">
    <value name="DELAY_TIME_SECONDS">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="time_seconds"></block>
  <block type="time_runeverys">
    <value name="S">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="infinite_loop"></block>
  <block type="time_timer_lite"></block>
  <block type="time_timer_reset"></block>
</category>
<category name="%{BKY_CAT_SERIAL_PORT}" css-row="blocklyTreeRowSerialPort" css-icon="blocklyToolboxLiteSerialPort">
  <block type="serial_init">
    <field name="BAUD">9600</field>
  </block>
  <block type="serial_print">
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="serial_read_available"></block>
  <block type="serial_read_string"></block>
  <block type="serial_read_float"></block>
  <block type="serial_read_byte"></block>
  <block type="serial_write_byte">
    <value name="BYTE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="serial_plot"></block>
  <block type="serial_interrupt"></block>
  <block type="serial_timeout">
    <value name="MS">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
</category>
<category name="%{BKY_CAT_ACTUATORS}" css-row="blocklyTreeRowActuators" css-icon="blocklyToolboxLiteActuators">
  <block type="actuator_led_lite">
    <field name="PIN">7</field>
  </block>
  <block type="actuator_led_pwm_lite">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">128</field>
      </shadow>
    </value>
  </block>
  <block type="actuator_led_rgb_color_picker_lite">
    <field name="PIN_R">9</field>
    <field name="PIN_G">10</field>
    <field name="PIN_B">11</field>
    <field name="TYPE">CC</field>
    <value name="COLOUR">
      <block type="colour_picker">
        <field name="COLOUR">#ff0000</field>
      </block>
    </value>
  </block>
  <block type="actuator_led_rgb_lite">
    <field name="PIN_R">9</field>
    <field name="PIN_G">10</field>
    <field name="PIN_B">11</field>
    <field name="TYPE">CC</field>
    <value name="R">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="G">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="B">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="actuator_buzzer_tone_lite">
    <field name="PIN">7</field>
    <field name="S">1</field>
  </block>
  <block type="actuator_buzzer_rttl_melody_lite">
    <field name="PIN">2</field>
    <value name="RTTTL">
      <shadow type="text">
        <field name="TEXT">song:</field>
      </shadow>
    </value>
  </block>
</category>
<category name="%{BKY_CAT_MOTOR}" css-row="blocklyTreeRowMotor" css-icon="blocklyToolboxLiteMotor">
  <block type="motor_servo_move_lite">
    <field name="PIN">D2</field>
    <value name="DEGREE">
      <shadow type="math_angle_180">
        <field name="ANGLE">0</field>
      </shadow>
    </value>
  </block>
  <block type="motor_servo_oscillator_set_lite">
    <field name="PIN">D2</field>
    <value name="VALUE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="motor_servo_oscillator_action_lite">
    <field name="PIN">D2</field>
  </block>
</category>
<category name="%{BKY_CAT_IO}" css-row="blocklyTreeRowInOut" css-icon="blocklyToolboxLiteInOut">
  <block type="io_digital_read">
    <field name="PIN">2</field>
  </block>
  <block type="io_digital_write">
    <field name="PIN">2</field>
  </block>
  <block type="io_analog_write">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="io_analog_read"></block>
</category>
</xml>`;
