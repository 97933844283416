<template>
  <div>
    <ripple-button :disabled="!currentDevice" :class="{'connect': !currentDevice}" :on="toggleSerialShelf">
      <serial-svg :fill="$vuetify.theme.currentTheme[`${currentDevice ? 'yellow' : 'off'}`]" class="serial" height="36px" />
      <serial-small-svg
        :fill="$vuetify.theme.currentTheme[`${currentDevice ? 'yellow' : 'off'}`]"
        class="small-serial"
        height="36px"
      />
      <div class="custom-text d-flex align-center">
        <v-icon class="pl-1 pr-2" color="lightText" dense size="24">mdi-greater-than-or-equal</v-icon>
        <span class="lightText--text pr-1 button-text">
          {{ $t("commons.serial") }}
        </span>
      </div>
    </ripple-button>
  </div>
</template>

<script>
import SerialSvg from '@public/img/icons/serial.svg';
import SerialSmallSvg from '@public/img/icons/serial-small.svg';
import { mapMutations } from 'vuex';
import RippleButton from './ripple-button.vue';

export default {
  components: {
    SerialSvg,
    SerialSmallSvg,
    RippleButton,
  },
  props: ['currentDevice'],
  methods: {
    ...mapMutations(['toggleSerialShelf', 'setSerialTab']),
  },
};
</script>

<style scoped>
button {
  position: relative;
  margin-right: -8px;
}
button:not(:disabled):hover {
  filter: brightness(0.9);
}
button.connect {
  margin-left: 2em;
}
.custom-text {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .serial, .button-text {
    display: none;
  }
  .custom-text {
    padding-left: 8px;
  }
}
@media (min-width: 1000px) {
  .small-serial {
    display: none;
  }
}

</style>
