<template>
<div class="cdialog-card px-4">
  <div class="cdialog-card-header darkWhite py-2">
    <div class="cdialog-card-header-left d-flex align-center ">
      <header-icon class="cdialog-card-header-icon" height="25"/>
      <div class="text-sm-body-2 font-weight-bold ml-4 normalText--text">{{ $t('settings.title') }}</div>
    </div>
    <div class="cdialog-card-header-right">
      <v-btn icon class="cdialog-card-header-close" @click="emitResponse('close');">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
  <div class="cdialog-card-body py-2">
    <setting-section scs-title="settings.interface.title">
      <template>
        <setting-interface :scs-interface="interfaceMode" @update:interface="updateInterfaceMode" />
      </template>
    </setting-section>
    <setting-section scs-title="settings.sound.title">
      <template>
        <setting-sound :scs-sound="sound" @update:sound="updateSound" />
      </template>
    </setting-section>
    <setting-section scs-title="settings.color.title">
      <template>
        <setting-color :scs-color="colorSpace" @update:color="updateColorSpace" />
      </template>
    </setting-section>
    <setting-section scs-title="settings.drivers.title">
      <template>
        <setting-drivers :dsc-disable-close="true" />
      </template>
    </setting-section>
  </div>
  <div class="cdialog-card-footer"></div>
</div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import HeaderIcon from '@public/img/menu/settings.svg';
import SettingSection from './settings-card-section.vue';
import SettingInterface from './settings-card-interface.vue';
import SettingSound from './settings-card-sound.vue';
import SettingColor from './settings-card-color.vue';
import SettingDrivers from './drivers-selection-card.vue';

export default {
  components: {
    HeaderIcon,
    SettingSection,
    SettingInterface,
    SettingSound,
    SettingColor,
    SettingDrivers,
  },
  methods: {
    ...mapMutations(['setInterfaceMode', 'setSound', 'setColorSpace']),
    updateBoard(value) {
      // eslint-disable-next-line no-console
      // console.log('updateBorad', value);
      this.$store.commit('setCurrentBoard', value.uuid);
      if (value.config) {
        // eslint-disable-next-line camelcase
        const config_options = this.currentBoard.config_options.map((con) => (
          con.option !== value.config.option ? { ...con } : {
            ...con,
            values: con.values.map((val) => ({ ...val, selected: val.value === value.config.value })),
          }));
        const config = { [value.config.option]: value.config.value };
        (new this.$FeathersVuex.api.Board({
          ...this.currentBoard,
          config_options,
          config,
        })).save();
      }
    },
    updateInterfaceMode(value) {
      this.setInterfaceMode(value);
      this.$vuetify.theme.dark = /(dark)|(black)/.test(value ?? '');
    },
    updateSound(value) {
      this.setSound(value);
    },
    updateColorSpace(value) {
      this.setColorSpace(value);
    },
    emitResponse(response) {
      this.$emit('response', response);
    },
  },
  computed: {
    ...mapGetters(['interfaceMode', 'sound', 'colorSpace']),
    currentBoard() {
      const { Board } = this.$FeathersVuex.api;
      return Board.findInStore({ query: { uuid: this.$store.getters.currentBoard } }).data[0] || {};
    },
  },
};
</script>
<style scoped>
.cdialog-card {
  font-family: "Lemur";
  border-radius: 25px 0;
}
.cdialog-card-header {
  display: flex;
  justify-content: space-between;
  margin: 0 -16px 16px -16px;
}
.cdialog-card-header-left {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  padding-left: 16px;
}
.cdialog-card-header-right {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-right: 12px;
}
.cdialog-card-header-close {
  height: 25px;
  width: 25px;
}
.cdialog-card-body {
  overflow-y: auto;
  height: 60vh;
}
.cdialog-card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.cdialog-card-section-title {
  color: var(--v-normalText-base);
  font-weight: bolder;
  margin-bottom: 40px;
}
.cdialog-card-actions {
  justify-content: flex-end;
}
.cdialog-card-actions {
  justify-content: flex-end;
}
.cdialog-card-actions .cdialog-card-action {
  border-radius: 5%;
  width: 150px;
  padding: 5px;
  margin-left: 20px;
}
.cdialog-card-save {
  background-color: var(--v-green-base);
  color: var(--v-lightText-base);
}
.cdialog-card-exit {
  background-color: var(--v-off-base);
  color: var(--v-normalText-base);
}
.cdialog-card-footer {
  height: 25px;
}
</style>
