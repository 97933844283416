<template>
  <v-app>
    <app-header></app-header>
    <blockly />
    <app-footer />
    <serial-modal />
    <serial-prompts />
  </v-app>
</template>

<script>
import Blockly from './Blockly.vue';

import AppFooter from '../components/footer/footer.vue';
import AppHeader from '../components/header/header.vue';
import SerialModal from '../components/serial/modal.vue';
import SerialPrompts from '../components/serial/prompts.vue';

export default {
  name: 'BlocklyView',
  components: {
    AppFooter,
    AppHeader,
    Blockly,
    SerialModal,
    SerialPrompts,
  },
  created() {
    // This text is not shown in alert, it just has to be not empty
    window.onbeforeunload = () => 'You have attempted to leave this page. Are you sure?';
  },
};
</script>
