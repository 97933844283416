<template>
  <div class="ml-12 pr-8 pb-4">
    <div :id="id" class="terminal" :class="{'realTime': realTime}"/>
  </div>
</template>

<script>
import 'xterm/css/xterm.css';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { mapGetters } from 'vuex';

export default {
  props: ['realTime', 'paused'],
  data() {
    return {
      id: `xterm-${Math.random()}`,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.dispose();
  },
  computed: {
    ...mapGetters(['serialShelf']),
  },
  methods: {
    init() {
      const term = new Terminal({
        disableStdin: true,
        ...(this.$attrs || {}),
        theme: {
          background: this.$vuetify.theme.currentTheme.background,
          foreground: this.$vuetify.theme.currentTheme.normalText,
          cursor: this.$vuetify.theme.currentTheme.normalText,
        },
      });
      this.fitAddon = new FitAddon();
      term.loadAddon(this.fitAddon);
      term.open(document.getElementById(this.id));
      this.$terminal = term;
      // necessary because transition from v-dialog, if transition is deactivated this could be deleted
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        this.fit();
      }, 500);
      [this.xtermViewport] = document.getElementsByClassName('xterm-viewport');
      this.xtermViewport.addEventListener('scroll', this.disableScroll);
      window.addEventListener('resize', this.fit);
    },
    write(val = '') {
      if (typeof val !== 'string' || this.paused) return;
      this.$terminal.write(val.replace(/\r\n/g, '\n').replace(/\n/g, '\r\n'));
    },
    clear() {
      this.$terminal.write('\r\n');
      this.$terminal.clear();
    },
    focus() {
      this.$terminal.focus();
    },
    blur() {
      this.$terminal.blur();
    },
    fit() {
      this.fitAddon.fit();
    },
    cols() {
      return this.$terminal.cols;
    },
    scrollToBottom() {
      this.$terminal.scrollToBottom();
    },
    disableScroll() {
      if (this.realTime) {
        this.scrollToBottom();
      }
    },
    dispose() {
      this.$terminal.dispose();
      this.xtermViewport.removeEventListener('scroll', this.disableScroll);
      window.removeEventListener('resize', this.fit);
    },
  },
  watch: {
    realTime(val) {
      if (val) {
        this.scrollToBottom();
      }
    },
    serialShelf(val) {
      if (val) {
        this.init();
      } else {
        this.dispose();
      }
    },
  },
};
</script>

<style>
.terminal {
  overflow: hidden;
  height: calc(50vh - 66px);
}
.terminal.realTime, .terminal.realTime .xterm-viewport {
  overflow-y: hidden;
}
</style>
