<template>
  <div class="scs-container">
    <div class="scs-label">
      <silent-icon class="scs-icon"/>
      <div class="scs-txt">{{ $t('settings.sound.silent') }}</div>
    </div>
    <v-switch v-model="soundValue" inset class="scs-switch" />
    <div class="scs-label">
      <div class="scs-txt">{{ $t('settings.sound.sound') }}</div>
      <sound-icon class="scs-icon"/>
    </div>
  </div>
</template>
<script>
import SilentIcon from '@public/img/icons/settings/silent_v0.svg';
import SoundIcon from '@public/img/icons/settings/sound_v0.svg';

const SOUND_VALUES = {
  scsOn: 'sound',
  scsOff: 'silent',
};

export default {
  components: {
    SilentIcon,
    SoundIcon,
  },
  props: {
    scsSound: String,
  },
  data() {
    return {
      soundValue: this.scsSound === SOUND_VALUES.scsOn,
    };
  },
  watch: {
    soundValue(newValue) {
      this.$emit('update:sound', newValue ? SOUND_VALUES.scsOn : SOUND_VALUES.scsOff);
    },
  },
};
</script>
<style scoped>
.scs-container {
  display: flex;
  justify-content: space-between;
  width: 350px;
}
.scs-label {
  display: flex;
  align-items: center;
}
.scs-label, .scs-icon {
  height: 30px;
}
.scs-label .scs-txt {
  margin: 0px 4px;
}
.scs-switch {
  height: 50px;
  margin: 0px 20px;
}
</style>
