<template>
  <v-flex class="d-flex mt-4">
    <div class="orange lightText--text bullet-point d-flex justify-center align-center mr-2 flex-shrink-0">{{ n }}</div>
    <div>
      <p class="text-subtitle-1-bold">{{ title }}</p>
      <div v-for="(item, index) in text" :key="index">
        <p class="text-body-2">{{ item }}</p>
      </div>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: ['n', 'title', 'text'],
};
</script>

<style scoped>
.bullet-point {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
</style>
