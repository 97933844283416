<template>
  <v-container class="error-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 990.62 357.6">
      <rect x="564.09" y="38.84" width="241.25" height="111.92" rx="20.15" style="fill:none;stroke:#ccc;stroke-miterlimit:10" />
      <rect x="571.72" y="46.46" width="226" height="96.67" rx="10.15" style="fill:none;stroke:#ccc;stroke-miterlimit:10" />
      <rect x="681.74" y="150.75" width="12.27" height="106.07" style="fill:none;stroke:#ccc;stroke-miterlimit:10" />
      <ellipse cx="682.61" cy="268.2" rx="76.12" ry="6.28" style="fill:#ccc" />
      <rect x="687.41" y="252.51" width="27.73" height="10.98" rx="1.79" transform="translate(-37.38 145.12) rotate(-11.51)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:9.999999768495192;stroke-width:0.7499999826371394px" />
      <path d="M691.22,265.69l.09.05h0l1.87,1.17a1.13,1.13,0,0,0,1.44-.29l1.26-1.81h0l.06-.09Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <ellipse cx="776" cy="269.64" rx="14.1" ry="1.16" style="fill:#ccc" />
      <path d="M500.25,20.28V72.64A19.28,19.28,0,0,1,481,91.92H468.57c-8.19,34.35-37.23,47.72-37.23,47.72,15.83-16.51,19-36.89,19.44-47.72H230.83a19.28,19.28,0,0,1-19.28-19.28V20.28A19.28,19.28,0,0,1,230.83,1H481A19.28,19.28,0,0,1,500.25,20.28Z" transform="translate(0 0)" style="fill:#fff;stroke:#e5e5e5;stroke-miterlimit:10;stroke-width:2px" />
      <path d="M655.34,91.57v9.11h9.19v8.06h-9.19v10.59h-9.11V108.74h-20.4V103l16.68-32.67h9.54l-14.93,30.35h9.11V91.57Z" transform="translate(0 0)" style="fill:#ccc" />
      <path d="M699,113.34a15.74,15.74,0,0,1-25.76,0q-4.77-6.7-4.77-18.55t4.77-18.54a15.76,15.76,0,0,1,25.76,0q4.72,6.66,4.73,18.57T699,113.34ZM677.84,94.79q0,16.49,8.28,16.48t8.27-16.48q0-16.47-8.27-16.47T677.84,94.79Z" transform="translate(0 0)" style="fill:#ccc" />
      <path d="M737.43,91.57v9.11h9.18v8.06h-9.18v10.59h-9.12V108.74h-20.4V103L724.6,70.33h9.53L719.2,100.68h9.11V91.57Z" transform="translate(0 0)" style="fill:#ccc" />
      <line y1="252.73" x2="990.62" y2="252.73" style="fill:none;stroke:#ccc;stroke-miterlimit:10" />
      <path d="M405.19,215.59v31.08a6.93,6.93,0,0,0,6.95,6.91,6.84,6.84,0,0,0,3.42-.91l8.65-5a4.75,4.75,0,0,1,7.13,4.11v45.31a1.79,1.79,0,0,1-1.79,1.79H361.92l-.31.3-.46.43L340,319.72c-5,4.72-14.23,4.72-19.25,0L299.7,299.63l-.46-.43-.31-.3H233.47a1.79,1.79,0,0,1-1.79-1.79h0v-132a1.79,1.79,0,0,1,1.79-1.79h35.67l1,1,.47.43,21.07,20.09c5,4.72,14.28,4.72,19.25,0L332,164.7l.47-.43,1-1h96.08a1.79,1.79,0,0,1,1.79,1.79h0v45.35a4.75,4.75,0,0,1-7.13,4.11l-8.65-5a6.88,6.88,0,0,0-9.42,2.44A6.8,6.8,0,0,0,405.19,215.59Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:2px" />
      <ellipse cx="331.52" cy="349.37" rx="99.83" ry="8.23" style="fill:#ccc" />
      <path d="M327.44,277.44a18.3,18.3,0,1,0-36.6,0" transform="translate(0 0)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:6px" />
      <path d="M330.76,240.67a10.26,10.26,0,0,0,20.51,0" transform="translate(0 0)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:6px" />
      <path d="M267,240.67a10.26,10.26,0,0,0,20.51,0" transform="translate(0 0)" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:6px" />
      <path d="M236.71,277.44s-17.15,0-15.4,46.63" transform="translate(0 0)" style="fill:none;stroke:#e5e5e5;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px" />
      <path d="M425.16,277.44s17.15,0,15.4,46.63" transform="translate(0 0)" style="fill:none;stroke:#e5e5e5;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px" />
      <circle cx="221.19" cy="325.47" r="4.98" style="fill:#e5e5e5" />
      <circle cx="440.68" cy="325.47" r="4.98" style="fill:#e5e5e5" />
      <path d="M746.86,255.81,745,266.64a1.36,1.36,0,0,1-1.57,1.11h0l-23.69-4-.1.07h-.06l-2.28,1.54a1.42,1.42,0,0,1-1.8-.3l-1.66-2.22h0l-.08-.11-1.73-.3a1.36,1.36,0,0,1-1.12-1.56h0L712.73,250a1.36,1.36,0,0,1,1.56-1.12h0l31.44,5.33a1.36,1.36,0,0,1,1.12,1.58Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <rect x="695.61" y="202.91" width="7.55" height="19.07" rx="1.79" transform="translate(372.94 866.43) rotate(-80.37)" style="fill:#e5e5e5" />
      <path d="M691,214.92V215h0l.91,1.22a.8.8,0,0,0,1,.17l1.26-.85h.06Z" transform="translate(0 0)" style="fill:#e5e5e5" />
      <path d="M692.05,249.79v5.89a1.33,1.33,0,0,0,1.31,1.33,1.4,1.4,0,0,0,.69-.19l1.64-.95a.9.9,0,0,1,1.35.78v7.56a1.36,1.36,0,0,1-1.35,1.37H633.51a1.36,1.36,0,0,1-1.35-1.37h0v-23a1.37,1.37,0,0,1,1.35-1.37h62.13a1.38,1.38,0,0,1,1.36,1.37v7.57a.9.9,0,0,1-1.35.78l-1.64-.95a1.29,1.29,0,0,0-1.78.46A1.31,1.31,0,0,0,692.05,249.79Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <path d="M684.7,237.18,683,248a1.36,1.36,0,0,1-1.56,1.13h0l-31.5-5a1.37,1.37,0,0,1-1.13-1.56l.55-3.44a.46.46,0,0,0-.59-.27l-.88.34a.65.65,0,0,1-.84-.36.64.64,0,0,1,0-.36l.46-2.9a.66.66,0,0,1,.76-.54.67.67,0,0,1,.3.13l.74.59a.43.43,0,0,0,.62-.06h0l.63-4a1.37,1.37,0,0,1,1.56-1.13l31.5,5a1.37,1.37,0,0,1,1.12,1.58Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <path d="M685.4,164.28l2.19,3a.45.45,0,0,1-.09.63h0l-8.77,6.37a.45.45,0,0,1-.64-.11l-.7-1a.13.13,0,0,0-.19.05v0l-.13.3a.23.23,0,0,1-.32.07.23.23,0,0,1-.07-.07l-.59-.81a.23.23,0,0,1,.06-.32.18.18,0,0,1,.1,0h.32a.16.16,0,0,0,.14-.17l-.81-1.1a.47.47,0,0,1,.11-.65l8.76-6.36a.46.46,0,0,1,.64.12Z" transform="translate(0 0)" style="fill:#e5e5e5" />
      <path d="M667.47,257.92l1.58,7.6a1,1,0,0,1-.74,1.14l-22.06,4.59a1,1,0,0,1-1.15-.75l-.5-2.41a.33.33,0,0,0-.45,0l-.5.44a.46.46,0,0,1-.76-.25l-.42-2a.47.47,0,0,1,.35-.55.52.52,0,0,1,.25,0l.63.2a.32.32,0,0,0,.4-.19h0l-.58-2.78a1,1,0,0,1,.75-1.14l22.06-4.6A1,1,0,0,1,667.47,257.92Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <path d="M785.07,263.54v5.38a.69.69,0,0,1-.67.68H768.75a.67.67,0,0,1-.67-.67v-1.71a.22.22,0,0,0-.3-.09h0l-.4.24a.32.32,0,0,1-.44-.12.29.29,0,0,1,0-.16v-1.45a.32.32,0,0,1,.48-.28l.4.23a.22.22,0,0,0,.29-.06v-2a.68.68,0,0,1,.66-.68h15.65a.67.67,0,0,1,.69.65Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <path d="M703.83,263.08v5.39a.67.67,0,0,1-.65.67h0l-15.65.05a.67.67,0,0,1-.67-.67v-1.71a.23.23,0,0,0-.31-.08l-.4.23a.32.32,0,0,1-.44-.12.29.29,0,0,1,0-.16v-1.44a.32.32,0,0,1,.48-.28l.4.23a.22.22,0,0,0,.3-.09h0v-2a.67.67,0,0,1,.65-.67h15.66A.66.66,0,0,1,703.83,263.08Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <path d="M738.86,236.52l1.77,5.09a.67.67,0,0,1-.41.85l-14.77,5.15a.67.67,0,0,1-.86-.4h0l-.56-1.61a.21.21,0,0,0-.3,0h0l-.3.35a.31.31,0,0,1-.45,0,.24.24,0,0,1-.1-.14l-.48-1.37a.32.32,0,0,1,.19-.41.37.37,0,0,1,.18,0l.45.09a.23.23,0,0,0,.26-.18l-.65-1.86a.68.68,0,0,1,.41-.86L738,236.11A.68.68,0,0,1,738.86,236.52Z" transform="translate(0 0)" style="fill:#e5e5e5;stroke:#fff;stroke-miterlimit:10;stroke-width:0.75px" />
      <path d="M646,174.58c-2.92,0-5.84-.07-8.76,0-2,.06-2.73-.73-2.7-2.69.07-4.44.06-8.89,0-13.33,0-2,.82-2.69,2.74-2.67,5.85.07,11.69.06,17.53,0,2,0,2.73.75,2.69,2.7-.07,4.45-.09,8.89,0,13.34,0,2.06-.89,2.66-2.74,2.62-2.92-.06-5.85,0-8.77,0Z" transform="translate(0 0)" style="fill:#fff" />
      <g style="isolation:isolate" transform="translate(231.68 43.06)">
        <text fill="#cccccc" font-size="24">{{$t("errors.404")}}</text>
      </g>
      <g style="isolation:isolate" transform="translate(231.68 64.66)">
        <text fill="#cccccc" font-size="18">{{$t("errors.something")}}</text>
      </g>
    </svg>
  </v-container>
</template>
<script>
export default {
};
</script>
<style scoped>
.error-container {
  height: 100vh;
  display: grid;
  align-items: center;
  position: relative;
}
.text-error {
  position: absolute;
  left: 25vw;
  top: 16vh;
}
.text-error-1 {
  position: absolute;
  left: 25vw;
  top: 22vh;
}
.svg-error {
  position: relative;
  min-height: calc(100vh - 500px);
}

</style>
