import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en.json';
import es from './lang/es.json';
import ca from './lang/ca.json';
import eu from './lang/eu.json';
import { getUserLang } from './store';

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
  locale: getUserLang(),
  fallbackLocale: 'es',
  messages: {
    en, es, ca, eu,
  },
});
