<template>
  <div class="d-flex justify-center flex-column px-16">
    <span class="normalText--text text-sm-h6 text-center">{{$t('compile.title.error-compiling')}}</span>
    <div class="d-flex mt-6">
      <wise-icon width="84" class="flex-shrink-0"/>
      <div class="d-flex flex-column flex-grow-1 ml-8 pannel-container">
        <span class="pa-1 mx-6 yellow lightText--text text-sm-body-1 font-weight-bold text-center tip-text">
          {{$t('compile.title.error-compiling-tip')}}
        </span>
        <div class="vertical-bar left" />
        <div class="vertical-bar right" />
        <div class="error-text mt-4">
          <log-pannel :log="log" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WiseIcon from '@public/img/icons/iconos_svg_v1_blockly_wise.svg';
import LogPannel from '../components/log-pannel.vue';

export default {
  props: {
    log: {
      default: '',
    },
  },
  components: {
    WiseIcon,
    LogPannel,
  },
};
</script>

<style scoped>
.pannel-container {
  position: relative;
}
.vertical-bar {
  position: absolute;
  height: 26px;
  width: 6px;
  top: 31px;
  border-radius: 0 0 5px 5px;
  background: var(--v-yellow-darken1);
}
.vertical-bar.left {
  left: 50px;
}
.vertical-bar.right {
  right: 50px;
}
.tip-text {
  border-radius: 6px;
}
.error-text {
  border-radius: 15px;
  border: 20px solid var(--v-yellow-base);
  background: var(--v-darkBackground-base);
}
</style>
