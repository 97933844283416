<template>
  <v-dialog
    max-width="600"
    overlay-color="background"
    v-model="dialog"
    content-class="modal"
    style="z-index: 1002;"
  >
    <template v-slot:activator="{ on }">
      <div class="new-button" v-on="on">
        <button class="ma-4 pl-2 d-flex align-center">
          <v-row align-content="center">
            <new-icon width="16"/>
            <div class="text-body-2 ml-2 white--text d-flex align-center">{{ $t('new-project.new') }}</div>
          </v-row>
        </button>
      </div>
    </template>
    <template>
      <tools-header :text="$t('new-project.new')" class="text-sm-body-2" @closed="closeDialog">
        <new-icon height="25" />
      </tools-header>
      <p class="pt-2 darkText--text text-center font-weight-bold">{{$t('new-project.create') }}</p>
      <v-flex class="d-flex justify-center">
        <new-project-icon height="128" />
      </v-flex>
      <div class="px-8 py-4 normalText--text text-center text-sm-body-2">{{$t('new-project.changes-lost') }}</div>
      <v-card-actions class="pb-6 justify-center">
        <v-btn
          class="lightText--text green mr-2 px-4"
          style="text-transform: none;"
          text
          @click="confirm"
        >{{$t('commons.confirm') }}</v-btn>
        <v-btn
          class="normalText--text lightGrey ml-2 px-4"
          style="text-transform: none;"
          text
          @click="closeDialog"
        >{{$t('commons.cancel') }}</v-btn>
      </v-card-actions>
    </template>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import NewIcon from '@public/img/menu/new.svg';
import NewProjectIcon from '@public/img/icons/iconos_svg_new_project.svg';
import ToolsHeader from '../tools/header.vue';

export default {
  components: {
    NewIcon,
    NewProjectIcon,
    ToolsHeader,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    ...mapMutations(['increaseProjectVersion', 'setProjectName']),
    closeDialog() {
      this.dialog = false;
      this.$emit('closed');
    },
    confirm() {
      this.increaseProjectVersion();
      this.setProjectName('');
      this.closeDialog();
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style>
.new-button {
  cursor: pointer;
}
.new-button:hover {
  background: var(--v-toolbox1-lighten1);
}
.modal {
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-greyShadow-base);
}
</style>
