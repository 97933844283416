<template>
  <div>
    <h1>{{ $t('documentation.text.title') }}</h1>
    <doc-card :content="$t('documentation.text.text')" />
  </div>
</template>

<script>
import DocCard from '../cards/doc-card.vue';

export default {
  components: {
    DocCard,
  },
};
</script>

<style></style>
