<template>
  <div class="pa-2">
    <div class="log-pannel-container">
      <v-card elevation="2" class="log-pannel pa-2" v-html="logMessage">
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    log: {
      type: String,
      default: '',
    },
  },
  computed: {
    logMessage() {
      return this.log.toString().replaceAll('\n', '<br/>');
    },
  },
};
</script>

<style scoped>
.log-pannel-container {
  max-height: 200px;
  overflow: auto;
}
.log-pannel-container::-webkit-scrollbar-thumb  {
  background: var(--v-grey-base);
  border-radius: 10px;
}
.log-pannel-container::-webkit-scrollbar-thumb:hover {
  background: var(--v-lightGrey-base);
}
.log-pannel-container::-webkit-scrollbar-track {
  background: var(--v-normalText-base);
  border-radius: 10px;
}
.log-pannel {
  background-color: var(--v-darkBackground-base);
  color: var(--v-lightText-base);
  font-family: monospace;
  font-size: 1rem;

}

</style>
