<template>
  <v-footer style="z-index: 100" class="px-12 footer-position">
    <v-row style="display: contents; line-height: 28px;">
      <small>{{ $t("footer.rights") }}</small>
      <legal-warning />
      <privacy-policy />
      <v-spacer/>
      <footer-status />
    </v-row>
  </v-footer>
</template>

<script>

import FooterStatus from './footer-status.vue';
import LegalWarning from './footer-legal-warning.vue';
import PrivacyPolicy from './footer-privacy-policy.vue';

export default {
  components: {
    FooterStatus,
    LegalWarning,
    PrivacyPolicy,
  },
};
</script>

<style>
.footer-position {
  position: relative;
}
.editor-modal {
  overflow-y: hidden;
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-greyShadow-base);
}
</style>
