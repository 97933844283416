<template>
  <div class="d-flex align-center toolbar">
    <menu-component />
    <code-component />
    <action-button type="undo"/>
    <action-button type="redo" class="ml-1"/>
    <v-spacer/>
    <connect-component v-if="serialReady" />
    <serial-component :current-device="currentDevice" />
    <upload-component
      :current-server="currentServer"
      :current-board="currentBoard"
      :current-device="currentDevice"
    />
    <header-toggle />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import MenuComponent from './buttons/menu.vue';
import CodeComponent from './buttons/code.vue';
import ActionButton from './buttons/action.vue';
import ConnectComponent from './status/connect/connect.vue';
import SerialComponent from './status/serial.vue';
import UploadComponent from './status/upload/upload.vue';
import HeaderToggle from './status/header-toggle.vue';

export default {
  components: {
    MenuComponent,
    CodeComponent,
    ConnectComponent,
    SerialComponent,
    UploadComponent,
    HeaderToggle,
    ActionButton,
  },
  data() {
    return {
      deviceCB: null,
      currentDevice: null,
      serialReady: false,

    };
  },
  computed: {
    ...mapGetters({ currentServerId: 'currentServer' }),
    currentServer() {
      const { Server } = this.$FeathersVuex.api;
      return Server.findInStore({ query: { uuid: this.currentServerId } }).data[0] || {};
    },
    currentBoard() {
      const { Board } = this.$FeathersVuex.api;
      return Board.findInStore({ query: { uuid: this.$store.getters.currentBoard } }).data[0];
    },
    currentProject() {
      /*
      const { Project } = this.$FeathersVuex.api;
      return Project.findInStore({ query: { uuid: this.$store.getters.currentProject } }).data[0];
      */
      return true;
    },
  },
  methods: {
    ...mapMutations(['toggleSerialShelf', 'setSerialTab']),
    checkSerialReady() {
      if (this.$serial) this.serialReady = true;
      else setTimeout(() => this.checkSerialReady(), 100);
    },
  },
  mounted() {
    this.checkSerialReady();
    setTimeout(() => {
      this.currentDevice = this.$serial.currentDevice || null;
      this.deviceCB = (value) => { this.currentDevice = value; };
      this.$serial.on('currentDevice', this.deviceCB);
    }, this.$serial ? 100 : 1500);
  },
  beforeDestroy() {
    if (this.deviceCB) this.$serial.off('currentDevice', this.deviceCB);
  },
};
</script>

<style>
.toolbar {
  height: 64px;
  border-bottom: 1px solid var(--v-grey-base);
  margin: 0 -50px;
  padding: 0 50px;
}
</style>
