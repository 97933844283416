<template>
  <div>
    <small id="privacy-policy" class="pa-2 ml-4">{{ $t("footer.privacy-policy") }}</small>
    <v-dialog
      max-width="80vw"
      overlay-color="background"
      v-model="dialog"
      content-class="editor-modal"
      style="z-index: 1002;"
      activator="#privacy-policy"
    >
      <template>
        <v-flex class="d-flex pt-1 pl-6 pr-2 align-center">
          <v-btn icon style="margin-left: auto" @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
        <v-container class="px-4">
          <v-main class="px-4 pb-4 legal-content">
            <p class="text-h6">{{$t("footer.privacy.title")}}</p>
            <div v-for="i in [1,2,3,4,5,6,7,8,9,10,11]" :key="i">
              <bullet-point :n="i" :title="$t(`footer.privacy.${i}.title`)" :text="$t(`footer.privacy.${i}.text`)" />
            </div>
            <ok-button class="d-flex justify-center align-center pa-8" @click="dialog=false" color="orange"/>
          </v-main>
        </v-container>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import BulletPoint from './footer-bullet-point.vue';
import OkButton from '../program/components/ok-button.vue';

export default {
  data: () => ({
    dialog: false,
  }),
  components: {
    BulletPoint,
    OkButton,
  },
};
</script>

<style scoped>
#privacy-policy {
  cursor: pointer;
  border-radius: 5px;
}
#privacy-policy:hover {
  background-color: var(--v-lightestGrey-base);
}
.legal-content {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
