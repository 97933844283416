var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"d-flex flex-column",staticStyle:{"height":"50vh"}},[_c('div',{staticClass:"flex-grow-1 my-6 mx-12",attrs:{"id":_vm.id}}),_c('v-flex',{staticClass:"d-flex flex-shrink-0 toolbox1 px-4 custom-footer align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"dark":"","icon":""},on:{"click":_vm.togglePlotterPaused}},on),[_c('v-icon',{attrs:{"color":"lightText"}},[_vm._v("mdi-"+_vm._s(_vm.plotterPaused ? 'play' : 'pause'))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.plotterPaused ? _vm.$t('serial.tooltips.play') : _vm.$t('serial.tooltips.pause'))+" "+_vm._s(_vm.$t('serial.tooltips.plotter'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disabled":!_vm.plotterPaused,"dark":"","icon":""},on:{"click":function($event){_vm.clearCB && _vm.clearCB()}}},on),[_c('v-icon',{attrs:{"color":"lightText"}},[_vm._v("mdi-eraser")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('serial.tooltips.clear')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4",attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.chartOpts.axisY.includeZero = !_vm.chartOpts.axisY.includeZero}}},on),[_c('v-icon',{attrs:{"color":"lightText"}},[_vm._v("mdi-"+_vm._s(_vm.chartOpts.axisY.includeZero ? 'arrow-up-down' : 'arrow-vertical-lock'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.chartOpts.axisY.includeZero ? _vm.$t('serial.tooltips.auto-scale') : _vm.$t('serial.tooltips.lock-y')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-8",staticStyle:{"margin-left":"auto"},on:{"click":_vm.downloadCSV}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-download")]),_vm._v(" CSV ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('serial.tooltips.csv')))])]),_c('v-row',{attrs:{"align":"right"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3","sm":"3","offset-md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('rate',{staticClass:"ml-4 mt-0",staticStyle:{"flex-grow":"0"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('serial.tooltips.speed')))])])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2","sm":"2"}},[_c('span',{staticClass:"lightestGrey--text"},[_vm._v(" "+_vm._s(_vm.$t('serial.baudrate')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }