<template>
  <v-menu offset-x rounded="lg" v-model="value" content-class="adi-menu" :style="{ 'z-index': 90 }">
    <template v-slot:activator="{ on }">
      <div class="tools-item d-flex">
        <button
          id="close"
          class="ma-4 pl-2 flex-grow-1"
          :class="[value ? 'rounded-t-lg' : 'rounded-lg','white--text','text-none', 'menu-button'] "
          v-on="on"
        >
          <v-row align-content="center">
            <tools-icon width="16"/>
            <div class="text-body-2 ml-2 white--text d-flex align-center">{{$t('toolbar.tools')}}</div>
            <v-icon right dark> mdi-chevron-right</v-icon>
          </v-row>
        </button>
      </div>
    </template>
    <led-editor v-if="currentBoardCustomName !== 'cirkids'" @clicked="this.closeMenu"/>
    <lcd-editor v-if="currentBoardCustomName !== 'cirkids'" @clicked="this.closeMenu" />
    <color-picker @clicked="this.closeMenu" />
  </v-menu>
</template>

<script>
import ToolsIcon from '@public/img/menu/tools.svg';
import { mapGetters } from 'vuex';
import LedEditor from '../tools/led-editor.vue';
import LcdEditor from '../tools/lcd-editor.vue';
import ColorPicker from '../tools/color-picker.vue';

export default {
  components: {
    ToolsIcon,
    LedEditor,
    LcdEditor,
    ColorPicker,
  },
  data() {
    return {
      value: false,
    };
  },
  methods: {
    open() {
      this.value = true;
    },
    closeMenu() {
      this.$emit('closed');
    },
  },
  computed: {
    ...mapGetters(['currentBoardCustomName']),
  },
};
</script>

<style scoped>

.menu-button{
  justify-content: start;
}

#close::before {
  background-color: transparent !important;
}

.adi-menu {
  display: flex;
  flex-direction: column;
  background: var(--v-toolbox1-base);
  transition: all 0s;
}

.tools-item:hover {
  background: var(--v-toolbox1-lighten1);
}

</style>
