<template>
  <v-dialog
    max-width="600"
    overlay-color="background"
    v-model="dialog"
    content-class="editor-modal"
    style="z-index: 1002;"
  >
    <template v-slot:activator="{ on }">
      <div class="tools-item pb-1">
        <button class="ml-2 my-2" v-on="on" @click="handleClick">
          <div class="d-flex align-center">
            <color-picker-icon width="16" />
            <div class="text-body-2 mx-2 white--text">{{$t("toolbar.color-picker")}}</div>
          </div>
        </button>
      </div>
    </template>
    <template>
      <tools-header :text="$t('toolbar.tools')" class="text-sm-body-2" @closed="closeDialog">
        <tool-icon height="25" />
      </tools-header>
      <p class="pt-4 darkText--text text-center font-weight-bold">{{$t("toolbar.color-picker")}}</p>
      <div class="d-flex justify-center px-4 pb-8">
        <v-color-picker
          class="ma-2"
          show-swatches
          hide-mode-switch
        ></v-color-picker>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import ColorPickerIcon from '@public/img/icons/iconos_svg_v1_selector_color.svg';
import ToolIcon from '@public/img/menu/tools.svg';
import ToolsHeader from './header.vue';

export default {
  components: {
    ColorPickerIcon,
    ToolIcon,
    ToolsHeader,
  },
  data: () => ({
    dialog: false,
    code: '',
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    handleClick() {
      this.dialog = true;
      this.$emit('clicked');
    },
  },
};
</script>

<style>

.tools-item:hover {
  background: var(--v-toolbox1-lighten1);
}
.v-color-picker__hue {
  margin-bottom: 0 !important;
}
.v-color-picker__alpha {
  display: none;
}
.v-color-picker__input:last-of-type {
  display: none;
}

</style>
