<template>
    <v-navigation-drawer
      id="editor-drawer"
      :value='$store.getters.codeShelf'
      mobile-breakpoint='9999999'
      class='elevation-0'
      right
      clipped
      app
      :width="navigation.width"
      style="z-index: 100;"
      overlay-color="background"
      @input = 'toggleCodeShelf($event)'
      ref="drawer"
      model="navigation.shown"
    >
      <MonacoEditor
        v-if="$store.getters.codeShelf"
        class="editor"
        v-model="codeData"
        language="javascript"
        :options="optionsMonaco"
        @editorDidMount="editorDidMount"
        ref="editor"
      />
    </v-navigation-drawer>
</template>
<script>

// vuex
import { mapGetters, mapMutations } from 'vuex';

// monaco editor
import MonacoEditor from 'vue-monaco';

export default {
  props: ['code'],
  components: {
    MonacoEditor,
  },
  data() {
    return {
      navigation: {
        shown: false,
        width: '35%',
        borderSize: 10,
      },
      codeData: JSON.parse(JSON.stringify(this.code)),
      optionsMonaco: {
        readOnly: true,
        scrollbar: {
          horizontal: 'visible',
          horizontalScrollbarSize: 16,
          verticalScrollbarSize: 16,
        },
      },
    };
  },
  mounted() {
    this.setBorderWidth();
    this.setEvents();
    new ResizeObserver(this.editorResizeListener).observe(document.getElementById('editor-drawer'));
  },
  methods: {
    ...mapMutations(['toggleCodeShelf']),
    setMonacoOptions() {
      const darkMode = /(dark)|(black)/.test(this.interfaceMode);
      if (this.$refs.editor) {
        const { monaco } = this.$refs.editor;
        monaco.editor.setTheme(darkMode ? 'vs-dark' : 'vs-light');
      }
    },
    editorDidMount(editor) {
      this.editor = editor;
      const { monaco } = this.$refs.editor;
      monaco.editor.defineTheme('vs-light', {
        base: 'vs',
        inherit: true,
        rules: [],
        colors: {
          'editor.background': this.$vuetify.theme.themes.light.background,
        },
      });
      this.setMonacoOptions();
    },
    editorResizeListener() {
      if (this.editor) {
        this.editor.layout();
      }
    },
    setBorderWidth() {
      const i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border',
      );
      i.style.width = `${this.navigation.borderSize}px`;
      i.style.cursor = 'ew-resize';
      i.style.background = 'transparent';
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector('.v-navigation-drawer__border');
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left';

      function resize(e) {
        document.body.style.cursor = 'ew-resize';
        const f = direction === 'right'
          ? document.body.scrollWidth - e.clientX
          : e.clientX;
        el.style.width = `${f}px`;
        e.stopPropagation();
        e.preventDefault();
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = 'initial';
            document.addEventListener('mousemove', resize, false);
          }
        },
        false,
      );

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = '';
          this.navigation.width = el.style.width;
          document.body.style.cursor = '';
          document.removeEventListener('mousemove', resize, false);
        },
        false,
      );
    },
  },
  computed: {
    ...mapGetters(['interfaceMode']),
  },
  watch: {
    interfaceMode() {
      this.setMonacoOptions();
    },
    code(val) {
      this.codeData = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>

<style>
#editor-drawer {
  border-left: 1px solid var(--v-lightGrey-base);
}
.editor {
  height: calc(100% - 100px);
  margin: 60px 0 0 20px;
}
.editor .slider{
  background: var(--v-lightestGrey-base);
  border-radius: 10px;
}
.editor .slider:hover {
   background: var(--v-grey-base);
 }
</style>
