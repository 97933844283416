<template>
  <div class="item-button" @click="item.action">
    <button class="ml-4 pa-2 my-2 d-flex align-center">
      <v-row align-content="center">
        <div :style="{ width: '16px', height: '100%' }">
          <component :is="item.icon"></component>
        </div>
        <div class="text-body-2 ml-2 white--text d-flex align-center">{{ $t(item.title) }}</div>
      </v-row>
    </button>
  </div>
</template>

<script>

export default {
  props: ['item'],
};

</script>

<style scoped>
.item-button {
  cursor: pointer;
}
.item-button:hover {
  background: var(--v-toolbox1-lighten1);
}

</style>
