<template>
  <v-radio-group v-model="colorValue" row disabled>
    <div class="scs-option">
      <v-radio color="#a7a9ac" value="turned-off" />
      <div class="scs-label">{{ $t('settings.color.turned-off') }}</div>
    </div>
    <div class="scs-option">
      <v-radio color="#a7a9ac" value="deutera-red-green" />
      <div class="scs-label">{{ $t('settings.color.deutera-red-green') }}</div>
    </div>
    <div class="scs-option">
      <v-radio color="#a7a9ac" value="prota-red-green" />
      <div class="scs-label">{{ $t('settings.color.prota-red-green') }}</div>
    </div>
    <div class="scs-option">
      <v-radio color="#a7a9ac" value="trita-blue-yellow" />
      <div class="scs-label">{{ $t('settings.color.trita-blue-yellow') }}</div>
    </div>
  </v-radio-group>
</template>
<script>

export default {
  props: {
    scsColor: String,
  },
  data() {
    return {
      colorValue: this.scsColor,
    };
  },
  watch: {
    colorValue(newValue) {
      this.$emit('update:color', newValue);
    },
  },
};
</script>
<style scoped>
.scs-option {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 15px;
}
.scs-option .scs-label{
  text-align: center;
}
.scs-option .v-radio {
  margin-right: -8px !important;
}
</style>
