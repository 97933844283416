<template>
<div class="cdialog-card px-4">
  <div class="cdialog-card-header darkWhite py-2">
    <div class="cdialog-card-header-left d-flex align-center ">
      <header-icon class="cdialog-card-header-icon" height="25"/>
      <div class="text-sm-body-2 font-weight-bold ml-4 normalText--text">{{ $t('select-card.title') }}</div>
    </div>
    <div class="cdialog-card-header-right">
      <v-btn icon class="cdialog-card-header-close" @click="emitResponse('closed');">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
  <div class="cdialog-card-body py-2">
    <select-card-board @response="emitResponse"/>
  </div>
  <div class="cdialog-card-footer"></div>
</div>
</template>
<script>
import HeaderIcon from '@public/img/menu/seleccion_placa_v0.svg';
import SelectCardBoard from './select-card-board.vue';

export default {
  components: {
    HeaderIcon,
    SelectCardBoard,
  },
  methods: {
    emitResponse(response) {
      this.$emit('response', response);
    },
  },
};
</script>
<style scoped>
.cdialog-card {
  font-family: "Lemur";
  border-radius: 25px 0;
}
.cdialog-card-header {
  display: flex;
  justify-content: space-between;
  margin: 0 -16px 16px -16px;
}
.cdialog-card-header-left {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  padding-left: 16px;
}
.cdialog-card-header-right {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-right: 12px;
}
.cdialog-card-header-close {
  height: 25px;
  width: 25px;
}
.cdialog-card-body {
  overflow-y: auto;
  height: 65vh;
}
.cdialog-card-footer {
  height: 25px;
}
</style>
