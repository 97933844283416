<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :max-width="maxWidth"
      overlay-color="background"
      content-class="round no-scroll"
      style="z-index: 1002;"
    >
      <template v-slot:activator="{ on }">
        <slot name="btn" :on="on"></slot>
      </template>
      <v-card>
        <slot name="card" :close='closeDialog' :open='openDialog'></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    activate: Boolean,
    maxWidth: {
      type: String,
      default: '290',
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  watch: {
    activate(newValue) {
      if (newValue) {
        this.openDialog();
      } else {
        this.closeDialog();
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit('response', false);
      }
    },
  },
};
</script>
<style>
  .no-scroll {
    overflow-y: hidden;
  }
  .round  {
    border-radius: 25px;
    border: 1px solid var(--v-lightGrey-base);
    box-shadow: 8px 8px 8px var(--v-greyShadow-base);
  }

</style>
