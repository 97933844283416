/* eslint-disable func-names */
<template>
  <div>
    <div id="trashMask" class="blocklyToolboxTrashMask"></div>
    <div id="trashConten" class="blocklyToolboxTrashConten"></div>
    <div class="blocklyDiv" ref="blocklyDiv"></div>
    <div ref="blocklyToolbox" style="display:none"></div>
    <screenshoot-dialog v-model="activeScreenshotDialog" @closed="closeMenu" @download="downloadScreenshot" />
<!--    <led-editor-dialog v-bind:active="activeMatrixLedDialog" @closed="closeMenu" />-->
<!--    <lcd-editor-dialog v-bind:active="activeLcdDialog" @closed="closeMenu" />-->
  </div>
</template>

<script>
import Blockly from 'blockly';
import './toolbox/toolboxStyles';
import './toolbox/core/toolbox';
import './flyout/flyoutStyles';
import './flyout/vertical_flyout';
import '../../plugins/backpack/backpack';
import { init } from '@academiadeinventores/helloblocks-blocks';
import { Backpack } from '@blockly/workspace-backpack';
import { DisableTopBlocks } from '@blockly/disable-top-blocks';
import { mapGetters, mapMutations } from 'vuex';
import { tiggerMatrixEditor } from '@academiadeinventores/helloblocks-blocks/src/blocks/block-matrix';
import { tiggerLcdEditor } from '@academiadeinventores/helloblocks-blocks/src/blocks/block-lcd';
import { registerContextMenu } from '../../plugins/contextmenu/contextmenu';
import { FullscreenControl } from '../../plugins/fullscreen/fullscreen';
import toolboxBlockFullStyles from './toolbox/toolboxStylesFullConf';
import toolboxBlockLiteStyles from './toolbox/toolboxStylesLiteConf';
import { FieldGridDropdown } from '../../plugins/field-grid-dropdown';
import ScreenshootDialog from '../header/buttons/screenshot.vue';
import { Screenshot, tiggerScreenshot } from '../../plugins/screenshot/screenshot';

init(Blockly, FieldGridDropdown);

export default {
  components: { ScreenshootDialog },
  name: 'BlocklyComponent',
  props: ['options', 'version'],
  data() {
    return {
      workspace: null,
      defaultTheme: null,
      screenshot: null,
      activeScreenshotDialog: false,
      activeMatrixLedDialog: false,
      activeLcdDialog: false,
    };
  },
  mounted() {
    // Inject Blockly
    this.injectBlockly();

    // Init check trigger
    this.checkTiggerWatch();

    // Initialize backpack plugin.
    // https://github.com/google/blockly-samples/tree/master/plugins/workspace-backpack
    const backpackOptions = {
      contextMenu: {
        emptyBackpack: true,
        removeFromBackpack: false,
        copyToBackpack: true,
        copyAllToBackpack: false,
        pasteAllToBackpack: false,
        disablePreconditionChecks: false,
      },
    };
    const backpack = new Backpack(this.workspace, backpackOptions);
    backpack.init();
    this.setBackpack(backpack);

    // Initialize workspace comments in context menu
    registerContextMenu(this.workspace);

    // Initialize screenshot plugin.
    this.screenshot = new Screenshot(this.workspace);
    this.screenshot.init();

    // Initialize screenshot plugin.
    const fullscreen = new FullscreenControl(this.workspace);
    fullscreen.init();

    // Add the disableOrphans event handler. This is not done automatically by
    // the plugin and should be handled by your application.
    this.workspace.addChangeListener(Blockly.Events.disableOrphans);

    // The plugin must be initialized before it has any effect.
    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();
    this.defaultTheme = this.workspace.themeManager_.getTheme();
  },
  computed: {
    ...mapGetters(['currentEditor']),
    blocklyOptions() {
      return this.options;
    },
  },
  methods: {
    ...mapMutations(['setBackpack']),
    injectBlockly() {
      const options = this.$props.options || {};
      if (!options.toolbox) {
        options.toolbox = this.$refs.blocklyToolbox;
      }
      options.theme = {
        blockStyles: this.getBlockStyles(),
      };
      this.workspace = Blockly.inject(this.$refs.blocklyDiv, options);
    },
    closeMenu() {
      this.screenshot.closeScreenshot();
    },
    downloadScreenshot(name) {
      this.screenshot.downloadScreenshot(this.workspace, name);
    },
    getBlockStyles() {
      const toolboxStyles = this.currentEditor === 'lite' ? toolboxBlockLiteStyles : toolboxBlockFullStyles;
      return toolboxStyles.reduce(
        (acc, { style, themeVariable }) => ({
          ...acc,
          [style]: { colourPrimary: this.$vuetify.theme.currentTheme[themeVariable] },
        }),
        {},
      );
    },
    checkTiggerWatch() {
      this.activeMatrixLedDialog = tiggerMatrixEditor;
      this.activeLcdDialog = tiggerLcdEditor;
      this.activeScreenshotDialog = tiggerScreenshot;
      setTimeout(() => this.checkTiggerWatch(), 200);
    },
  },
  watch: {
    blocklyOptions(value) {
      const themeManager = this.workspace.themeManager_;
      if (value && value.theme && themeManager.getTheme().name !== 'dark') {
        value.theme = {
          ...value.theme,
          blockStyles: this.getBlockStyles(),
        };
        themeManager.setTheme(value.theme);
      } else if (value && !value.theme && themeManager.getTheme().name === 'dark') {
        themeManager.setTheme(this.defaultTheme);
      }

      if (value && value.sounds) {
        this.workspace.getAudioManager()?.unmute();
      } else {
        this.workspace.getAudioManager()?.mute();
      }
    },
    currentEditor() {
      const themeManager = this.workspace.themeManager_;
      const theme = themeManager.getTheme();
      theme.blockStyles = this.getBlockStyles();
      themeManager.setTheme(theme);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.blocklyDiv {
  height: 100%;
  width: 100%;
  text-align: left;
}

.blocklyToolboxTrashConten {
    padding: 4px 0 4px 10px;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    width: 330px;
    height: 100%;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
}

.blocklyToolboxTrashMask {
    padding: 4px 0 4px 10px;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    width: 330px;
    height: 100%;
    opacity: 0;
}

</style>
