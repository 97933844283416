<template>
  <dialog-container max-width="400" :activate="activeDialog" @response="emitResponse">
    <template v-if="dEnableButton" v-slot:btn="{ on }">
      <v-btn v-on="on">{{ dButtonText }}</v-btn>
    </template>
    <template v-slot:card="slotProps">
      <board-not-selected-card @response="emitResponse" :slotProps="slotProps"/>
    </template>
  </dialog-container>
</template>

<script>
import DialogContainer from './dialog-container.vue';
import BoardNotSelectedCard from './cards/board-not-selected-card.vue';

export default {
  components: {
    DialogContainer,
    BoardNotSelectedCard,
  },
  props: {
    dButtonText: {
      type: String,
      default: 'Abrir Dialog',
    },
    dExtActivate: {
      type: Boolean,
      default: false,
    },
    dEnableButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeDialog() {
      return this.dExtActivate;
    },
  },
  methods: {
    emitResponse(value) {
      this.$emit('response', value);
    },
  },
};
</script>
