<template>
  <v-dialog max-width="600" overlay-color="background" v-model="dialog" content-class="modal" style="z-index: 1002;">
    <input ref="file" type="file" @change="changeFiles" hidden />
    <template v-slot:activator="{ on }">
      <div class="import-button" v-on="on">
        <button class="ma-4 pl-2 d-flex align-center">
          <v-row align-content="center">
            <import-icon width="16" />
            <div class="text-body-2 ml-2 white--text d-flex align-center">{{ $t('toolbar.import') }}</div>
          </v-row>
        </button>
      </div>
    </template>
    <template>
      <tools-header :text="$t('toolbar.import')" class="text-sm-body-2" @closed="closeDialog">
        <import-icon height="25" />
      </tools-header>
      <p class="pt-2 darkText--text text-center font-weight-bold">{{ $t('toolbar.import-project') }}</p>
      <v-flex class="d-flex justify-center">
        <new-project-icon height="128" />
      </v-flex>
      <div class="px-8 py-4 normalText--text text-center text-sm-body-2">{{ $t('new-project.changes-lost') }}</div>
      <v-card-actions class="pb-6 justify-center">
        <v-btn class="lightText--text green mr-2 px-4" style="text-transform: none;" text @click="confirm">{{
          $t('commons.confirm')
        }}</v-btn>
        <v-btn class="normalText--text lightGrey ml-2 px-4" style="text-transform: none;" text @click="closeDialog">{{
          $t('commons.cancel')
        }}</v-btn>
      </v-card-actions>
    </template>
  </v-dialog>
</template>

<script>
import ImportIcon from '@public/img/menu/import.svg';
import NewProjectIcon from '@public/img/icons/iconos_svg_new_project.svg';
import Blockly from 'blockly';
import { mapMutations } from 'vuex';
import ToolsHeader from '../tools/header.vue';

export default {
  components: {
    ImportIcon,
    NewProjectIcon,
    ToolsHeader,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    ...mapMutations(['setProjectName']),
    changeFiles({ target: { files } }) {
      try {
        const fr = new FileReader();

        fr.onload = ({ target: { result } }) => {
          this.setProjectName((files[0].name || 'Hello-Blocks').split('.hb')[0]);
          const xml = Blockly.Xml.textToDom(result);
          Blockly.Events.disable();
          Blockly.getMainWorkspace().clear();
          Blockly.Events.enable();
          Blockly.Xml.domToWorkspace(xml, Blockly.getMainWorkspace());
        };

        fr.readAsText(files[0]);
      } catch (error) {
        this.$notifier.error(error);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('closed');
    },
    confirm() {
      this.$refs.file.click();
      this.$emit('closed');
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style>
.import-button {
  cursor: pointer;
}
.import-button:hover {
  background: var(--v-toolbox1-lighten1);
}
.modal {
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-lightGrey-base);
}
</style>
