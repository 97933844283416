<template>
  <ripple-button :on="on">
    <connect-svg :fill="$vuetify.theme.currentTheme[`${initialized ? 'orange' : 'green'}`]" class="connect" height="36px" />
    <connect-small-svg
      :fill="$vuetify.theme.currentTheme[`${initialized ? 'orange' : 'green'}`]"
      class="small-connect"
      height="36px"
    />
    <div class="custom-text d-flex align-center">
      <v-icon dense class="pl-1 pr-2" color="lightText" size="24">mdi-usb mdi-rotate-90</v-icon>
      <span class="lightText--text pr-1 button-text">
        {{ $t(`commons.${initialized ? 'disconnect' : 'connect'}`) }}
      </span>
    </div>
  </ripple-button>
</template>

<script>
import ConnectSvg from '@public/img/icons/connect.svg';
import ConnectSmallSvg from '@public/img/icons/connect-small.svg';
import RippleButton from '../ripple-button.vue';

export default {
  props: ['on', 'initialized'],
  components: {
    RippleButton,
    ConnectSvg,
    ConnectSmallSvg,
  },
};
</script>

<style scoped>
  button {
    position: relative;
    margin-right: -10px;
  }
  button:hover {
    filter: brightness(0.9);
  }
  .custom-text {
    position: absolute;
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: 1000px) {
    .connect, .button-text {
      display: none;
    }
    .custom-text {
      padding-left: 8px;
    }
  }
  @media (min-width: 1000px) {
    .small-connect {
      display: none;
    }
  }
</style>
