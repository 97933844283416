import { render, staticRenderFns } from "./rock.vue?vue&type=template&id=4d136cfd&scoped=true&"
import script from "./rock.vue?vue&type=script&lang=js&"
export * from "./rock.vue?vue&type=script&lang=js&"
import style0 from "./rock.vue?vue&type=style&index=0&id=4d136cfd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d136cfd",
  null
  
)

export default component.exports