/* eslint-disable */
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Changes the serial_plot block to use a +/- mutator UI.
 */

import Blockly from 'blockly';
import {img_path} from "@academiadeinventores/helloblocks-blocks/src/generator/arduino";

import {createPlusField} from './field_plus';
import {createMinusField} from './field_minus';

// Delete original block because there's no way to unregister it:
// https://github.com/google/blockly-samples/issues/768#issuecomment-885663394
delete Blockly.Blocks['thingspeak_upload'];

/* eslint-disable quotes */
Blockly.defineBlocksWithJsonArray([
  {
    type: "thingspeak_upload",
    message0: "%{BKY_THINGSPEAK_UPLOAD_TITLE}", //"Wifi %1 Upload: %2 %3", //"%{BKY_SERIAL_PLOT_CREATE_TITLE}", // oJo
    args0: [
      {
        type: "field_image",
        src: img_path + "arduino/thingspeak_logo.svg", // oJo
        width: 100,
        height: 35,
        alt: "",
        flipRtl: false
      },
      {
        type: "input_dummy"
      },
      {
        type: "input_value",
        name: "EMPTY",
        check: "Number",
        align: "RIGHT"
      }
    ],
    previousStatement: null,
    nextStatement: null,
    style: "thingspeak_blocks",
    helpUrl: "",
    tooltip: "%{BKY_SERIAL_PLOT_CREATE_WITH_TOOLTIP}", //oJo
    mutator: "tsupload_create_with_mutator_number"
  }
]);
/* eslint-enable quotes */

const tsuploadCreateMutatorNumber = {
  /**
   * Number of item inputs the block has.
   * @type {number}
   */
  itemCount_: 0,

  /**
   * Max number of item inputs.
   * @type {number}
   */
   maxItems_: 8,

  /**
   * Creates XML to represent number of text inputs.
   * @return {!Element} XML storage element.
   * @this {Blockly.Block}
   */
  mutationToDom: function() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },
  /**
   * Parses XML to restore the text inputs.
   * @param {!Element} xmlElement XML storage element.
   * @this {Blockly.Block}
   */
  domToMutation: function(xmlElement) {
    const targetCount = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(targetCount);
  },

  /**
   * Adds inputs to the block until it reaches the target number of inputs.
   * @param {number} targetCount The target number of inputs for the block.
   * @this {Blockly.Block}
   * @private
   */
  updateShape_: function(targetCount) {
    while (this.itemCount_ < targetCount) {
      this.addPart_();
    }
    while (this.itemCount_ > targetCount) {
      this.removePart_();
    }
    this.updateMinus_();
    this.updatePlus_();
  },

  /**
   * Callback for the plus image. Adds an input to the end of the block and
   * updates the state of the minus.
   */
  plus: function() {
    if (this.itemCount_ == this.maxItems_) {
      return;
    }
    this.addPart_();
    this.updateMinus_();
    this.updatePlus_();
  },

  /**
   * Callback for the minus image. Removes an input from the end of the block
   * and updates the state of the minus.
   */
  minus: function() {
    if (this.itemCount_ == 0) {
      return;
    }
    this.removePart_();
    this.updateMinus_();
    this.updatePlus_();
  },

  // To properly keep track of indices we have to increment before/after adding
  // the inputs, and decrement the opposite.
  // Because we want our first input to be ADD0 (not ADD1) we increment after.

  /**
   * Adds an input to the end of the block. If the block currently has no
   * inputs it updates the top 'EMPTY' input to receive a block.
   * @this {Blockly.Block}
   * @private
   */
  addPart_: function() {
    if (this.itemCount_ == 0) {
      this.removeInput('EMPTY');
      this.topInput_ = this.appendValueInput('ADD' + this.itemCount_)
        .appendField(createPlusField(), 'PLUS')
        .appendField(Blockly.Msg.THINGSPEAK_UPLOAD_FIELD + (this.itemCount_ + 1)) //.appendField('field ' + (this.itemCount_ + 1)) //.appendField(Blockly.Msg.LISTS_CREATE_WITH_INPUT_NUMBER_WITH + this.itemCount_) //oJo
        .setCheck('Number')
        .setAlign(Blockly.ALIGN_RIGHT);
    } else {
      this.appendValueInput('ADD' + this.itemCount_)
        .appendField(Blockly.Msg.THINGSPEAK_UPLOAD_FIELD + (this.itemCount_ + 1)) //.appendField('field ' + (this.itemCount_ + 1)) //.appendField(Blockly.Msg.LISTS_CREATE_WITH_INPUT_NUMBER_WITH + this.itemCount_) //oJo
        .setCheck('Number')
        .setAlign(Blockly.ALIGN_RIGHT);
    }
    // TODO, utilizar esta funcion para eliminar la literal cuando el bloque esta inline
    // https://developers.google.com/blockly/reference/js/Blockly.BlockSvg#getInputsInline

    this.itemCount_++;
  },

  /**
   * Removes an input from the end of the block. If we are removing the last
   * input this updates the block to have an 'EMPTY' top input.
   * @this {Blockly.Block}
   * @private
   */
  removePart_: function() {
    this.itemCount_--;
    this.removeInput('ADD' + this.itemCount_);
    if (this.itemCount_ == 0) {
      this.topInput_ = this.appendDummyInput('EMPTY')
        .appendField(createPlusField(), 'PLUS')
        .appendField('add fields') //.appendField(Blockly.Msg['LISTS_CREATE_EMPTY_TITLE']) //oJo
        .setAlign(Blockly.ALIGN_RIGHT);
    }
  },

  /**
   * Makes it so the minus is visible iff there is an input available to remove.
   * @private
   */
  updateMinus_: function() {
    const minusField = this.getField('MINUS');
    if (!minusField && this.itemCount_ > 0) {
      this.topInput_.insertFieldAt(1, createMinusField(), 'MINUS');
    } else if (minusField && this.itemCount_ < 1) {
      this.topInput_.removeField('MINUS');
    }
  },

  /**
   * Makes it so the is is visible if there is an input available to add.
   * @private
   */
   updatePlus_: function() {
    const plusField = this.getField('PLUS');
    if (!plusField && this.itemCount_ < this.maxItems_) {
      this.topInput_.insertFieldAt(1, createPlusField(), 'PLUS');
    } if (plusField && this.itemCount_ == this.maxItems_) {
      this.topInput_.removeField('PLUS');
    }
  }
};

/**
 * Updates the shape of the block to have 3 inputs if no mutation is provided.
 * @this {Blockly.Block}
 */
const tsuploadCreateHelperNumber = function() {
  this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
  this.updateShape_(3);
};

Blockly.Extensions.registerMutator('tsupload_create_with_mutator_number',
    tsuploadCreateMutatorNumber, tsuploadCreateHelperNumber);
