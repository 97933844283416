<template>
  <div>
    <plugged-icon :paused="serialTab === 'monitor' ? monitorPaused : plotterPaused" />
    <v-dialog
      :value="$store.getters.serialShelf"
      overlay-color="background"
      content-class="monitor-modal"
      @input="toggleSerialShelf($event)"
    >
      <v-flex class="d-flex background justify-center">
        <v-tabs
          centered
          class="pt-4 pb-2"
          :value="$store.getters.serialTab"
          hide-slider
          @change="setSerialTab"
        >
          <v-tab class="my-2 mr-4 tab" active-class="tab-active" href="#monitor">{{$t('serial.monitor')}}</v-tab>
          <v-tab class="my-2 ml-4 tab" active-class="tab-active" href="#plotter">{{ $t('serial.plotter') }}</v-tab>
        </v-tabs>
        <v-btn icon x-large class="icon-close ma-4" @click="toggleSerialShelf($event)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-flex>
      <v-tabs-items :value="$store.getters.serialTab" @change="setSerialTab">
        <v-tab-item value="monitor">
          <serial-monitor/>
        </v-tab-item>
        <v-tab-item value="plotter">
          <serial-plotter />
        </v-tab-item>
      </v-tabs-items>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import PluggedIcon from './plugged-icon.vue';
import SerialMonitor from './monitor.vue';
import SerialPlotter from './plotter.vue';

export default {
  components: {
    SerialMonitor,
    SerialPlotter,
    PluggedIcon,
  },
  computed: {
    ...mapGetters(['serialShelf', 'serialTab', 'monitorPaused', 'plotterPaused']),
  },
  methods: {
    ...mapMutations(['toggleSerialShelf', 'setSerialTab']),
  },
};
</script>

<style>
.monitor-modal {
  border: 3px solid var(--v-toolbox1-base);
  border-radius: 10px;
  position: relative;
  width: 75%;
  max-width: 1200px;
}
.icon-close {
  position: absolute;
  right: 0;
}
.tab {
  background-color: var(--v-lightGrey-base);
  border-radius: 6px;
  text-transform: none;
  min-width: 132px;
}
.tab-active {
  background-color: var(--v-yellow-base);
  color: var(--v-lightText-base) !important;
}
</style>
