<template>
  <div>
    <div class="blockly-doc-container">
      <div :class="{...inject}" :ref="inject" width="500px" height="500px"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BlocklyDoc from 'blockly';

// // language
import { AES } from '@academiadeinventores/helloblocks-blocks/src/msg/es';
import { AEN } from '@academiadeinventores/helloblocks-blocks/src/msg/en';
import { ACA } from '@academiadeinventores/helloblocks-blocks/src/msg/ca';
import { AEU } from '@academiadeinventores/helloblocks-blocks/src/msg/eu';

import toolboxBlockStyles from './toolbox/toolboxStylesFullConf';
import { CATEGORY_LANG_MAP } from './toolbox/toolboxTranslations';
import '../../plugins/block-plus-minus/index';

const BLOCKLY_LANG_MAP = {
  en: AEN(),
  es: AES(),
  ca: ACA(),
  eu: AEU(),
};

export default {
  data() {
    return {
      workspace: null,
    };
  },
  props: {
    block: String,
    inject: String,
  },
  mounted() {
    // Inject Blockly
    this.injectBlockly();
  },
  computed: {
    ...mapGetters(['locale']),
  },
  watch: {
    locale(val) {
      BlocklyDoc.setLocale(this.loadCategoryTranslations(val));
      BlocklyDoc.Events.disable();
      const xml = BlocklyDoc.Xml.workspaceToDom(BlocklyDoc.getMainWorkspace());
      BlocklyDoc.Xml.clearWorkspaceAndLoadFromXml(xml, this.workspace);
      BlocklyDoc.Events.enable();
    },
  },
  methods: {
    injectBlockly() {
      // console.log(this.inject);

      this.workspace = BlocklyDoc.inject(this.inject, {
        comments: false,
        toolbox: false,
        trashcan: false,
        readOnly: true,
        scrollbars: false,
        zoom: false,
        collapse: true,
        disable: true,
        maxBlocks: Infinity,
        maxInstances: Infinity, // {controls_if: 3, control_arduino_setup: 1, control_arduino_loop: 1 },
        horizontalLayout: false,
        rtl: false,
        move: false,
        renderer: 'zelos',
        grid: false,
        theme: {
          blockStyles: this.getBlockStyles(),
        },
      });

      const xml = BlocklyDoc.Xml.textToDom(this.$refs);
      BlocklyDoc.Xml.domToWorkspace(xml, this.workspace);

      const metrics = this.workspace.getMetrics();
      this.$refs.blocklyDoc.style.height = `${metrics.contentHeight}px`;
      this.$refs.blocklyDoc.style.width = `${metrics.contentWidth}px`;
      BlocklyDoc.svgResize(this.workspace);
    },
    getBlockStyles() {
      return toolboxBlockStyles.reduce(
        (acc, { style, themeVariable }) => ({
          ...acc,
          [style]: { colourPrimary: this.$vuetify.theme.currentTheme[themeVariable] },
        }),
        {},
      );
    },
    loadCategoryTranslations(lang) {
      let selectedLang = lang;
      if (BLOCKLY_LANG_MAP[lang] === undefined) {
        selectedLang = this.$i18n.fallbackLocale;
      }
      const translations = BLOCKLY_LANG_MAP[selectedLang];
      const categoryTranslations = CATEGORY_LANG_MAP[selectedLang];
      return { ...translations, ...categoryTranslations };
    },
  },
};
</script>

<style>
.blockly-doc-container {
  position: relative;
  flex: 1;
  margin: 10px;
  border: none;
  padding: 10px;
}

.blocklyDoc {
  height: 500px;
  width: 500px;
  text-align: left;
  border: none;
}

.blocklyDoc .blocklyMainBackground {
  stroke: none;
}
</style>
