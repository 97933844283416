import Notifier from '../components/notifier.vue';

class NotifierPlugin {
  install(Vue, { store }) {
    this.store = store;

    Vue.prototype.$notifier = this;
    Vue.component('v-notifier', Notifier);
  }

  success(message) {
    this.store.commit('setNotification', { type: 'success', message });
  }

  error(message) {
    this.store.commit('setNotification', { type: 'error', message });
  }

  fatalError(message) {
    this.store.commit('setNotification', { type: 'fatal', message });
  }
}

export default new NotifierPlugin();
