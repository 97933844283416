<template>
  <div>
    <v-menu
      offset-y
      rounded="b-lg"
      v-model="value"
      content-class="adi-menu py-1"
      z-index="90"
      :close-on-click="value"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="close"
          color="toolbox1"
          depressed
          :class="[value ? 'menu-button-long rounded-t-lg' : 'rounded-lg','white--text','text-none','menu-button-short']"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left dark> mdi-chevron-{{value ? 'up':'down'}} </v-icon>
          {{ $t('toolbar.menu') }}
        </v-btn>
      </template>

      <hr class="mb-2" />
      <new-project @closed="this.closeMenu"/>
      <div v-for="(item, index) in items" :key="index" >
        <menu-item :item="item" />
      </div>
      <import-project @closed="this.closeMenu"/>
      <tools-menu ref="toolsRef" @closed="this.closeMenu"/>
      <card-selection @closed="this.closeMenu"/>
      <settings @closed="this.closeMenu"/>
      <screen-shot/>
    </v-menu>
    <div class="box-blind" v-if="value"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Export from '@public/img/menu/export.svg';
import Blockly from 'blockly';
import NewProject from './new.vue';
import ImportProject from './import.vue';
import ToolsMenu from './tools-menu.vue';
import MenuItem from './menu-item.vue';
import Settings from './settings.vue';
import CardSelection from './card-selection.vue';
import ScreenShot from './screenshot.vue';

export default {
  components: {
    NewProject,
    ImportProject,
    ToolsMenu,
    MenuItem,
    Settings,
    CardSelection,
    ScreenShot,
  },
  data() {
    return {
      value: true,
      items: [
        { title: 'toolbar.export', icon: Export, action: this.exportCode },
      ],
    };
  },
  computed: {
    ...mapGetters(['projectName']),
  },
  methods: {
    exportCode() {
      const xml = Blockly.Xml.workspaceToDom(Blockly.getMainWorkspace());
      const text = Blockly.Xml.domToText(xml);
      const link = document.createElement('a');
      try {
        const name = this.projectName || 'Hello-Blocks';
        link.download = `${name}.hb`;
        link.href = `data:application/octet-stream;utf-8,${encodeURIComponent(
          text,
        )}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (e) {
        window.location.href = `data:application/octet-stream;utf-8,${encodeURIComponent(
          text,
        )}`;
        this.$notifier.error(e);
      }
    },
    openTools() {
      this.$refs.toolsRef.open();
    },
    closeMenu() {
      this.value = false;
    },
  },
};
</script>

<style scoped>
hr {
  width: 82%;
  height: 3px;
  background: var(--v-lightestGrey-base);
  margin: auto;
}

button {
  letter-spacing: normal;
}

.menu-button-long{
  width: 192px !important;
  justify-content: start;
}

.menu-button-short{
  width:110px;
  justify-content: start;
}

#close::before {
  background-color: transparent !important;
}

.box-blind{
  position: absolute;
  background: var(--v-toolbox1-base);
  width: 192px;
  height: 20px;
  transform: translateY(-4px);
}

.adi-menu {
  display: flex;
  flex-direction: column;
  background: var(--v-toolbox1-base);
  transition: all 0s;
  width: 192px;
}
</style>
