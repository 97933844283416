/* eslint-disable */
/**
 * @license
 * Copyright 2020 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview Changes the if block to use a +/- mutator UI.
 */

 import Blockly from 'blockly/core';
 import {createMinusField} from './field_minus';
 import {createPlusField} from './field_plus';

 const logicIfMutator = {
   // TODO: This should be its own extension. But that requires core changes.
   suppressPrefixSuffix: true,

   /**
    * Number of else-if inputs on this block.
    * @type {number}
    */
   elseIfCount_: 0,
   /**
    * Whether this block has an else input or not.
    * @type {boolean}
    */
   hasElse_: false,
   hasVirtualElse_: false,
   ifInit_: false,

   /**
    * Creates XML to represent the number of else-if and else inputs.
    * @return {Element} XML storage element.
    * @this {Blockly.Block}
    */
   mutationToDom: function() {
     const container = Blockly.utils.xml.createElement('mutation');
     container.setAttribute('elseif', this.elseIfCount_);
     if (this.hasVirtualElse_) {
       // Has to be stored as an int for backwards compat.
       container.setAttribute('else', 1);
     }
    return container;
   },

   /**
    * Parses XML to restore the else-if and else inputs.
    * @param {!Element} xmlElement XML storage element.
    * @this {Blockly.Block}
    */
   domToMutation: function(xmlElement) {
     const targetCount = parseInt(xmlElement.getAttribute('elseif'), 10) || 0;
     this.hasVirtualElse_ = !!parseInt(xmlElement.getAttribute('else'), 10) || 0;
     if (this.hasVirtualElse_) {
       this.appendDummyInput('IF_ELSE')
           .appendField(createMinusField(0), 'MINUS' + 0)
           .appendField(Blockly.Msg['LOGIC_IF_MSG_ELSE']);
       this.appendStatementInput('DO_ELSE')
           .appendField(Blockly.Msg['LOGIC_IF_MSG_THEN']);
     }
     this.updateShape_(targetCount);
     this.ifInit_ = true;
   },

   /**
    * Adds else-if and do inputs to the block until the block matches the
    * target else-if count.
    * @param {number} targetCount The target number of else-if inputs.
    * @this {Blockly.Block}
    * @private
    */
   updateShape_: function(targetCount) {
     while (this.elseIfCount_ < targetCount) {
       this.addElseIf_();
     }
     while (this.elseIfCount_ > targetCount) {
       this.removeElseIf_();
     }
   },

   /**
    * Callback for the plus field. Adds an else-if input to the block.
    */
   plus: function() {
     this.addElseIf_();
   },

   /**
    * Callback for the minus field. Triggers "removing" the input at the specific
    * index.
    * @see removeInput_
    * @param {number} index The index of the else-if input to "remove".
    * @this {Blockly.Block}
    */
   minus: function(index) {
     this.removeElseIf_(index);
   },

   /**
    * Adds an else-if and a do input to the bottom of the block.
    * @this {Blockly.Block}
    * @private
    */
   addElseIf_: function() {
     // Because else-if inputs are 1-indexed we increment first, decrement last.
     // primer + simpre crea else

     if (!this.hasVirtualElse_ && this.ifInit_) {
      // si no hay else virtual, lo genera
      this.appendDummyInput('IF_ELSE')
          .appendField(createMinusField(0), 'MINUS' + 0)
          .appendField(Blockly.Msg['LOGIC_IF_MSG_ELSE']);
      this.appendStatementInput('DO_ELSE')
          .appendField(Blockly.Msg['LOGIC_IF_MSG_THEN']);
      this.hasVirtualElse_ = true;
    }
     // siguientes + generan un elseif y lo intecambian por el anterior else si este existe
     else  {
       this.elseIfCount_++;
       // genera else if
       this.appendValueInput('IF' + this.elseIfCount_)
           .setCheck('Boolean')
           .appendField(
             createMinusField(this.elseIfCount_), 'MINUS' + this.elseIfCount_)
           .appendField(Blockly.Msg['LOGIC_IF_MSG_ELSEIF']);
       this.appendStatementInput('DO' + this.elseIfCount_)
           .appendField(Blockly.Msg['LOGIC_IF_MSG_THEN']);
    }
    // intercambia si else <-> elseif si existe virtual else
    if (this.hasVirtualElse_) {
      this.moveInputBefore('IF_ELSE', null);
      this.moveInputBefore('DO_ELSE', null);
    }
   },

   /**
    * Appears to remove the input at the given index. Actually shifts attached
    * blocks and then removes the input at the bottom of the block. This is to
    * make sure the inputs are always IF0, IF1, etc with no gaps.
    * @param {?number=} index The index of the input to "remove", or undefined
    *     to remove the last input.
    * @this {Blockly.Block}
    * @private
    */
   removeElseIf_: function(index = undefined) {
     // The strategy for removing a part at an index is to:
     //  - Kick any blocks connected to the relevant inputs.
     //  - Move all connect blocks from the other inputs up.
     //  - Remove the last input.
     // This makes sure all of our indices are correct.
     if ((index == 0)) {
       this.removeInput('IF_ELSE');
       this.removeInput('DO_ELSE');

       this.hasVirtualElse_ = false;
     }
     else
     {
       if (index !== undefined && index != this.elseIfCount_) {
         // Each else-if is two inputs on the block:
         // the else-if input and the do input.
         const elseIfIndex = index * 2;
         const inputs = this.inputList;
         let connection = inputs[elseIfIndex].connection; // If connection.
         if (connection.isConnected()) {
           connection.disconnect();
         }
         connection = inputs[elseIfIndex + 1].connection; // Do connection.
         if (connection.isConnected()) {
           connection.disconnect();
         }
         this.bumpNeighbours();
         for (let i = elseIfIndex + 2, input; (input = this.inputList[i]); i++) {
           if (input.name == 'IF_ELSE') {
             break; // Should be last, so break.
           }
           const targetConnection = input.connection.targetConnection;
           if (targetConnection) {
             this.inputList[i - 2].connection.connect(targetConnection);
           }
         }
       }

       this.removeInput('IF' + this.elseIfCount_);
       this.removeInput('DO' + this.elseIfCount_);

       // Because else-if inputs are 1-indexed we increment first, decrement last.
       this.elseIfCount_--;
     }
   },
 };

 /**
  * Adds the initial plus button to the if block.
  * @this {Blockly.Block}
  */
 const logicIfHelper = function() {
   this.getInput('IF0').insertFieldAt(0, createPlusField(), 'PLUS');
 };

 Blockly.Extensions.unregister('controls_if_mutator');
 Blockly.Extensions.registerMutator('controls_if_mutator',
     logicIfMutator, logicIfHelper);
