import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export const lightTheme = {
  orange: '#ed6d63',
  yellow: '#fdcb5d',
  green: '#1cb57e',
  off: '#dadada',
  darkBackground: '#3f3e40',
  darkText: '#3f3e40',
  normalText: '#575756',
  background: '#fff',
  lightText: '#fff',
  darkWhite: '#f4f5f5',
  lightestGrey: '#e7e8e8',
  lightGrey: '#ccc',
  grey: '#b5b7b9',
  greyShadow: '#ccc',
  toolbox1: '#384667',
  toolbox2: '#008FC7',
  toolbox3: '#02AC8A',
  toolbox4: '#F2BB64',
  toolbox5: '#D19032',
  toolbox6: '#E5867B',
  toolbox7: '#E84B3C',
  toolbox8: '#844C89',
  toolbox9: '#057F67',
  toolbox10: '#F2A118',
  toolbox11: '#67B258',
};

export const darkTheme = {
  orange: '#ed6d63',
  yellow: '#fdcb5d',
  green: '#1cb57e',
  off: '#575756',
  darkBackground: '#3f3e40',
  darkText: '#e7e8e8',
  normalText: '#e7e8e8',
  background: '#272727',
  lightText: '#fff',
  darkWhite: '#333333',
  lightestGrey: '#333333',
  lightGrey: '#797979',
  grey: '#b5b7b9',
  greyShadow: '#302f31',
  toolbox1: '#384667',
  toolbox2: '#008FC7',
  toolbox3: '#02AC8A',
  toolbox4: '#F2BB64',
  toolbox5: '#D19032',
  toolbox6: '#E5867B',
  toolbox7: '#E84B3C',
  toolbox8: '#844C89',
  toolbox9: '#057F67',
  toolbox10: '#F2A118',
  toolbox11: '#67B258',
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: lightTheme,
      dark: darkTheme,
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
