<template>
  <button ref="button" :disabled="disabled" class="ripple d-flex align-center justify-center" v-on:click="on">
    <slot/>
  </button>
</template>

<script>

export default {
  props: ['on', 'disabled'],
  mounted() {
    this.$refs.button.addEventListener('click', this.createRipple);
  },
  methods: {
    createRipple(event) {
      const button = event.currentTarget;

      const circle = document.createElement('span');
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;

      circle.style.width = `${diameter}px`;
      circle.style.height = `${diameter}px`;
      circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
      circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
      circle.classList.add('ripple');

      const ripple = button.getElementsByClassName('ripple')[0];

      if (ripple) {
        ripple.remove();
      }

      button.appendChild(circle);
    },
  },
  beforeDestroy() {
    this.$refs.button.removeEventListener('click', this.createRipple);
  },
};

</script>

<style>
button.ripple {
  overflow: hidden;
}
</style>
