<template>
  <v-dialog
    max-width="600"
    overlay-color="background"
    v-model="dialog"
    content-class="editor-modal"
    style="z-index: 1002;"
  >
    <template v-slot:activator="{ on }">
      <div class="tools-item">
        <button class="ml-2 my-2" v-on="on" @click="handleClick">
          <div class="d-flex align-center">
            <lcd-icon width="16" />
            <div class="text-body-2 mx-2 white--text">{{$t('toolbar.lcd-editor')}}</div>
          </div>
        </button>
      </div>
    </template>
    <template>
      <tools-header :text="$t('toolbar.tools')" class="text-sm-body-2" @closed="closeDialog">
        <tool-icon height="25" />
      </tools-header>
      <div class="px-4">
        <div class="lcd-body mb-4">
          <p class="pt-2 darkText--text text-center font-weight-bold">LCD editor</p>
          <div class="d-flex flex-column justify-center align-center mt-8">
            <matrix :rows="8" :columns="5" ref="matrix" @updatedData="updatedData"/>
            <buttons
              class="d-flex mt-4 justify-space-between px-0"
              :code="code"
              @clear="this.clear"
              @fill="this.fill"
            />
          </div>
          <matrix-textarea class="mx-12 mt-4" :code="code"/>
        </div>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import LcdIcon from '@public/img/icons/lcd.svg';
import ToolIcon from '@public/img/menu/tools.svg';
import ToolsHeader from './header.vue';
import Matrix from './matrix.vue';
import Buttons from './buttons.vue';
import MatrixTextarea from './matrix-textarea.vue';

export default {
  components: {
    MatrixTextarea,
    LcdIcon,
    ToolIcon,
    ToolsHeader,
    Matrix,
    Buttons,
  },
  data: () => ({
    dialog: false,
    code: '',
  }),
  methods: {
    clear() {
      this.$refs.matrix.clear();
    },
    fill() {
      this.$refs.matrix.fill();
    },
    closeDialog() {
      this.dialog = false;
    },
    updatedData(val) {
      this.code = val;
    },
    handleClick() {
      this.dialog = true;
      this.$emit('clicked');
    },
  },
};
</script>

<style>
.editor-modal {
  overflow-y: hidden;
  background: var(--v-background-base);
  border-radius: 25px;
  border: 1px solid var(--v-lightGrey-base);
  box-shadow: 8px 8px 8px var(--v-greyShadow-base);
}
.lcd-body {
  overflow-y: auto;
  max-height: 80vh;
}
.tools-item:hover {
  background: var(--v-toolbox1-lighten1);
}
</style>
