<template>
  <v-snackbar v-model="snackbar" :color="color">
    {{ text }}

    <v-btn text  @click="snackbar = false">
        Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

const colors = {
  error: 'red',
  success: 'green',
  fatal: 'pink',
};

export default {
  data: () => ({
    snackbar: false,
    text: '',
    type: '',
    color: '',
  }),
  computed: {
    ...mapGetters(['notification']),
  },
  methods: {
    showMessage({ message, type }) {
      this.text = message;
      this.type = type;
      this.snackbar = true;
      this.color = colors[type];
    },
  },
  watch: {
    notification(newValue) {
      this.showMessage(newValue);
    },
  },
};
</script>
