<template>
  <div>
    <connect-btn :on="activate" :initialized="currentDevice" />
  </div>
</template>

<script>
import ConnectBtn from './connect-btn.vue';

export default {
  components: {
    ConnectBtn,
  },
  data() {
    return {
      currentDevice: (this.$serial && this.$serial.currentDevice) || null,
      deviceCB: null,
    };
  },
  mounted() {
    this.currentDevice = this.$serial.currentDevice || null;
    this.deviceCB = (value) => { this.currentDevice = value; };
    this.$serial.on('currentDevice', this.deviceCB);
  },
  beforeDestroy() {
    if (this.deviceCB) this.$serial.off('currentDevice', this.deviceCB);
  },
  methods: {
    activate() {
      if (this.currentDevice) {
        this.$serial.disconnectDevice();
      } else if (this.$serial.handlesSelect) {
        this.$serial.requestDevice();
      }
    },
  },
};
</script>
<style scoped>
</style>
