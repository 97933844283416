<template>
  <div>
    <board-not-selected-dialog
      :d-enable-button="false"
      :d-ext-activate="boardNotSelectedActivate"
      @response="boardNotSelectedAction"/>
    <board-not-connected-dialog
      :d-enable-button="false"
      :d-ext-activate="boardNotConnectedActivate"
      @response="boardNotConnectedAction"/>
    <drivers-installed-dialog
      :d-enable-button="false"
      :d-ext-activate="driversInstalledActivate"
      @response="driversInstalledAction"/>
    <drivers-selection-dialog
      :d-enable-button="false"
      :d-ext-activate="driversSelectionActivate"
       @response="driversSelectionAction"/>
    <board-check-dialog
      :d-enable-button="false"
      :d-ext-activate="boardCheckActivate"
       @response="boardCheckAction"/>
  </div>
</template>

<script>
import BoardNotSelectedDialog from './board-not-selected-dialog.vue';
import BoardNotConnectedDialog from './board-not-connected-dialog.vue';
import DriversInstalledDialog from './drivers-installed-dialog.vue';
import DriversSelectionDialog from './drivers-selection-dialog.vue';
import BoardCheckDialog from './board-check-dialog.vue';

export default {
  components: {
    BoardNotSelectedDialog,
    BoardNotConnectedDialog,
    DriversInstalledDialog,
    DriversSelectionDialog,
    BoardCheckDialog,
  },
  props: {
    activate: Boolean,
  },
  data() {
    return {
      boardNotSelectedActivate: false,
      boardNotConnectedActivate: false,
      driversInstalledActivate: false,
      driversSelectionActivate: false,
      boardCheckActivate: false,
    };
  },
  methods: {
    boardNotSelectedAction(value) {
      if (value === 'cancel') {
        this.boardNotConnectedActivate = true;
      }
      this.$emit('firstDialogClose');
      this.boardNotSelectedActivate = false;
    },
    boardNotConnectedAction(value) {
      if (value === 'accept') {
        this.driversInstalledActivate = true;
      }
      this.boardNotConnectedActivate = false;
    },
    driversInstalledAction(value) {
      if (value === 'accept') {
        this.boardCheckActivate = true;
      } else if (value === 'cancel') {
        this.driversSelectionActivate = true;
      }
      this.driversInstalledActivate = false;
    },
    // driversDetectedAction(value) {
    //   if (value === 'cancel') {
    //     this.driversSelectionActivate = true;
    //   }
    //   this.driversDetectedActivate = false;
    // },
    driversSelectionAction() {
      this.driversSelectionActivate = false;
    },
    boardCheckAction() {
      this.boardCheckActivate = false;
    },
  },
  watch: {
    activate(newValue) {
      this.boardNotSelectedActivate = newValue;
    },
  },
};
</script>
