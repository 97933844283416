<template>
  <div class="d-flex align-center justify-center external-dot" :class="valid ? 'green-border' : 'orange-border'">
    <div class="internal-dot" :class="valid ? 'green' : 'orange'" />
  </div>
</template>

<script>
export default {
  props: ['valid'],
};
</script>

<style scoped>
.external-dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: transparent;
}
.external-dot.green-border {
  border: 2px solid var(--v-green-base);
}
.external-dot.orange-border {
  border: 2px solid var(--v-orange-base);
}
.internal-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
</style>
