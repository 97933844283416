import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from './feathers-client';

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/,
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export const getUserLang = () => sessionStorage.helloblocksLocale || window.navigator.language
  || window.navigator.userLanguage || 'es';

const store = new Vuex.Store({
  state: {
    locale: getUserLang(),
    serialShelf: false,
    codeShelf: false,
    donateMenu: false,
    serialTab: 'program',
    currentServer: sessionStorage.currentServer || null,
    currentBoard: sessionStorage.currentBoard || '51b2904f-b7fd-3df8-9330-0ef1b852b816',
    currentBoardCustomName: sessionStorage.currentBoardCustomName || 'uno-board',
    currentProject: sessionStorage.currentProject || null,
    currentFile: sessionStorage.currentFile || null,
    currentEditor: sessionStorage.currentEditor || 'full',
    blocklySaved: false,
    // eslint-disable-next-line quotes
    currentCode: '',
    projectName: sessionStorage.helloblocksProjectName || '',
    userInfo: {
      name: '',
    },
    notification: {
      type: '',
      message: '',
    },
    showOverlay: false,
    showHeader: sessionStorage.helloblocksShowHeader || sessionStorage.helloblocksShowHeader === undefined,
    interfaceMode: sessionStorage.interfaceMode || 'light-mode',
    sound: sessionStorage.sound || 'sound',
    colorSpace: sessionStorage.colorSpace || 'turned-off',
    backpack: undefined,
    monitorPaused: false,
    plotterPaused: false,
    projectVersion: 0,
  },
  mutations: {
    changeLocale(state, val) {
      state.locale = val;
    },
    toggleSerialShelf(state, val) {
      state.serialShelf = typeof val === 'boolean' ? val : !state.serialShelf;
    },
    toggleDonateMenu(state, val) {
      state.donateMenu = typeof val === 'boolean' ? val : !state.donateMenu;
    },
    setSerialTab(state, val) {
      state.serialTab = val;
    },
    setCodeTab(state, val) {
      state.serialTab = val;
    },
    setCurrentServer(state, uuid) {
      sessionStorage.currentServer = uuid;
      state.currentServer = uuid;
    },
    setCurrentBoard(state, uuid) {
      sessionStorage.currentBoard = uuid;
      state.currentBoard = uuid;
    },
    setCurrentBoardCustomName(state, id) {
      sessionStorage.currentBoardCustomName = id;
      state.currentBoardCustomName = id;
    },
    setCurrentProject(state, uuid) {
      sessionStorage.currentProject = uuid;
      state.currentProject = uuid;
    },
    setCurrentFile(state, uuid) {
      sessionStorage.currentFile = uuid;
      state.currentFile = uuid;
    },
    setCurrentEditor(state, editor) {
      sessionStorage.currentEditor = editor;
      state.currentEditor = editor;
    },
    setBlocklySaved(state, val) {
      state.blocklySaved = typeof val === 'boolean' ? val : !state.serialShelf;
    },
    toggleCodeShelf(state, val) {
      state.codeShelf = typeof val === 'boolean' ? val : !state.codeShelf;
    },
    setCode(state, code) {
      state.currentCode = code;
    },
    setNotification(state, { type, message }) {
      state.notification = {
        type,
        message,
      };
    },
    setShowOverlay(state, value) {
      state.showOverlay = value;
    },
    setProjectName(state, name) {
      sessionStorage.helloblocksProjectName = name;
      state.projectName = name;
    },
    setUserName(state, name) {
      state.userInfo.name = name;
    },
    toggleShowHeader(state) {
      sessionStorage.helloblocksShowHeader = !state.showHeader;
      state.showHeader = !state.showHeader;
    },
    setInterfaceMode(state, value) {
      sessionStorage.interfaceMode = value;
      state.interfaceMode = value;
    },
    setSound(state, value) {
      sessionStorage.sound = value;
      state.sound = value;
    },
    setColorSpace(state, value) {
      sessionStorage.colorSpace = value;
      state.colorSpace = value;
    },
    setBackpack(state, value) {
      state.backpack = value;
    },
    toggleMonitorPaused(state, val) {
      state.monitorPaused = typeof val === 'boolean' ? val : !state.monitorPaused;
    },
    togglePlotterPaused(state, val) {
      state.plotterPaused = typeof val === 'boolean' ? val : !state.plotterPaused;
    },
    increaseProjectVersion(state) {
      state.projectVersion += 1;
    },
  },
  getters: {
    locale(state) { return state.locale; },
    serialShelf(state) { return state.serialShelf; },
    codeShelf(state) { return state.codeShelf; },
    donateMenu(state) { return state.donateMenu; },
    serialTab(state) { return state.serialTab; },
    currentServer(state) { return state.currentServer; },
    currentBoard(state) { return state.currentBoard; },
    currentBoardCustomName(state) { return state.currentBoardCustomName; },
    currentProject(state) { return state.currentProject; },
    currentFile(state) { return state.currentFile; },
    currentEditor(state) { return state.currentEditor; },
    currentBlocklySaved(state) { return state.blocklySaved; },
    currentCode(state) { return state.currentCode; },
    notification(state) { return state.notification; },
    projectName(state) { return state.projectName; },
    userName(state) { return state.userInfo.name; },
    showOverlay(state) { return state.showOverlay; },
    showHeader(state) { return state.showHeader; },
    interfaceMode(state) { return state.interfaceMode; },
    sound(state) { return state.sound; },
    colorSpace(state) { return state.colorSpace; },
    backpack(state) { return state.backpack; },
    monitorPaused(state) { return state.monitorPaused; },
    plotterPaused(state) { return state.plotterPaused; },
    projectVersion(state) { return state.projectVersion; },
  },
  actions: {

  },
  modules: {

  },

  plugins: [
    ...servicePlugins,
  ],
});

export default store;
