<template>
  <div style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: center;">
    <complete></complete>
  </div>
</template>

<script>
import Complete from '@public/upload/complete.svg';

export default {
  components: {
    Complete,
  },
};
</script>

<style>

</style>
