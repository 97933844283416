<template>
  <ripple-button :disabled="!valid" :class="{'upload': !valid}" :on="upload">
    <upload-svg :fill="$vuetify.theme.currentTheme[`${valid ? 'green' : 'off'}`]" class="upload" height="36px" />
    <upload-small-svg :fill="$vuetify.theme.currentTheme[`${valid ? 'green' : 'off'}`]" class="small-upload" height="36px" />
    <div class="custom-text d-flex align-center">
      <v-icon dense class="pl-1 pr-2" color="lightText" size="24">mdi-upload</v-icon>
      <span class="lightText--text pr-1 button-text">
        {{ $t(`commons.upload`) }}
      </span>
    </div>
  </ripple-button>
</template>

<script>

import UploadSvg from '@public/img/icons/upload.svg';
import UploadSmallSvg from '@public/img/icons/upload-small.svg';
import RippleButton from '../ripple-button.vue';

export default {
  components: {
    UploadSvg,
    UploadSmallSvg,
    RippleButton,
  },
  props: ['valid'],
  methods: {
    upload() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
button {
  position: relative;
}
button:not(:disabled):hover {
  filter: brightness(0.9);
}
button.upload {
  margin-left: 2em;
}
.custom-text {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
}
@media (max-width: 1000px) {
  .upload, .button-text {
    display: none;
  }
  .custom-text {
    padding-left: 8px;
  }
}
@media (min-width: 1000px) {
  .small-upload {
    display: none;
  }
}
</style>
