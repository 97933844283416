import Blockly from 'blockly';

const iconStyleArray = [
  'border-radius: 5px;',
  'margin-right: 16px;',
  'height: 20px;',
  'width: 20px;',
];

const emptyIconStyleArray = [
  'border-radius: 5px;',
  'margin-right: 16px;',
  'height: 20px;',
  'width: 20px;',
  'border-style: solid;',
  'border-width: 6px;',
];

const liteEmptyIconStyleArray = [
  'border-radius: 10px;',
  'margin-right: 16px;',
  'height: 20px;',
  'width: 20px;',
  'border-style: solid;',
  'border-width: 6px;',
];

const blocklyTreeRowSelected = [
  'background-position: right;',
  'background-color: transparent !important;',
  'height: 84px;',
];

Blockly.Css.register([
  /* eslint-disable indent */

  '.blocklyToolboxGrab {',
    'cursor: url("<<<PATH>>>/handclosed.cur"), auto;',
    'cursor: grabbing;',
    'cursor: -webkit-grabbing;',
  '}',

  /* Category tree in Toolbox. */
  '.blocklyToolboxDiv {',
    'background-color: white;',
    'padding: 4px 0 4px 10px;',
    'position: absolute;',
    '-webkit-tap-highlight-color: transparent;', /* issue #1345 */
    'overflow: hidden;',
  '}',

  '.blocklyToolboxDelete {',
    'background-color: var(--v-background-base);',
    'background-position: center;',
    'opacity: 0.8 !important;',
    'z-index: 1111;',
  '}',

  '@-webkit-keyframes pulse {',
    '0% {-webkit-transform: scale(0.9, 0.9);}',
    '50% {-webkit-transform: scale(1.0, 1.0);}',
    '100% {-webkit-transform: scale(0.9, 0.9);}',
  '}',

  '.pulseTrashMask {',
    'cursor: url("<<<PATH>>>/handdelete.cur"), auto;',
    'background-image: url("./img/icons/toolbox/bin_v0.svg");',
    'background-color: transparent;',
    'background-size: 20%;',
    'background-position: center;',
    'opacity: 1.0 !important;',
    'z-index: 1112;',
    'animation: pulse 1s ease-in-out infinite;',
  '}',

  '.blocklyToolboxContents {',
    'padding-left: 32px;',
    'direction: ltr;',
    'overflow-x: visible;',
    'overflow-y: overlay;',
    'height: 100%;',
    'flex-wrap: nowrap;',
    'direction: rtl;',
  '}',

  '.blocklyToolboxCategory {',
    'cursor: pointer;',
    'direction: ltr;',
    'width: 290px;', // fixed width per request
  '}',

  '.blocklyToolboxContents .blocklyTreeSeparator {',
    'margin: 15px 0;',
  '}',

  '.blocklyTreeRowContentContainer {',
    'display: flex;',
    'align-items: center;',
    'height: 100%;',
    'padding-left: 6px',
  '}',

  '.blocklyTreeLabel {',
    'font-family: Lemur-Medium, Lemur;',
    'font-size: 20px;',
    'padding-bottom: 2px;',
    'color: var(--v-normalText-base);',
  '}',

  '.blocklyTreeSelected .blocklyTreeLabel {',
    'color: var(--v-normalText-base);',
    'font-size: 24px;',
    'margin-right: 72px;',
  '}',

  '.someCategorySelected :not(.blocklyTreeSelected .blocklyTreeLabel) {',
    'color: var(--v-lightGrey-base);',
  '}',

  '.blocklyTreeSelected .blocklyToolboxLogic, .blocklyTreeSelected  .blocklyToolboxControl, ',
  '.blocklyTreeSelected .blocklyToolboxMath, .blocklyTreeSelected  .blocklyToolboxText, ',
  '.blocklyTreeSelected .blocklyToolboxVariable, .blocklyTreeSelected .blocklyToolboxFunctions, ',
  '.blocklyTreeSelected .blocklyToolboxLists, .blocklyTreeSelected .blocklyToolboxFunctions, ',
  '.blocklyTreeSelected .blocklyToolboxFullInOut, .blocklyTreeSelected .blocklyToolboxLiteInOut, ',
  '.blocklyTreeSelected .blocklyToolboxFullTime, ',
  '.blocklyTreeSelected .blocklyToolboxLiteTime, .blocklyTreeSelected .blocklyToolboxFullSerialPort, ',
  '.blocklyTreeSelected .blocklyToolboxLiteSerialPort, .blocklyTreeSelected .blocklyToolboxFullSensors, ',
  '.blocklyTreeSelected .blocklyToolboxLiteSensors, .blocklyTreeSelected .blocklyToolboxFullActuators',
  '.blocklyTreeSelected .blocklyToolboxLiteActuators, .blocklyTreeSelected .blocklyToolboxLcdScreen, ',
  '.blocklyTreeSelected .blocklyToolboxLedMatrix, .blocklyTreeSelected .blocklyToolboxNeoPixel, ',
  '.blocklyTreeSelected .blocklyToolboxFullMotor, .blocklyTreeSelected .blocklyToolboxLiteMotor, ',
  '.blocklyTreeSelected .blocklyToolboxRtcClock, ',
  '.blocklyTreeSelected .blocklyToolboxSdCard, .blocklyTreeSelected .blocklyToolboxBluetooth, ',
  '.blocklyTreeSelected .blocklyToolboxIR, .blocklyTreeSelected .blocklyToolboxWifi,  { ',
    'opacity: 0 !important;',
  '}',

  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLogic,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxControl,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxMath,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxText,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxVariable,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLists,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFunctions,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFullInOut,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLiteInOut,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFullTime,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLiteTime,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFullSerialPort,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLiteSerialPort,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFullSensors,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLiteSensors,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFullActuators,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLiteActuators,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLcdScreen,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLedMatrix,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxNeoPixel,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxFullMotor,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxLiteMotor,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxRtcClock,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxSdCard,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxIR,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxBluetooth,',
  '.someCategorySelected :not(.blocklyTreeSelected) .blocklyToolboxWifi {',
    'opacity: 0.4;',
  '}',

  '.blocklyToolboxLogic {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox1-base);',
  '}',

  '.blocklyToolboxControl {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox2-base);',
  '}',

  '.blocklyToolboxMath {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox3-base);',
  '}',

  '.blocklyToolboxText {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox4-base);',
  '}',

  '.blocklyToolboxVariable {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox10-base);',
  '}',

  '.blocklyToolboxLists {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox6-base);',
  '}',

  '.blocklyToolboxFunctions {',
    ...iconStyleArray,
    'background-color: var(--v-toolbox8-base);',
  '}',

  '.blocklyToolboxFullInOut {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox1-base);',
  '}',

  '.blocklyToolboxLiteInOut {',
    ...liteEmptyIconStyleArray,
    'border-color: var(--v-toolbox1-base);',
  '}',

  '.blocklyToolboxFullTime {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox2-base);',
  '}',

  '.blocklyToolboxLiteTime {',
    ...liteEmptyIconStyleArray,
    'border-color: var(--v-toolbox2-base);',
  '}',

  '.blocklyToolboxFullSerialPort {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox3-base);',
  '}',

  '.blocklyToolboxLiteSerialPort {',
    ...liteEmptyIconStyleArray,
    'border-color: var(--v-toolbox3-base);',
  '}',

  '.blocklyToolboxFullSensors {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox4-base);',
  '}',

  '.blocklyToolboxLiteSensors {',
    ...liteEmptyIconStyleArray,
    'border-color: var(--v-toolbox1-base);',
  '}',

  '.blocklyToolboxFullActuators {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox10-base);',
  '}',

  '.blocklyToolboxLiteActuators {',
    ...liteEmptyIconStyleArray,
    'border-color: var(--v-toolbox10-base);',
  '}',

  '.blocklyToolboxLcdScreen {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox6-base);',
  '}',

  '.blocklyToolboxLedMatrix {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox6-base);',
  '}',

  '.blocklyToolboxNeoPixel {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox6-base);',
  '}',

  '.blocklyToolboxFullMotor {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox8-base);',
  '}',

  '.blocklyToolboxLiteMotor {',
    ...liteEmptyIconStyleArray,
    'border-color: var(--v-toolbox8-base);',
  '}',

  '.blocklyToolboxRtcClock {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox1-base);',
  '}',

  '.blocklyToolboxSdCard {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox1-base);',
  '}',

  '.blocklyToolboxBluetooth {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox2-base);',
  '}',

  '.blocklyToolboxWifi {',
    ...emptyIconStyleArray,
    'border-color: var(--v-toolbox3-base);',
  '}',

  '.blocklyToolboxIR {',
  ...emptyIconStyleArray,
  'border-color: var(--v-toolbox4-base);',
  '}',

  '.blocklyTreeRowLogic, .blocklyTreeRowControl, .blocklyTreeRowMath, .blocklyTreeRowText, .blocklyTreeRowVariable, ',
  '.blocklyTreeRowLists, .blocklyTreeRowFunctions, .blocklyTreeRowInOut, .blocklyTreeRowTime, .blocklyTreeRowSerialPort, ',
  '.blocklyTreeRowFullSensors, .blocklyTreeRowLiteSensors, .blocklyTreeRowActuators, .blocklyTreeRowLcdScreen, ',
  '.blocklyTreeRowLedMatrix, .blocklyTreeRowNeoPixel, .blocklyTreeRowMotor, .blocklyTreeRowRtcClock, ',
  '.blocklyTreeRowSdCard, .blocklyTreeRowBluetooth, .blocklyTreeRowWifi, .blocklyTreeRowIR {',
    'margin-bottom: 0;',
    'border-left: none !important;',
    'height: 38px;',
    'margin-right: 8px;',
  '}',
  '.blocklyTreeRowLogic.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/logic.svg");',
  '}',
  '.blocklyTreeRowControl.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/control.svg");',
  '}',
  '.blocklyTreeRowMath.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/math.svg");',
  '}',
  '.blocklyTreeRowText.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/text.svg");',
  '}',
  '.blocklyTreeRowVariable.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/variable.svg");',
  '}',
  '.blocklyTreeRowLists.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/lists.svg");',
  '}',
  '.blocklyTreeRowFunctions.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/functions.svg");',
  '}',
  '.blocklyTreeRowInOut.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/inOut.svg");',
  '}',
  '.blocklyTreeRowTime.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/time.svg");',
  '}',
  '.blocklyTreeRowSerialPort.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/serialPort.svg");',
  '}',
  '.blocklyTreeRowFullSensors.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/sensors.svg");',
  '}',
  '.blocklyTreeRowLiteSensors.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/sensors_lite.svg");',
  '}',
  '.blocklyTreeRowActuators.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/actuators.svg");',
  '}',
  '.blocklyTreeRowLcdScreen.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/lcdScreen.svg");',
  '}',
  '.blocklyTreeRowLedMatrix.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/ledMatrix.svg");',
  '}',
  '.blocklyTreeRowNeoPixel.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/neoPixel.svg");',
  '}',
  '.blocklyTreeRowMotor.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/motor.svg");',
  '}',
  '.blocklyTreeRowRtcClock.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/rtcClock.svg");',
  '}',
  '.blocklyTreeRowSdCard.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/sdCard.svg");',
  '}',
  '.blocklyTreeRowBluetooth.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/bluetooth.svg");',
  '}',
  '.blocklyTreeRowWifi.blocklyTreeSelected {',
    ...blocklyTreeRowSelected,
    'background-image: url("./img/icons/toolbox/wifi.svg");',
  '}',
  '.blocklyTreeRowIR.blocklyTreeSelected {',
  ...blocklyTreeRowSelected,
  'background-image: url("./img/icons/toolbox/infrared.svg");',
  '}',

  /* fix scroolbar in context menu */
  '.blocklyContextMenu {',
    'box-sizing: content-box;',
  '}',

  /* eslint-enable indent */
]);
