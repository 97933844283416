<template>
  <div class="cdialog-card">
    <div class="cdialog-card-close">
      <v-btn icon @click="emitResponse('close');slotProps.close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card-title class="cdialog-card-title">
      {{ $t(dTitle) }}
    </v-card-title>
    <v-card-text class="cdialog-card-text">
      {{ $t(dBody) }}
    </v-card-text>
    <v-card-actions class="cdialog-card-actions">
      <div class="cdialog-card-action cdialog-card-action-left">
        <div class="cdialog-card-action-icon">
          <accept-icon width="45" />
        </div>
        <bubble-speech-button
          bsb-color="green"
          :bsb-text="dAcceptActionText"
          bsb-direction="left"
          @clicked="emitResponse('accept');slotProps.close()" />
      </div>
      <div class="cdialog-card-action cdialog-card-action-right">
        <bubble-speech-button
          bsb-color="grey"
          :bsb-text="dCancelActionText"
          bsb-direction="right"
          @clicked="emitResponse('cancel');slotProps.close()" />
        <div class="cdialog-card-action-icon">
          <cancel-icon width="45" />
        </div>
      </div>
    </v-card-actions>
  </div>
</template>

<script>
import AcceptIcon from '@public/img/icons/iconos_svg_29.svg';
import CancelIcon from '@public/img/icons/iconos_svg_28.svg';
import BubbleSpeechButton from '../actions/bubble-speech-button.vue';

export default {
  components: {
    AcceptIcon,
    CancelIcon,
    BubbleSpeechButton,
  },
  data: () => ({
    dTitle: 'connection-guide.dialog-3.title',
    dBody: 'connection-guide.dialog-3.body',
    dAcceptActionText: 'connection-guide.dialog-3.accept',
    dCancelActionText: 'connection-guide.dialog-3.cancel',
  }),
  props: {
    slotProps: Object,
  },
  methods: {
    emitResponse(response) {
      this.$emit('response', response);
    },
  },
};
</script>
<style scoped>
  .cdialog-card {
    padding: 20px;
    font-family: "Lemur";
  }
  .cdialog-card-close {
    height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .cdialog-card-title {
    color: var(--v-darkText-base);
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    word-break: normal;
  }
  .cdialog-card-text {
    color: var(--v-normalText-base);
    text-align: center;
    font-size: 1rem;
    margin-top: 20px;
  }
  .cdialog-card-actions {
    width: 100%;
    justify-content: space-around;
    margin: 20px 0px;
  }
  .cdialog-card-action {
    width: 50%;
    display: flex;
  }
  .cdialog-card-action-left {
    justify-content: flex-start;
  }
  .cdialog-card-action-right {
    justify-content: flex-end;
  }
  .cdialog-card-action-icon{
    width: 50px;
  }
</style>
