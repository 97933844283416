<template>
  <div>
    <app-header></app-header>
    <v-row>
      <v-container class="tree-col">
        <tree-view @nodeClick="onNodeClick($event)" class="col-class" />
      </v-container>

      <v-container class="block-col">
        <div class="col-class">
          <logic-section v-if="activeNode == 1" />
          <control-section v-else-if="activeNode == 2" />
          <math-section v-else-if="activeNode == 3" />
          <text-section v-else-if="activeNode == 4" />
        </div>
      </v-container>
    </v-row>
    <app-footer />
  </div>
</template>

<script>
import TreeView from '../components/documentation/Tree.vue';
import LogicSection from '../components/documentation/sections/logic.vue';
import ControlSection from '../components/documentation/sections/control.vue';
import MathSection from '../components/documentation/sections/math.vue';
import TextSection from '../components/documentation/sections/text.vue';
import AppHeader from '@/components/header/header.vue';
import AppFooter from '@/components/footer/footer.vue';

export default {
  components: {
    AppFooter,
    AppHeader,
    TreeView,

    LogicSection,
    ControlSection,
    MathSection,
    TextSection,
  },
  data() {
    return {
      activeNode: 1,
    };
  },
  methods: {
    onNodeClick(value) {
      this.activeNode = value;
    },
  },
};
</script>

<style>
.col-class {
  height: 900px;
  overflow-y: scroll;
}

.tree-col {
  width: 20%;
}

.block-col {
  width: 80%;
}
</style>
