<template>
  <v-flex class="d-flex flex-column" style="height: 50vh">
    <div :id="id" class="flex-grow-1 my-6 mx-12" />
    <v-flex class="d-flex flex-shrink-0 toolbox1 px-4 custom-footer align-center">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="mr-2" dark icon @click="togglePlotterPaused" v-on="on">
            <v-icon color="lightText">mdi-{{ plotterPaused ? 'play' : 'pause' }}</v-icon>
          </v-btn>
        </template>
        <span>
          {{ plotterPaused ? $t('serial.tooltips.play') : $t('serial.tooltips.pause') }}
          {{ $t('serial.tooltips.plotter') }}
        </span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="mr-2" :disabled="!plotterPaused" dark icon @click="clearCB && clearCB()" v-on="on">
            <v-icon color="lightText">mdi-eraser</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('serial.tooltips.clear') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="mr-4" dark icon @click="chartOpts.axisY.includeZero = !chartOpts.axisY.includeZero" v-on="on">
            <v-icon color="lightText">mdi-{{ chartOpts.axisY.includeZero ? 'arrow-up-down' : 'arrow-vertical-lock' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ chartOpts.axisY.includeZero ? $t('serial.tooltips.auto-scale') : $t('serial.tooltips.lock-y') }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="mr-8" style="margin-left: auto" @click="downloadCSV" v-on="on">
            <v-icon class="mr-2">mdi-download</v-icon>
            CSV
          </v-btn>
        </template>
        <span>{{ $t('serial.tooltips.csv') }}</span>
      </v-tooltip>

      <!-- Speed selector -->
      <v-row align="right">
        <v-col class="d-flex" cols="3" sm="3" offset-md="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <rate class="ml-4 mt-0" style="flex-grow: 0" />
              </div>
            </template>
            <span>{{ $t('serial.tooltips.speed') }}</span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="2" sm="2">
          <span class="lightestGrey--text"> {{ $t('serial.baudrate') }}</span>
        </v-col>
      </v-row>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { plotNames } from '@academiadeinventores/helloblocks-blocks/src/generator/arduino/generator-serial';
import Rate from './rate.vue';

export default {
  components: {
    Rate,
  },
  data() {
    return {
      data: null,
      chart: null,
      logCB: null,
      clearCB: null,
      lastRender: 0,
      timeWindow: 60,
      dataToDownload: '',
      buff: '',
      id: '',
      dataOpts: {
        type: 'line',
        // type: 'spline',
        lineThickness: 3,
        markerSize: 0,
        xValueType: 'dateTime',
        xValueFormatString: '',
        showInLegend: true,
        // legendText: 'value',
      },
      chartOpts: {
        zoomEnabled: true,
        legend: {
          horizontalAlign: 'center',
          verticalAlign: 'bottom',
          fontSize: 15,
          cursor: 'pointer',
          itemclick(e) {
            if (typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }
            this.chart.render();
          },
          itemTextFormatter(e) {
            let lastValue = 0;
            let index = 0;
            index = e.dataSeries.id;
            lastValue = e.dataSeries.dataPoints[e.dataSeries.dataPoints.length - 1].y;
            return `${plotNames[index - 1]}: ${lastValue.toFixed(1)}`;
          },
        },
        axisX: {
          lineColor: this.$vuetify.theme.currentTheme.normalText,
          lineThickness: 3,
          tickLength: 10,
          tickColor: this.$vuetify.theme.currentTheme.background,
        },
        axisY: {
          lineColor: this.$vuetify.theme.currentTheme.normalText,
          lineThickness: 3,
          gridColor: this.$vuetify.theme.currentTheme.grey,
          includeZero: false,
          tickLength: 10,
          tickColor: this.$vuetify.theme.currentTheme.background,
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(['projectName', 'plotterPaused', 'serialShelf']),
  },
  methods: {
    ...mapMutations(['togglePlotterPaused']),
    init() {
      this.initChart();
      this.logCB = (data) => {
        const now = Date.now();
        this.buff += data;
        const parts = this.buff.split(/\r?\n/g);
        if (parts.length < 2) return;
        this.buff = /\r?\n$/.test(this.buff) ? '' : parts.pop();
        parts.forEach((part) => {
          const nums = part
            .split(/[\s,]+/g)
            .map((num) => (num !== '' ? Number(num) : NaN))
            .map((num) => (Number.isNaN(num) ? null : num));
          if (!nums.some((num) => num !== null)) return;
          if (nums.length > this.data.length) {
            for (let i = 0; i < nums.length - this.data.length; i += 1) {
              this.data.push({
                dataPoints: [],
                pausedData: [],
                ...this.dataOpts,
              });
            }
          }
          this.dataToDownload += `${new Date(Date.now()).toISOString()}, ${part}\n`;
          this.data.forEach((datum, i) => {
            if (typeof nums[i] === 'number') datum.pausedData.push({ x: now, y: nums[i] });
            if (this.plotterPaused) {
              datum.pausedData = datum.pausedData.filter((point) => now - point.x <= this.timeWindow * 1000);
              return;
            }
            datum.dataPoints = [...datum.dataPoints, ...datum.pausedData].filter(
              (point) => now - point.x <= this.timeWindow * 1000,
            );

            const totalSeries = this.data.length;
            const pointsPerSerie = parseInt(5000 / totalSeries, 10);
            if (datum.dataPoints.length > pointsPerSerie) {
              datum.dataPoints = datum.dataPoints.filter((item, index) => index > datum.dataPoints.length - pointsPerSerie);
            }

            datum.pausedData = [];
          });
        });
        if (now - this.lastRender > 50 && this.chart) {
          this.lastRender = now;
          try {
            this.chart.render();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        }
      };
      this.$serial.on('message', this.logCB);
      this.clearCB = () => {
        this.initChart();
        this.dataToDownload = '';
      };
      this.$serial.on('clear', this.clearCB);
    },
    initChart() {
      this.id = `chart-container-${Math.random()}`.replace('0.', '');
      this.data = [];

      this.dataToDownload += 'Time';
      for (let i = 0; i < plotNames.length; i += 1) {
        this.dataToDownload += `, ${plotNames[i]}`;
      }
      this.dataToDownload += '\n';

      this.$nextTick(() => {
        this.chart = new window.CanvasJS.Chart(this.id, {
          data: this.data,
          ...this.chartOpts,
        });
      });
    },
    downloadCSV() {
      const link = document.createElement('a');
      try {
        const name = this.projectName || 'Hello-Blocks';
        link.download = `${name}-plotter-${new Date()}.csv`;
        link.href = `data:application/octet-stream;utf-8,${encodeURIComponent(this.dataToDownload)}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (e) {
        window.location.href = `data:application/octet-stream;utf-8,${encodeURIComponent(this.dataToDownload)}`;
        this.$notifier.error(e);
      }
    },
    dispose() {
      if (this.logCB) this.$serial.off('message', this.logCB);
      if (this.clearCB) this.$serial.off('clear', this.clearCB);
    },
  },
  beforeDestroy() {
    this.dispose();
  },
  watch: {
    serialShelf(val) {
      if (val) {
        this.init();
      } else {
        this.dispose();
      }
    },
  },
};
</script>

<style lang="scss">
.custom-footer {
  max-height: 50px;
}
.canvasjs-chart-credit {
  display: none;
}
</style>
