<template>
  <div class="orange matrix pa-5" :style="cssVars">
    <div class="cell ma-1"  v-on:click="handleClick(i)" :class="{'active': n}" v-for="(n, i) in matrixState" :key="i" />
  </div>
</template>

<script>

export default {
  props: ['rows', 'columns'],
  data() {
    return {
      matrixState: Array(this.rows * this.columns).fill(0),
    };
  },
  mounted() {
    this.getCode();
  },
  computed: {
    cssVars() {
      return {
        '--matrix-rows': this.rows,
        '--matrix-columns': this.columns,
      };
    },
  },
  methods: {
    clear() {
      this.matrixState.forEach((v, i) => {
        this.$set(this.matrixState, i, 0);
      });
      this.getCode();
    },
    fill() {
      this.matrixState.forEach((v, i) => {
        this.$set(this.matrixState, i, 1);
      });
      this.getCode();
    },
    handleClick(i) {
      this.$set(this.matrixState, i, 1 - this.matrixState[i]);
      this.getCode();
    },
    getCode() {
      let code = '';
      this.matrixState.forEach((v, i) => {
        if (i % this.columns === 0) {
          code += 'B';
        }
        code += v;
        if (i % this.columns === this.columns - 1) {
          code += ',';
        }
      });
      code = code.slice(0, -1);
      this.$emit('updatedData', code);
    },
  },
};
</script>

<style scoped>
.matrix {
  border-radius: 25px;
  display: grid;
  grid-template-columns: repeat(var(--matrix-columns), 1fr);
  grid-template-rows: repeat(var(--matrix-rows), 1fr);
}
.cell {
  width: 28px;
  height: 28px;
  background: var(--v-orange-darken2);
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
}
.cell.active {
  background: var(--v-lightText-base);
}
</style>
