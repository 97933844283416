<template>
  <div>
    <icon-svg width="45px"></icon-svg>

    <div class="ok-button" @click="$emit('click')">
      <upload-svg :fill="$vuetify.theme.currentTheme[color]" class="svg-button" height="32px" />
      <div class="ok-text d-flex align-center">
        <span class="lightText--text pr-1 button-text">
          {{ $t(`connection-guide.dialog-6.accept`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import UploadSvg from '@public/img/icons/upload.svg';
import IconSvg from '@public/img/icons/iconos_svg_29.svg';

export default {
  props: ['color'],
  components: {
    UploadSvg,
    IconSvg,
  },
  methods: {},
};
</script>

<style>
.ok-button {
  /* min-width: 180px; */
  display: inline-flex;
  margin-left: 6px;
  justify-content: center;
}
.ok-button:hover {
  opacity: 0.9;
  cursor: pointer;
}
ok-button.svg-button {
  margin-left: 2em;
}
.ok-text {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 4px;
}
</style>
