import { render, staticRenderFns } from "./browser-check.vue?vue&type=template&id=f9d66276&scoped=true&"
import script from "./browser-check.vue?vue&type=script&lang=js&"
export * from "./browser-check.vue?vue&type=script&lang=js&"
import style0 from "./browser-check.vue?vue&type=style&index=0&id=f9d66276&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9d66276",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VDialog})
