<template>
  <div class="header background">
    <div class="header-container" :class="{ 'closed': !showHeader }">
      <a :href="root">
        <logo-svg  alt="Home" height="100%"  width="6em" />
      </a>
      <div v-if="this.$route.path === '/'" class="box">
        <input :value="projectName" @input="updateProjectName" :placeholder="$t('header.projectLabel')" />
      </div>
      <v-spacer />
      <lang-btn></lang-btn>
      <div class="text-decoration-underline text-caption mr-3">
        {{ userName() }}
      </div>
      <user-svg height="42px" />
    </div>
    <toolbar v-if="this.$route.path === '/'"/>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import LogoSvg from '@public/logo.svg';
import UserSvg from '@public/img/menu/user.svg';
import toolbar from './toolbar.vue';
import LangBtn from '../lang/lang.vue';

export default {
  components: {
    toolbar,
    LangBtn,
    LogoSvg,
    UserSvg,
  },
  methods: {
    ...mapMutations(['setProjectName', 'setUserName']),
    ...mapGetters(['userName']),
    updateProjectName(e) {
      this.setProjectName(e.target.value);
    },
  },
  computed: {
    ...mapGetters(['showHeader', 'projectName']),
  },
  created() {
    const maleInventors = ['Benjamin', 'Thomas', 'Alexander', 'Nicola', 'Albert', 'Leonardo', 'Isaac'];
    const maleAdjetives = ['Franklin', 'Edison', 'Graham', 'Tesla', 'Einstein', 'da Vinci', 'Peral'];
    const femaleInventors = ['Marie', 'Hedy', 'Sthephanie', 'Josephine', 'Melitta', 'Ángela', 'Marion'];
    const femaleAdjetives = ['Anderson', 'Lamarr', 'Kwolek', 'Cochrane', 'Bentz', 'Ruiz ', 'Donovan'];
    const randomIntInventor = Math.floor(Math.random() * (maleInventors.length));
    const randomIntAdjetive = Math.floor(Math.random() * (maleAdjetives.length));
    const gender = Math.floor(Math.random() * 2);

    const randomName = gender ? `${maleInventors[randomIntInventor]} ${maleAdjetives[randomIntAdjetive]}`
      : `${femaleInventors[randomIntInventor]} ${femaleAdjetives[randomIntAdjetive]}`;
    this.setUserName(randomName);
  },
  data() {
    return {
      root: window.location.origin,
    };
  },
  watch: {
    projectName(val) {
      this.setProjectName(val);
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  padding: 0 50px;
  align-self: center;
}

.header-container {
  margin: 10px -50px 0 -50px;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid var(--v-grey-base);
  transform: scaleY(1);
  transition: transform 0.5s, height 0.5s, opacity 0.5s, margin 0.5s, padding 0.5s;
}
.header-container.closed {
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  transform: scaleY(0);
  border-bottom: none;
}

.box {
  position: relative;
  padding: 2px;
  margin-left: 20px;
  border-radius: 5px;
  border: 2px solid var(--v-off-base);
  width: 300px;
}

.box > input {
  position: relative;
  z-index: 3;
  border: none;
  background: transparent;
  width: 100%;
  padding-left: 10px;
  color: var(--v-normalText-base);
}

.box:after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: var(--v-background-base);
  content: " ";
  border-radius: 5px;
}

.box:before {
  position: absolute;
  left: -5px;
  top: 9px;
  display: block;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  content: " ";
  background: var(--v-background-base);
  z-index: 0;
  border: 2px solid var(--v-off-base);
}
</style>
