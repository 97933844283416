<template>
  <div style="display: flex; flex-direction: column; width: 100%; height: 100%; justify-content: center;">
    <failed></failed>
  </div>
</template>

<script>
import Failed from '@public/upload/failed.svg';

export default {
  components: {
    Failed,
  },
};
</script>

<style>

</style>
