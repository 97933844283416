export default `<xml style='display: none'>
<category name="%{BKY_CAT_LOGIC}" css-row="blocklyTreeRowLogic" css-icon="blocklyToolboxLogic">
  <label text="%{BKY_LABEL_LOGIC_OPERATIONS}"></label>
  <block type="controls_if"></block>
  <block type="logic_compare"></block>
  <block type="logic_operation"></block>
  <block type="logic_negate"></block>
  <block type="logic_boolean"></block>
  <block type="logic_boolean_io"></block>
  <label text="%{BKY_LABEL_BOOL_COMPARATOR}"></label>
  <block type="logic_compare_bool"></block>
</category>
<category name="%{BKY_CAT_CONTROL}" css-row="blocklyTreeRowControl" css-icon="blocklyToolboxControl">
  <block type="controls_repeat_ext">
    <value name="TIMES">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
  </block>
  <block type="controls_for">
    <field name="VAR">i</field>
    <value name="FROM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">9</field>
      </shadow>
    </value>
    <value name="BY">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="controls_whileUntil"></block>
  <block type="control_wait_whileUntil"></block>
  <block type="control_group"></block>
</category>
<category name="%{BKY_CAT_MATH}" css-row="blocklyTreeRowMath" css-icon="blocklyToolboxMath">
  <block type="math_number"></block>
  <block type="math_arithmetic"></block>
  <block type="math_map">
    <value name="FROMLOW">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="FROMHIGH">
      <shadow type="math_number">
        <field name="NUM">1023</field>
      </shadow>
    </value>
    <value name="TOLOW">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TOHIGH">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_change">
    <value name="DELTA">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="math_constrain">
    <value name="LOW">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="HIGH">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_to_int"></block>
  <block type="math_to_uint"></block>
  <block type="math_angle"></block>
  <block type="math_single"></block>
  <block type="math_random_int">
    <value name="FROM">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="math_modulo"></block>
  <block type="math_atan2"></block>
</category>
<category name="%{BKY_CAT_TEXT}" css-row="blocklyTreeRowText" css-icon="blocklyToolboxText">
  <block type="text"></block>
  <block type="text_join"></block>
  <block type="text_compare"></block>
  <block type="text_tonumber">
    <value name="TXT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="text_format_decimal">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
      <field name="DECIMALS">4</field>
    </value>
  </block>
  <block type="text_format">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="text_length"></block>
  <block type="text_contains"></block>
  <block type="text_indexof"></block>
  <block type="text_substring"></block>
  <block type="text_replace"></block>
  <block type="text_ascii"></block>
</category>
<category name="%{BKY_CAT_VARIABLES}" css-row="blocklyTreeRowVariable" css-icon="blocklyToolboxVariable">
  <label text="%{BKY_LABEL_NUMERIC_VARIABLES}"></label>
  <block type="variables_set_number">
    <value name="VALUE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="variables_get_number"></block>
  <label text="%{BKY_LABEL_TEXT_VARIABLES}"></label>
  <block type="variables_set_text">
    <value name="VALUE">
      <shadow type="text"></shadow>
    </value>
  </block>
  <block type="variables_get_text"></block>
  <label text="%{BKY_LABEL_BOOL_VARIABLES}"></label>
  <block type="variables_set_bool">
    <value name="VALUE">
      <shadow type="logic_boolean_io"></shadow>
    </value>
  </block>
  <block type="variables_get_bool"></block>
</category>
<category name="%{BKY_CAT_LISTS}" css-row="blocklyTreeRowLists" css-icon="blocklyToolboxLists">
  <label text="%{BKY_LABEL_LISTS_NUMBERS}"></label>
  <block type="lists_create_with_number"></block>
  <block type="lists_getIndex_number">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lists_setIndex_number">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lists_length_number"></block>
  <label text="%{BKY_LABEL_LISTS_TEXT}"></label>
  <block type="lists_create_with_text"></block>
  <block type="lists_getIndex_text">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lists_setIndex_text">
    <value name="AT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TO">
      <shadow type="text">
        <field name="TEXT">A</field>
      </shadow>
    </value>
  </block>
  <block type="lists_length_text"></block>
</category>
<category name="%{BKY_CAT_FUNCTIONS}" custom="PROCEDURE" css-row="blocklyTreeRowFunctions" css-icon="blocklyToolboxFunctions"></category>
<sep></sep>
<category name="%{BKY_CAT_IO}" css-row="blocklyTreeRowInOut" css-icon="blocklyToolboxFullInOut">
  <block type="io_digital_read">
    <field name="PIN">2</field>
  </block>
  <block type="io_digital_read_pin">
    <value name="PIN">
      <shadow type="math_number">
        <field name="NUM">2</field>
      </shadow>
    </value>
  </block>
  <block type="io_digital_write">
    <field name="PIN">2</field>
  </block>
  <block type="io_digital_write_var">
    <value name="PIN">
      <shadow type="math_number">
        <field name="NUM">2</field>
      </shadow>
    </value>
    <value name="STAT">
      <shadow type="logic_boolean_io">
        <field name="BOOL">TRUE</field>
      </shadow>
    </value>
  </block>
  <block type="io_analog_write">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="io_analog_write_pin">
    <value name="PIN">
      <shadow type="math_number">
        <field name="NUM">3</field>
      </shadow>
    </value>
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="io_analog_read"></block>
  <block type="io_analog_read_pin">
    <value name="PIN">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="io_pulsein">
    <field name="PIN">2</field>
    <value name="TIMEOUT">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
  <block type="io_interrupt"></block>
</category>
<category name="%{BKY_CAT_TIME}" css-row="blocklyTreeRowTime" css-icon="blocklyToolboxFullTime">
  <block type="time_delay">
    <value name="DELAY_TIME_MILI">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
  <block type="time_delaymicros">
    <value name="DELAY_TIME_MICRO">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
  <block type="time_millis"></block>
  <block type="time_micros"></block>
  <block type="time_runeveryms">
    <value name="MS">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
  <block type="infinite_loop"></block>
  <block type="time_timer"></block>
  <block type="time_timer_reset"></block>
</category>
<category name="%{BKY_CAT_SERIAL_PORT}" css-row="blocklyTreeRowSerialPort" css-icon="blocklyToolboxFullSerialPort">
  <block type="serial_init">
    <field name="BAUD">9600</field>
  </block>
  <block type="serial_print">
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="serial_read_available"></block>
  <block type="serial_read_string"></block>
  <block type="serial_read_float"></block>
  <block type="serial_read_byte"></block>
  <block type="serial_write_byte">
    <value name="BYTE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="serial_plot"></block>
  <block type="serial_interrupt"></block>
  <block type="serial_timeout">
    <value name="MS">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
</category>
<category name="%{BKY_CAT_SENSORS}" css-row="blocklyTreeRowFullSensors" css-icon="blocklyToolboxFullSensors">
  <block type="sensor_button">
    <field name="PIN">2</field>
  </block>
  <block type="sensor_button_debounced">
    <field name="PIN">2</field>
  </block>
  <block type="sensor_tilt">
    <field name="PIN">2</field>
  </block>
  <block type="sensor_potentiometer"></block>
  <block type="sensor_TEMT6000"></block>
  <block type="sensor_sound"></block>
  <block type="sensor_tmp36"></block>
  <block type="sensor_ultrasonic">
    <field name="TRIGGER_PIN">2</field>
    <field name="ECHO_PIN">3</field>
    <field name="UNITS">cm</field>
  </block>
  <block type="sensor_dht11">
    <field name="PIN">2</field>
  </block>
  <block type="sensor_dht22">
    <field name="PIN">2</field>
  </block>
  <block type="sensor_adxl345"></block>
</category>
<category name="%{BKY_CAT_ACTUATORS}" css-row="blocklyTreeRowActuators" css-icon="blocklyToolboxFullActuators">
  <block type="actuator_led">
    <field name="PIN">2</field>
  </block>
  <block type="actuator_led_pwm">
    <value name="NUM">
      <shadow type="math_number">
        <field name="NUM">128</field>
      </shadow>
    </value>
  </block>
  <block type="actuator_led_rgb_color_picker">
    <field name="PIN_R">9</field>
    <field name="PIN_G">10</field>
    <field name="PIN_B">11</field>
    <field name="TYPE">CC</field>
    <value name="COLOUR">
      <block type="colour_picker">
        <field name="COLOUR">#ff0000</field>
      </block>
    </value>
  </block>
  <block type="actuator_led_rgb">
    <field name="PIN_R">9</field>
    <field name="PIN_G">10</field>
    <field name="PIN_B">11</field>
    <field name="TYPE">CC</field>
    <value name="R">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="G">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="B">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="actuator_buzzer">
    <field name="PIN">2</field>
    <value name="TONE">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
    <value name="MS">
      <shadow type="math_number">
        <field name="NUM">500</field>
      </shadow>
    </value>
  </block>
  <block type="actuator_buzzer_tone"></block>
  <block type="actuator_buzzer_rttl">
    <field name="PIN">2</field>
    <value name="RTTTL">
      <shadow type="text">
        <field name="TEXT">song:</field>
      </shadow>
    </value>
  </block>
  <block type="actuator_buzzer_rttl_melody"></block>
</category>
<category name="%{BKY_CAT_LCD_SCREEN}" css-row="blocklyTreeRowLcdScreen" css-icon="blocklyToolboxLcdScreen">
  <block type="lcd_begin_i2c">
    <field name="ADDR">0x27</field>
  </block>
  <block type="lcd_clear"></block>
  <block type="lcd_print">
    <field name="CURSOR_COLUMN">0</field>
    <field name="CURSOR_ROW">0</field>
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="lcd_print_customchar">
    <field name="CURSOR_COLUMN">0</field>
    <field name="CURSOR_ROW">0</field>
  </block>
  <block type="lcd_print2">
    <value name="CURSOR_COLUMN">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="CURSOR_ROW">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="lcd_print2_customchar">
    <value name="CURSOR_COLUMN">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="CURSOR_ROW">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="lcd_customchar"></block>
  <block type="lcd_drawchar"></block>
  <block type="lcd_scroll"></block>
  <block type="lcd_backlight"></block>
  <block type="lcd_cursor"></block>
  <block type="lcd_display"></block>
</category>
<category name="%{BKY_CAT_LED_MATRIX}" css-row="blocklyTreeRowLedMatrix" css-icon="blocklyToolboxLedMatrix">
  <block type="ledmatrix_init"></block>
  <block type="ledmatrix_clear"></block>
  <block type="ledmatrix_drawpixel">
    <value name="X">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="Y">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="ledmatrix_drawline">
    <value name="X1">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="Y1">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="X2">
      <shadow type="math_number">
        <field name="NUM">7</field>
      </shadow>
    </value>
    <value name="Y2">
      <shadow type="math_number">
        <field name="NUM">7</field>
      </shadow>
    </value>
  </block>
  <block type="ledmatrix_drawrectangle">
    <value name="X1">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="Y1">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="X2">
      <shadow type="math_number">
        <field name="NUM">7</field>
      </shadow>
    </value>
    <value name="Y2">
      <shadow type="math_number">
        <field name="NUM">7</field>
      </shadow>
    </value>
  </block>
  <block type="ledmatrix_drawcircle">
    <value name="X">
      <shadow type="math_number">
        <field name="NUM">4</field>
      </shadow>
    </value>
    <value name="Y">
      <shadow type="math_number">
        <field name="NUM">4</field>
      </shadow>
    </value>
    <value name="R">
      <shadow type="math_number">
        <field name="NUM">4</field>
      </shadow>
    </value>
  </block>
  <block type="ledmatrix_drawtext">
    <value name="X">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="Y">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="TXT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="ledmatrix_drawsprite"></block>
  <block type="ledmatrix_drawbitmap"></block>
  <block type="ledmatrix_drawmatrix"></block>
  <block type="ledmatrix_rotation"></block>
</category>
<category name="%{BKY_CAT_NEO_PIXEL}" css-row="blocklyTreeRowNeoPixel" css-icon="blocklyToolboxNeoPixel">
  <block type="neopixel_init">
    <field name="PIN">2</field>
    <value name="LEDCOUNT">
      <shadow type="math_number">
        <field name="NUM">64</field>
      </shadow>
    </value>
  </block>
  <block type="neopixel_clear"></block>
  <block type="neopixel_setled">
    <value name="LEDNUMBER">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="R">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="G">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="B">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="neopixel_setled_colour_picker">
    <value name="LEDNUMBER">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="COLOUR">
      <block type="colour_picker">
        <field name="COLOUR">#ff0000</field>
      </block>
    </value>
  </block>
  <block type="neopixel_show"></block>
  <block type="neopixel_examples"></block>
  <block type="neopixel_setbrightness">
    <value name="BRIGHTNESS">
      <shadow type="math_number">
        <field name="NUM">50</field>
      </shadow>
    </value>
  </block>
</category>
<category name="%{BKY_CAT_MOTOR}" css-row="blocklyTreeRowMotor" css-icon="blocklyToolboxFullMotor">
  <label text="%{BKY_LABEL_MOTORSHIELD_SERVO}"></label>
  <block type="motor_servo_move">
    <field name="PIN">2</field>
    <value name="DEGREE">
      <shadow type="math_angle_180">
        <field name="ANGLE">0</field>
      </shadow>
    </value>
    <value name="DELAY_TIME">
      <shadow type="math_number">
        <field name="NUM">100</field>
      </shadow>
    </value>
  </block>
  <block type="motor_servo_oscillator_set">
    <field name="PIN">2</field>
    <value name="VALUE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="motor_servo_oscillator_action">
    <field name="PIN">2</field>
  </block>
  <label text="%{BKY_LABEL_MOTOR_STEPPER}"></label>
  <block type="motor_stepper_init">
    <value name="STEPS">
      <shadow type="math_number">
        <field name="NUM">2048</field>
      </shadow>
    </value>
    <field name="PIN1">9</field>
    <field name="PIN2">10</field>
    <field name="PIN3">11</field>
    <field name="PIN4">12</field>
  </block>
  <block type="motor_stepper_setspeed">
    <value name="RPM">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
  </block>
  <block type="motor_stepper_step">
    <value name="STEP">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <label text="%{BKY_LABEL_MOTOR_DC}"></label>
  <block type="motor_dc_init">
    <field name="PIN_A">3</field>
    <field name="PIN_B">5</field>
  </block>
  <block type="motor_dc_direction">
    <field name="DIRECCION">Adelante</field>
    <value name="VELOCIDAD">
      <shadow type="math_number">
        <field name="NUM">255</field>
      </shadow>
    </value>
  </block>
  <block type="motor_dc_stop">
  </block>
</category>
<category name="%{BKY_CAT_RTC_CLOCK}" css-row="blocklyTreeRowRtcClock" css-icon="blocklyToolboxRtcClock">
  <block type="rtc_set_time">
    <value name="DAY">
      <shadow type="math_number">
        <field name="NUM">10</field>
      </shadow>
    </value>
    <value name="MONTH">
      <shadow type="math_number">
        <field name="NUM">07</field>
      </shadow>
    </value>
    <value name="YEAR">
      <shadow type="math_number">
        <field name="NUM">2021</field>
      </shadow>
    </value>
    <value name="HOUR">
      <shadow type="math_number">
        <field name="NUM">15</field>
      </shadow>
    </value>
    <value name="MINUTE">
      <shadow type="math_number">
        <field name="NUM">06</field>
      </shadow>
    </value>
    <value name="SECOND">
      <shadow type="math_number">
        <field name="NUM">07</field>
      </shadow>
    </value>
  </block>
  <block type="rtc_set">
    <value name="VALUE">
      <shadow type="math_number">
        <field name="NUM">1</field>
      </shadow>
    </value>
  </block>
  <block type="rtc_get"></block>
  <block type="rtc_get_time_text"></block>
  <block type="rtc_get_date_text"></block>
</category>
<category name="%{BKY_CAT_SD_CARD}" css-row="blocklyTreeRowSdCard" css-icon="blocklyToolboxSdCard">
  <block type="sd_init">
    <field name="CS">4</field>
  </block>
  <block type="sd_print">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="sd_filesize">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
  </block>
  <block type="sd_remove">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
  </block>
  <block type="sd_writebyte">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
    <value name="BYTE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="sd_readbyte">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
    <value name="POS">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="sd_readloop">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
  </block>
  <block type="sd_exists">
    <value name="FILE">
      <shadow type="text">
        <field name="TEXT">log.txt</field>
      </shadow>
    </value>
  </block>
  <block type="sd_format"></block>
</category>
<category name="%{BKY_CAT_BLUETOOTH}" css-row="blocklyTreeRowBluetooth" css-icon="blocklyToolboxBluetooth">
  <block type="bluetooth_init">
    <field name="RX">2</field>
    <field name="TX">3</field>
    <field name="BAUD">9600</field>
  </block>
  <block type="bluetooth_name_simple">
    <value name="DEVNAME">
      <shadow type="text">
        <field name="TEXT">HelloBlocks</field>
      </shadow>
    </value>
  </block>
  <block type="bluetooth_print">
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="bluetooth_write_byte">
    <value name="BYTE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="bluetooth_read_available"></block>
  <block type="bluetooth_read_string"></block>
  <block type="bluetooth_read_float"></block>
  <block type="bluetooth_read_byte"></block>
  <block type="bluetooth_timeout">
    <value name="MS">
      <shadow type="math_number">
        <field name="NUM">1000</field>
      </shadow>
    </value>
  </block>
</category>
<!--
  <category name="%{BKY_CAT_MQTT}" css-row="blocklyTreeRowMqtt" css-icon="blocklyToolboxMqtt">
    <block type="mqtt_init_esp8266">
      <field name="RX">1</field>
      <field name="TX">0</field>
      <field name="BAUD">115200</field>
    </block>
    <block type="mqtt_pub">
      <value name="TOPIC">
        <shadow type="text">
          <field name="TEXT"></field>
        </shadow>
      </value>
      <value name="MSG">
        <shadow type="text">
          <field name="TEXT"></field>
        </shadow>
      </value>
    </block>
    <block type="mqtt_sub">
      <value name="TOPIC">
        <shadow type="text">
          <field name="TEXT"></field>
        </shadow>
      </value>
    </block>
      <block type="mqtt_sub_text">
          <value name="TOPIC">
              <shadow type="text">
                  <field name="TEXT"></field>
              </shadow>
          </value>
      </block>
    <block type="mqtt_isconnected"></block>
    <block type="mqtt_thingspeak_pub"></block>
    <block type="mqtt_thingspeak_sub"></block>
    <block type="mqtt_adafruit_pub"></block>
    <block type="mqtt_adafruit_sub"></block>
  </category>
-->
<category name="%{BKY_CAT_WIFI}" css-row="blocklyTreeRowWifi" css-icon="blocklyToolboxWifi">
  <label text="%{BKY_LABEL_IOT_IFTTT}"></label>
  <block type="ifttt_init_esp01">
    <field name="RX">1</field>
    <field name="TX">0</field>
    <field name="BAUD">115200</field>
  </block>
  <block type="ifttt_email"></block>
  <block type="ifttt_sms">
    <value name="MESSAGE">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="ifttt_tweet">
    <value name="MESSAGE">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="ifttt_telegram">
    <value name="MESSAGE">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <label text="%{BKY_LABEL_IOT_THINGER}"></label>
  <block type="thinger_init_esp01">
    <field name="RX">1</field>
    <field name="TX">0</field>
    <field name="BAUD">115200</field>
  </block>
  <block type="thinger_upload"></block>
  <label text="%{BKY_LABEL_IOT_THINGSPEAK}"></label>
  <block type="thingspeak_init_esp01">
    <field name="RX">1</field>
    <field name="TX">0</field>
    <field name="BAUD">115200</field>
  </block>
  <block type="thingspeak_upload"></block>
<!--<label text="Blynk IoT"></label>--> <!-- oJo use text="%{BKY_LABEL_LOGIC_OPERATIONS}"-->
<!--  <block type="blynk_init_esp01">
    <field name="RX">1</field>
    <field name="TX">0</field>
    <field name="BAUD">115200</field>
  </block>
  <block type="blynk_vpin_write">
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="blynk_app_read"></block>
  <block type="blynk_app_write"></block>
  <block type="blynk_param_read_int0"></block>
  <block type="blynk_param_read_string0"></block>
  <block type="blynk_param_read_int"></block>
  <block type="blynk_param_read_string"></block>
  <block type="blynk_timer">
    <field name="PERIOD">5000</field>
  </block>
  <block type="blynk_set_property">
    <value name="PROPERTY">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
    <value name="VALUE">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="blynk_led_onoff"></block>
  <block type="blynk_led">
    <value name="INTENSITY">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="blynk_terminal_print">
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="blynk_terminal_clear"></block>
  <block type="blynk_lcd_print">
    <value name="STRINGOUTPUT">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="blynk_lcd_clear"></block>
  <block type="blynk_map">
    <value name="LAT">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="LON">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="DESCRIPTION">
      <shadow type="text">
        <field name="TEXT"></field>
      </shadow>
    </value>
  </block>
  <block type="blynk_connected"></block>
  <block type="blynk_app_connected"></block>
  <block type="blynk_app_disconnected"></block>
-->
</category>
<category name="%{BKY_CAT_IR}" css-row="blocklyTreeRowIR" css-icon="blocklyToolboxIR">
  <label text="%{BKY_LABEL_MULTIPROTOCOL}"></label>
  <block type="infrared_irtx_multi">
    <field name="PROTOCOL">RC5</field>
    <value name="CODE">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
    <value name="BITS">
      <shadow type="math_number">
        <field name="NUM">12</field>
      </shadow>
    </value>
  </block>
  <block type="infrared_irrx_multi">
    <field name="PIN">2</field>
  </block>
  <label text="%{BKY_LABEL_EMIT_NUMBERS}"></label>
  <block type="infrared_irtx_number">
    <value name="DATA">
      <shadow type="math_number">
        <field name="NUM">0</field>
      </shadow>
    </value>
  </block>
  <block type="infrared_irrx_number">
    <field name="PIN">2</field>
  </block>
</category>
</xml>`;
