<template>
  <v-flex class="d-flex py-1 pl-6 pr-2 align-center darkWhite">
    <slot />
    <div class="font-weight-bold normalText--text ml-4">{{ text }}</div>
    <v-btn icon style="margin-left: auto" @click="closeDialog">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-flex>
</template>

<script>

export default {
  props: ['text'],
  methods: {
    closeDialog() {
      this.$emit('closed');
    },
  },
};
</script>
