<template>
  <div ref="containerRef" class="container" id="path-container">
    <svg class="path">
      <path ref="pathRef" />
    </svg>
    <running ref="elementRef" class="motion-element" :style="cssVars" />
    <failed-path ref="elementRef" class="failed-element" :style="cssVars" />
    <rock top="70%" left="22%" letter="A" explanation="compile.errors.suggestions.what-happened-1"/>
    <rock top="24%" left="36%" letter="B" explanation="compile.errors.suggestions.what-happened-2" text-top="true" />
    <rock top="56%" left="50%" letter="C" explanation="compile.errors.suggestions.what-happened-3"/>
    <rock top="18%" left="70%" letter="D" explanation="compile.errors.suggestions.what-happened-4" text-top="true" />
    <rock top="70%" left="74%" letter="E" explanation="compile.errors.suggestions.what-happened-5"/>
  </div>
</template>

<script>
import Meanderer from 'meanderer';
import FailedPath from '@public/upload/failed-path.svg';
import Rock from './rock.vue';
import Running from '../headers/running.vue';

// eslint-disable-next-line max-len
const path = 'M6.6 74.7c8.7 14.7 19.6-.5 19.6-.5s4.4-6.3 3.3-12.6c-3.5-18.4 10-23.2 10-23.2s6.2-2.1 7.4 8.4c1.1 10.6 6.5 13 6.5 13 9 1.7 9.6-8.7 10.6-14.8 4.4-23.5 23.8-15.8 16.2 5.2-1.9 4.9-4.6 2.8-6.4 15-2 13.5 2.7 11 5 9.9a57 57 0 0014.8-9';

export default {
  components: {
    Running,
    Rock,
    FailedPath,
  },
  data() {
    return {
      path,
      svg: true,
      height: 105,
      width: 105,
      cssVars: {
        '--motion-path': this.path,
        '--height': '40px',
      },
    };
  },
  mounted() {
    new ResizeObserver(this.pathContainerResizeListener).observe(document.getElementById('path-container'));
  },
  methods: {
    pathContainerResizeListener() {
      if (this.$refs.containerRef) {
        const meanderer = new Meanderer({
          path,
          height: this.height,
          width: this.width,
        });
        const newPath = meanderer.generatePath(this.$refs.containerRef.offsetWidth, this.$refs.containerRef.offsetHeight);
        this.$refs.pathRef.setAttribute('d', newPath);
        this.cssVars = {
          '--motion-path': `"${newPath}"`,
        };
      }
    },
  },
};
</script>

<style scoped>
.container {
  height: 40vh;
  min-width: 200px;
  min-height: 200px;
  border: 4px solid rgba(255,255,255,0.5);
  overflow: hidden;
  position: relative;
  margin-bottom: 2rem;
  transform-origin: bottom center;
  transform-style: preserve-3d;
}
.motion-element {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  offset-path: path(var(--motion-path));
  animation: travel 15s infinite normal linear, show-running 15s infinite normal step-end;
}
.failed-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 1002;
  offset-path: path(var(--motion-path));
  animation: travel 15s infinite normal linear, show-failed 15s infinite normal step-end;
}
svg.path {
  height: 100%;
  width: 100%;
  z-index: 1002;
}
.path path {
  fill: none;
  stroke: var(--v-lightestGrey-base);
  stroke-width: 30px;
  transition: stroke 0.25s ease;
}

@keyframes travel {
  from {
    offset-distance: 0;
  }
  6% {
    offset-distance: 12%;
  }
  16% {
    offset-distance: 12%;
  }
  25% {
    offset-distance: 30%;
  }
  35% {
    offset-distance: 30%;
  }
  42% {
    offset-distance: 44%;
  }
  52% {
    offset-distance: 44%;
  }
  61% {
    offset-distance: 62%;
  }
  71% {
    offset-distance: 62%;
  }
  84% {
    offset-distance: 88%;
  }
  94% {
    offset-distance: 88%;
  }
  to {
    offset-distance: 100%;
  }
}

@keyframes show-running {
  from {
    opacity: 1;
  }
  6% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  52% {
    opacity: 1;
  }
  61% {
    opacity: 0;
  }
  71% {
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  94% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@keyframes show-failed {
  from {
    opacity: 0;
  }
  6% {
    opacity: 1;
  }
  16% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  61% {
    opacity: 1;
  }
  71% {
    opacity: 0;
  }
  84% {
    opacity: 1;
  }
  94% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

</style>
