<template>
  <v-dialog
    max-width="600"
    persistent
    overlay-color="background"
    v-model="dialog"
    content-class="modal py-8 px-16"
    style="z-index: 1005;"
  >
    <template />
    <template>
      <div class="d-flex align-center flex-column">
        <span class="text-center">{{$t('browser-check.title')}}</span>
        <sad-icon width="64" class="mt-6 mb-2"/>
        <span class="text-sm-body-2 ma-2 text-center">{{$t('browser-check.description')}}</span>
        <v-btn class="green lightText--text mt-6" @click="goToGoogleChrome">
          {{$t('browser-check.button')}}
        </v-btn>
        <v-btn class="orange lightText--text mt-6" @click="closeModal">
          {{$t('browser-check.continue')}}
        </v-btn>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import SadIcon from '@public/img/icons/iconos_svg_32.svg';

export default {
  components: {
    SadIcon,
  },
  data: () => ({
    dialog: false,
  }),
  mounted() {
    this.dialog = !this.checkIfBrowserIsChrome();
  },
  methods: {
    checkIfBrowserIsChrome() {
      return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    },
    goToGoogleChrome() {
      window.open('https://www.google.com/chrome/', '_blank');
    },
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
button {
  text-transform: none;
}
</style>
