/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * @fileoverview UI control for fullscreen.
 */
import Blockly from 'blockly/core';

Blockly.Css.register([
  `.zoomToFit {
    opacity: .6;
  }
  .zoomToFit:hover {
    opacity: 1.0;
  }
  .zoomToFit:active {
    opacity: 1;
  }`,
]);
