var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"d-flex flex-column",staticStyle:{"height":"50vh"}},[_c('terminal',{ref:"term",staticClass:"flex-grow-1",attrs:{"real-time":_vm.realTime,"paused":_vm.monitorPaused}}),_c('v-flex',{staticClass:"d-flex flex-shrink-0 toolbox1 px-4 custom-footer align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","dark":""},on:{"click":_vm.toggleMonitorPaused}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.monitorPaused ? 'mdi-play' : 'mdi-pause'))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.monitorPaused ? _vm.$t('serial.tooltips.play') : _vm.$t('serial.tooltips.pause'))+" "+_vm._s(_vm.$t('serial.tooltips.monitor'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disabled":!_vm.monitorPaused,"dark":"","icon":""},on:{"click":function($event){return _vm.$refs.term.clear()}}},on),[_c('v-icon',[_vm._v("mdi-eraser")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('serial.tooltips.clear')))])]),_c('v-text-field',{staticClass:"mx-8 mt-0",staticStyle:{"flex-grow":"10"},attrs:{"dense":"","dark":"","hide-details":"","append-icon":"mdi-send","autocomplete":"off"},on:{"click:append":_vm.send,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-flex',{staticClass:"d-flex align-center mx-8 flex-shrink-0",staticStyle:{"flex-grow":"1"}},[_c('v-switch',{attrs:{"dense":"","dark":"","color":"green"},model:{value:(_vm.realTime),callback:function ($$v) {_vm.realTime=$$v},expression:"realTime"}}),_c('span',{staticClass:"lightestGrey--text"},[_vm._v(_vm._s(_vm.$t('serial.real-time')))])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('rate',{staticClass:"ml-4 mt-0",staticStyle:{"flex-grow":"0"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('serial.tooltips.speed')))])])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","sm":"6"}},[_c('span',{staticClass:"lightestGrey--text"},[_vm._v(" "+_vm._s(_vm.$t('serial.baudrate')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }