<template>
  <div>
    <v-textarea class="textarea" height="75" flat outlined readonly no-resize v-model="codeToShow"/>
  </div>
</template>

<script>

export default {
  props: ['code'],
  computed: {
    codeToShow() {
      return this.code;
    },
  },
};
</script>

<style scoped>
.textarea {
  width: 100%;
  color: var(--v-lightestGrey-base);
}
</style>
