<template>
  <div>
    <div id="bg-vue" :style="cssVars">
      <rock id="bg-rock-vue" width="80px"></rock>
      <span class="letter">{{letter}}</span>
    </div>
    <span class="explanation" :class="{'text-top': textTop}" :style="cssVars">{{ $t(explanation) }}</span>
  </div>
</template>

<script>
import Rock from '@public/upload/rock.svg';

export default {
  props: ['top', 'left', 'letter', 'explanation', 'textTop'],
  components: {
    Rock,
  },
  computed: {
    cssVars() {
      return {
        '--top': this.top,
        '--left': this.left,
      };
    },
  },
};
</script>

<style scoped>
#bg-vue {
  position: absolute;
  z-index: 0;
  top: var(--top);
  left: var(--left);
}

#bg-rock-vue {
  margin-top: 10px;
}
.letter {
  position: absolute;
  top: 25px;
  left: 35px;
  color: var(--v-lightText-base);
  font-size: 18px;
}

.explanation {
  position: absolute;
  width: 200px;
  top: calc(var(--top) + 50px);
  left: calc(var(--left) - 50px);
}
.explanation.text-top {
  top: calc(var(--top) - 25px);
}

</style>
