<template>
  <v-card-actions class="pb-6">
    <v-btn
      class="lightText--text yellow matrix-button"
      style="text-transform: none;"
      text
      @click="clear"
    >
      <div class="font-weight-bold">{{$t('commons.clear') }}</div>
    </v-btn>
    <v-btn
      class="lightText--text toolbox2 matrix-button"
      style="text-transform: none;"
      text
      @click="fill"
    >
      <div class="font-weight-bold">{{$t('commons.fill') }}</div>
    </v-btn>
    <v-btn
      class="lightText--text green matrix-button"
      style="text-transform: none;"
      text
      @click="copyText"
    >
      <div class="font-weight-bold">{{$t('commons.copy-data') }}</div>
    </v-btn>
    <v-snackbar
      min-width="0"
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ $t("commons.copied") }}
    </v-snackbar>
  </v-card-actions>
</template>

<script>

export default {
  props: ['code'],
  data: () => ({
    snackbar: false,
    timeout: 1000,
  }),
  methods: {
    clear() {
      this.$emit('clear');
    },
    fill() {
      this.$emit('fill');
    },
    copyText() {
      navigator.clipboard.writeText(this.code);
      this.snackbar = true;
    },
  },
};
</script>

<style>
.matrix-button {
  min-width: 100px !important;
}
</style>
