<template>
  <v-flex class="d-flex flex-column" style="height: 50vh">
    <terminal ref="term" :real-time="realTime" :paused="monitorPaused" class="flex-grow-1" />
    <v-flex class="d-flex flex-shrink-0 toolbox1 px-4 custom-footer align-center">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="mr-2" icon dark @click="toggleMonitorPaused" v-on="on">
            <v-icon>{{ monitorPaused ? 'mdi-play' : 'mdi-pause' }}</v-icon>
          </v-btn>
        </template>
        <span>
          {{ monitorPaused ? $t('serial.tooltips.play') : $t('serial.tooltips.pause') }}
          {{ $t('serial.tooltips.monitor') }}
        </span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn class="mr-2" :disabled="!monitorPaused" dark icon @click="$refs.term.clear()" v-on="on">
            <v-icon>mdi-eraser</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('serial.tooltips.clear') }}</span>
      </v-tooltip>

      <v-text-field
        v-model="text"
        dense
        dark
        class="mx-8 mt-0"
        style="flex-grow: 10"
        hide-details
        append-icon="mdi-send"
        autocomplete="off"
        @click:append="send"
        @keydown.enter="send"
      />

      <v-flex class="d-flex align-center mx-8 flex-shrink-0" style="flex-grow: 1">
        <v-switch v-model="realTime" dense dark color="green" />
        <span class="lightestGrey--text">{{ $t('serial.real-time') }}</span>
      </v-flex>

      <v-row align="center">
        <v-col class="d-flex" cols="6" sm="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <rate class="ml-4 mt-0" style="flex-grow: 0" />
              </div>
            </template>
            <span>{{ $t('serial.tooltips.speed') }}</span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="6" sm="6">
          <span class="lightestGrey--text"> {{ $t('serial.baudrate') }}</span>
        </v-col>
      </v-row>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Terminal from '../terminal/terminal.vue';
import Rate from './rate.vue';

export default {
  components: {
    Terminal,
    Rate,
  },
  data() {
    return {
      text: '',
      logCB: null,
      clearCB: null,
      realTime: true,
    };
  },
  computed: {
    ...mapGetters(['serialShelf', 'serialTab', 'monitorPaused']),
  },
  methods: {
    ...mapMutations(['toggleMonitorPaused']),
    send() {
      if (!this.text || !this.$serial.connected) return;
      this.$serial.write(`${this.text}\n`);
      this.text = '';
    },
    reset() {
      this.$uploader.reset();
    },
    disconnectTerminal() {
      if (this.logCB) this.$serial.off('message', this.logCB);
      if (this.clearCB) this.$serial.off('clear', this.clearCB);
    },
    connectTerminal() {
      this.logCB = (data) => {
        this.$refs.term.write(data);
      };
      this.$serial.on('message', this.logCB);
      this.clearCB = () => {
        this.$refs.term.clear();
      };
      this.$serial.on('clear', this.clearCB);
    },
  },
  watch: {
    serialShelf(val) {
      if (val && this.serialTab === 'monitor') {
        this.disconnectTerminal();

        this.$uploader.reset().then(() => {
          this.connectTerminal();
        });
      }
    },
  },
  mounted() {
    this.$uploader.reset().then(() => {
      this.connectTerminal();
    });
  },
  beforeDestroy() {
    this.disconnectTerminal();
  },
};
</script>

<style scoped>
.custom-footer {
  max-height: 52px;
}
</style>
